import { Slice } from '../global/constants';
import { TGenericObject } from '../global/types';
import { useSlices } from './useSlices';
import { useMemo } from 'react';

/**
 * @function useDomainMap
 * @description Hook that reduces an array of domains to an object literal, with each domain keyed by both its ID and its slug.
 */
export const useDomainMap = () => {
  const [{ domains }] = useSlices(Slice.DOMAINS);
  return useMemo(
    () =>
      domains.reduce(
        (acc: any[], domain: TGenericObject) => ({
          ...acc,
          [domain['id']]: domain,
          [domain['slug']]: domain,
        }),
        {}
      ),
    [domains]
  );
};
