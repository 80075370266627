import { maybePlural } from '../../../global/helpers';

const minutesPerItem = 0.75;
/**
 * @function getFormattedDuration
 * @description Assuming a predefined time allotted per survey item, this function will return a formatted string depicting the total duration of the survey.
 * @param numberOfItems
 * @returns string
 */
export const getFormattedDuration = numberOfItems => {
  const durationRaw = Math.round(numberOfItems * minutesPerItem);
  const duration = durationRaw > 0 ? durationRaw : 1; // Minimum duration is 1 Min

  return `${duration} ${maybePlural(duration, 'Min', 'Mins')}`;
};
