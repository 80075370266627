import React from 'react';
import { ButtonSymptomView, IButtonSymptomView } from './ButtonSymptomView';
import { useSymptomMeta } from '../../store/slices/sliceSymptoms/core/useSymptomMap';
import { getSymptomDetailPath } from '../../global/helpers';

export interface ISymptomDatum {
  symptomId: number;
  concernId: number;
}

/**
 * @constructor ButtonSymptom
 * @description Button that links to a Symptom Detail Page.  Retrieves Symptom Meta from Store, passes all props to View
 * @param symptomId
 * @param concernId
 * @returns {JSX.Element}
 */
export const ButtonSymptom: React.FC<ISymptomDatum> = ({
  symptomId,
  concernId,
}) => {
  const { name, slug } = useSymptomMeta(symptomId);

  const viewProps: IButtonSymptomView = {
    text: name,
    concernId,
    to: getSymptomDetailPath(slug),
  };
  return <ButtonSymptomView {...viewProps} />;
};

export default ButtonSymptom;
