import { TGenericFunction } from '../../global/types';
import { TTestimonialItem } from './TestimonialsView';
import { pageGutter } from '../../pages/Page/props';

const breakpoint = 'lg';
const navItemHW = 10;
const navItemGutter = 15;

export const imageWrapProps = {
  mb: '35px',
};
export const testimonialTextProps = {
  color: 'white',
  m: 'auto',
  maxW: '27.305327869em',
};

export const viewportProps = {
  bgColor: 'green.500',
  overflow: 'hidden',
  py: '50px',
  w: '100vw',
};

export const createCarProps = (index: number) => ({
  key: `testimonial-${index}`,
  px: `${pageGutter}px`,
  textAlign: 'center' as any,
  w: '100vw',
});

const imageHW = 160;
export const createImageProps = (src: string) => ({
  display: 'block',
  h: imageHW,
  borderRadius: '100%',
  m: 'auto',
  maxW: imageHW,
  src,
  w: imageHW,
});

export const createNavProps = (data: TTestimonialItem[]) => ({
  display: 'flex',
  justifyContent: 'space-between',
  m: 'auto',
  w: `${navItemHW * data.length + navItemGutter * (data.length - 1)}px`,
});

export const createNavItemProps = (
  index: number,
  activeIndex: number,
  onClick: TGenericFunction
) => {
  const isActive = index === activeIndex;
  return {
    border: `1px solid white`,
    h: `${navItemHW}px`,
    key: `testimonial-nav-idx-${index}`,
    rounded: 100,
    w: `${navItemHW}px`,
    ...(isActive && {
      bgColor: 'white',
    }),
    ...(!isActive && {
      cursor: 'pointer',
      onClick,
    }),
  };
};

export const createTrainProps = (
  data: TTestimonialItem[],
  activeIndex: number
) => ({
  display: 'flex',
  justifyContent: 'space-between',
  transition: `transform 1s`,
  transform: `translateX(-${(activeIndex * 100) / data.length}%)`,
  mb: { base: '35px', [breakpoint]: '100px' },
  w: `${data.length * 100}vw`,
});
