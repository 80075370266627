import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createAccountAPIInteractions } from '../../../../../store';
import { nullChar, Slice } from '../../../../../global/constants';
import { allTrue } from '../../../../../global/helpers';
import { useSlices } from '../../../../../hooks/useSlices';


type HookSig = (isAccountInit: boolean) => void;
/**
 * useSubscribeAccountUpdate
 * @description Subscription for refreshing Account data
 * @param isAccountInit
 * @returns void
 */
export const useSubscribeAccountUpdate: HookSig = isAccountInit => {
  const dispatch = useDispatch();
  const [
    {
      id: accountId,
      username,
      firstName,
      lastName,
      email,
      isEmailOptin,
      tempData: { email: tempEmail, firstName: tempFirstName },
      personalizedEmailOptIn,
    },
  ] = useSlices(Slice.ACCOUNT);
  const { acAccountConvert } = createAccountAPIInteractions(accountId);

  useEffect(() => {
    // Boolean conditions for triggering an Account update
    const effectConditions = [
      isAccountInit,
      !!accountId,
      !!username,
      !!firstName,
      !!lastName,
      isEmailOptin !== null,
      email === nullChar,
      tempEmail !== nullChar,
      tempFirstName !== null,
    ];

    if (allTrue(...effectConditions)) {
      dispatch(
        acAccountConvert(
          username,
          tempFirstName,
          lastName,
          tempEmail,
          isEmailOptin
        )
      );
    }
  }, [
    isAccountInit,
    accountId,
    username,
    firstName,
    tempFirstName,
    lastName,
    email,
    tempEmail,
    personalizedEmailOptIn,
  ]);
};
