import { Slice } from '../../../../../global/constants';
import { allTrue } from '../../../../../global/helpers';
import { createAccountAPIInteractions } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSlices } from '../../../../../hooks/useSlices';

type HookSig = (isAssessmentsInit: boolean) => void;
/**
 * useSubscribeAssessmentCreate
 * @description Subscribe to the creation of an `Assessment` for the current `Person`.
 * @param isAssessmentsInit
 * @returns void
 */
export const useSubscribeAssessmentCreate: HookSig = isAssessmentsInit => {
  const dispatch = useDispatch();

  const [{ id: accountId }, { currentPersonId }, { currentAssessmentId }] =
    useSlices(Slice.ACCOUNT, Slice.PERSONS, Slice.ASSESSMENTS);

  const { createPersonInteractions } = createAccountAPIInteractions(accountId);
  const { acCreateAssessment } = createPersonInteractions(currentPersonId);

  useEffect(() => {
    // Boolean conditions for creating a `Person`.
    const effectConditions = [
      !!currentPersonId, // a current person exists
      isAssessmentsInit, // Assessments are Ready
      !currentAssessmentId, // An assessment still does not exist
    ];

    if (allTrue(...effectConditions)) {
      dispatch(acCreateAssessment());
    }
  }, [currentPersonId, isAssessmentsInit, currentAssessmentId]);
};
