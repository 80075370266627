import Button from '../Button';
import QuestionnaireView, { IQuestionnaireView } from './QuestionnaireView/';
import React from 'react';
import { Slice } from '../../global/constants';
import { TAssessmentDomain } from '../../store/slices';
import { acIncrementQuestionIdx, acSetIsEditMode } from '../../store';
import { useDispatch } from 'react-redux';
import { useGetVisibleSurveyProps } from '../../store/slices/sliceAssessments/core/useGetVisibleSurveyProps';
import { useSlices } from '../../hooks/useSlices';
import { uiEditButton } from './props';

interface IQuestionnaire {
  domain: TAssessmentDomain;
}

/**
 * @constructor Questionnaire
 * @description Renders a series of Assessment Questions to be answered by the user.  Displays an "Edit" button when in edit mode.  This button navigates to the question if there are more questions to update, or sends the user to the Results page if no more questions remain.  Shell component.  Consults with Store to assess if the user is in edit mode. Compiles props for QuestionnaireView.
 * @param domain - Assessment Domain.  This is the top level object that contains all the questions
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const Questionnaire: React.FC<IQuestionnaire> = ({
  domain,
  ...rest
}) => {
  const dispatch = useDispatch();
  const getVisibleSurveyProps = useGetVisibleSurveyProps();
  const [{ isEditMode, currentQuestionIdx = 0 }] = useSlices(
    Slice.QUESTIONNAIRE
  );
  if (!domain) return null;

  const { domainDefId: domainId, displayName: domainName } = domain;

  const { numberOfItems, numberOfItemsCompleted, assessmentItems } =
    getVisibleSurveyProps(domainId);

  const isBeforeLastQuestion = currentQuestionIdx + 1 < assessmentItems.length;

  // Build Response DomainProgress handler here. Pass down to Questionnaire, and then again to Question

  const handleEditModeDisable = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(acSetIsEditMode(false));
  };

  const editButtonProps = {
    variant: 'primary',
    ...uiEditButton,
    ...(isBeforeLastQuestion
      ? {
          onClick: () => dispatch(acIncrementQuestionIdx()),
          children: 'Next',
        }
      : {
          onClick: handleEditModeDisable,
          children: 'See Results',
        }),
  };

  const viewProps: IQuestionnaireView = {
    assessmentItems,
    currentQuestionIdx,
    domainId,
    domainName,
    numberOfItems,
    numberOfItemsCompleted,
    ...rest,
  };
  return (
    <>
      <QuestionnaireView {...viewProps} />
      {isEditMode && <Button {...editButtonProps} />}
    </>
  );
};
export default Questionnaire;
