import { createAsyncThunk } from '@reduxjs/toolkit';
import { Slice } from '../../../../global/constants';
import { Auth } from 'aws-amplify';
import api, { keyApi } from '../../../../services/api/api';
import { maybeGetResponseData } from '../../../../services/api/core/maybeGetResponseData';

type TPerformTemporaryLogin_Output = {
  username: string;
  tempPassword: string;
  tempEmail: string;
  accountId: number;
};
type TPerformTemporaryLogin_Input = {};
export const performTemporaryLogin = createAsyncThunk<
  TPerformTemporaryLogin_Output,
  TPerformTemporaryLogin_Input
>(`${Slice.SESSION}/tempLogin`, async () => {
  await Auth.signOut();
  const tempAccountResponse = await keyApi.post('account/tempaccount');
  const tempAccountData = maybeGetResponseData(tempAccountResponse) || {};

  const { username, tempPassword } = tempAccountData;
  await Auth.signIn(username, tempPassword);

  return tempAccountData;
});

type TUpdatePassword_Output = {
  updateStatus: string;
};
type TUpdatePassword_Input = {
  oldPassword: string;
  newPassword: string;
};
export const updatePassword = createAsyncThunk<
  TUpdatePassword_Output,
  TUpdatePassword_Input
>(`${Slice.SESSION}/updatePassword`, async ({ oldPassword, newPassword }) => {
  const user = await Auth.currentAuthenticatedUser();
  const res = await Auth.changePassword(user, oldPassword, newPassword);

  return { updateStatus: res };
});
export const logUserActivity = createAsyncThunk<
  { success: boolean },
  { accountId: number; activity: string }
>(`${Slice.SESSION}/logUserActivity`, async ({ accountId, activity }) => {
  const logVisitMyPlanResponse = await api.post(
    `/log/loguseractivity/${accountId}`,
    { activity }
  );

  const success = maybeGetResponseData(logVisitMyPlanResponse) || [];

  return { success };
});
