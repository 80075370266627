import { getButtonColors } from './helpers';
import { TGenericObject } from '../../../global/types';
const Template = {
  SOLID: (color: string) => ({
    bgColor: color,
  }),
  OUTLINE: (color: string) => ({
    bgColor: 'transparent',
    color,
    borderColor: color,
  }),
  LINK: (color: string) => ({
    color,
  }),
};

/**
 * @function createVariantProps
 * @description Creates the UI props for each button variation
 * @param colorSpace
 * @param colorVal
 * @returns TGenericObject
 */
export const createVariantProps = (
  colorSpace: string,
  colorVal: number
): TGenericObject => {
  const [colorBase, colorHover] = getButtonColors(colorSpace, colorVal);
  const thisBaseColor: string = colorBase!;
  const thisHoverColor: string = colorHover!;
  return {
    solid: {
      ...Template.SOLID(thisBaseColor),
      _hover: Template.SOLID(thisHoverColor),
      _active: Template.SOLID(thisBaseColor),
    },
    outline: {
      ...Template.OUTLINE(thisBaseColor),
      _hover: Template.OUTLINE(thisHoverColor),
      _active: Template.OUTLINE(thisBaseColor),
    },
    link: {
      ...Template.LINK(thisBaseColor),
      _hover: Template.LINK(thisHoverColor),
      _active: Template.LINK(thisBaseColor),
    },
  };
};
