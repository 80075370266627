import { darken } from 'polished';
import { getThemeColor } from '../../../theme/_';
import { TNullable } from '../../../global/types';

/**
 * @function getButtonColors
 * @description Returns the base and hover colors for a button
 * @param name
 * @param value
 * @returns [colorBase, colorHover]
 */
export const getButtonColors = (
  name: string,
  value: number
): [colorBase: TNullable<string>, colorHover: TNullable<string>] => {
  let colorBase: TNullable<string> = null;
  let colorHover: TNullable<string> = null;
  try {
    colorBase = getThemeColor(name, value);
    colorHover = colorBase ? darken(0.1, colorBase) : null;
  } catch (err) {
    console.error(
      `Theme Color '${name}.${value}' could not be retrieved.  Returning Null.`
    );
  }

  return [colorBase, colorHover];
};
