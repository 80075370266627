import FormRequestMissingInfo from '../../components/FormRequestMissingInfo';
import { TGenericActionCreator } from '../../store';

export interface IPageRequestAgeView {
  nextRoute: string;
  firstName: string;
  acMissingInfoUpdated: TGenericActionCreator;
}
const PageRequestAgeView: React.FC<IPageRequestAgeView> = ({
  nextRoute,
  firstName,
  acMissingInfoUpdated,
}) => {
  const infoDetails = {
    title: `How old is your child?`,
    headline: `Let's make sure you get age-appropriate recommendations.`,
    desc: ``,
    label: `How old is ${firstName}?`,
    type: 'number',
    requiredText: 'We need an age to give you appropriate recommendations',
    hfProps: {
      // validate: {
      //   checkDate: birthDateString =>
      //     Date.now() > Date.parse(birthDateString) ||
      //     'Birthdate can not be set in the future.',
      // },
      // pattern: {
      //   value: new RegExp(
      //     '^(1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4}$'
      //   ),
      //   message: `Please enter date as "mm/dd/yyyy"`,
      // },
    },
  };
  return (
    <FormRequestMissingInfo
      {...{
        acMissingInfoUpdated,
        infoDetails,
        nextRoute,
      }}
    />
  );
};

export default PageRequestAgeView;
