import React from 'react';
import Essay from '../../../Essay';
import { Box, Link } from '@chakra-ui/react';
import Image from '../../../Image';
import EssayInterior from '../../../EssayInterior';
import { H5 } from '../../../Prose';
import Button, { ButtonKind } from '../../../Button';
import {
  contentCellProps,
  contentContainerProps,
  createImageProps,
  headingProps,
  createImageCellProps,
  wrapProps,
} from './props';
import { TGenericFunction } from '../../../../global/types';
import { TCourse } from '../../../../store/slices';

export interface ICourseView extends TCourse {
  onCourseClick: TGenericFunction;
}

/**
 * @constructor CourseView
 * @description Renders a single Cadey course. View component. Renders props passed from shell
 * @param courseTitle - Title of the course
 * @param courseThumbnailUrl - Url of the course thumbnail
 * @param courseUrl - Url of the course
 * @param onCourseClick - Function to be called when the course is clicked
 * @returns {JSX.Element}
 */
const CourseView: React.FC<ICourseView> = ({
  courseTitle,
  courseThumbnailUrl,
  courseUrl,
  onCourseClick,
}) => {
  const buttonProps = {
    as: Link,
    href: courseUrl,
    kind: ButtonKind.UTILITY,
    onClick: onCourseClick,
    target: '_blank',
    variant: 'primary',
    w: '120px',
  };

  return (
    <Essay variant={'card'}>
      <Box {...wrapProps}>
        <Box {...createImageCellProps(courseThumbnailUrl)}>
          <Image {...createImageProps(courseThumbnailUrl)} />
        </Box>
        <Box {...contentCellProps}>
          <EssayInterior {...contentContainerProps}>
            <H5 {...headingProps}>{courseTitle}</H5>
            <Button {...buttonProps}>Go To Course</Button>
          </EssayInterior>
        </Box>
      </Box>
    </Essay>
  );
};

export default CourseView;
