import { Flex, Spinner } from '@chakra-ui/react';
import { containerProps, spinnerProps } from './core/ui';

/**
 * @constructor Loader
 * @description Renders a loading spinner.
 * @param isActive
 * @param isSpinner
 * @returns {JSX.Element}
 */
export const Loader = ({ isActive = false, isSpinner = true }) => (
  <Flex {...containerProps({ isActive })}>
    {isSpinner && <Spinner {...spinnerProps} />}
  </Flex>
);
export default Loader;
