// import DebugAssessmentItemCounts from '../DebugAssessmentItemCounts';
import AppAuthenticated from '../AppAuthenticated';
import Footer from '../Footer';
import PageLanding from '../../pages/PageLanding';
import { Box } from '@chakra-ui/react';
import { Route, Switch } from 'react-router-dom';
import { pageAreaProps, footerAreaProps, wrapProps } from './props';
import { getAuthPath, getRootPath } from '../../global/helpers';
import MenuMainProvider from '../../contexts/MenuMainContext';
import AppHeader from '../AppHeader';
import { navProps } from '../../pages/Page/props';
import React from 'react';

/**
 * @constructor AppView
 * @description Application root view component.
 * @returns {JSX.Element}
 */
export const AppView = () => {
  return (
    <Box {...wrapProps}>
      {/*<DebugAssessmentItemCounts />*/}
      <MenuMainProvider>
        <AppHeader {...navProps} />
      </MenuMainProvider>
      <Box {...pageAreaProps}>
        <Switch>
          <Route path={getAuthPath()} render={() => <AppAuthenticated />} />
          <Route path={getRootPath()} exact render={() => <PageLanding />} />
        </Switch>
      </Box>
      <Box {...footerAreaProps}>
        <Footer />
      </Box>
    </Box>
  );
};

export default AppView;
