import { PayloadAction } from '@reduxjs/toolkit';
import { TAccountState, TAdditionalData } from './core/types';
import { buildSlice } from '../core/helpers';
import { mapPayloadToState } from '../../../global/helpers';
import { nullChar, Slice } from '../../../global/constants';
import { updatePassword } from '../sliceSession';
import {
  authenticateCadeUser,
  getCadeAccount,
  postAdditionalData,
  postCadeAccountInfo,
  setWaitlistOptInStatus,
} from './core/thunks';

const initialState: TAccountState = {
  additionalData: null,
  address1: null,
  address2: null,
  apiKey: null,
  cellPhone: null,
  city: null,
  email: nullChar,
  firstName: null,
  homePhone: null,
  id: null,
  isEmailOptin: null,
  isInit: false,
  isReady: false,
  isSubscribed: false,
  isTemporary: true,
  isWaitlisted: null,
  lastName: null,
  personalizedEmailOptIn: null,
  postalCode: null,
  restrictedDomains: null,
  state: null,
  tempData: {
    firstName: null,
    email: nullChar,
    newPassword: null,
  },
  tempPassword: null,
  username: null,
  waitlistResponse: null,
};

export const sliceAccount = buildSlice({
  name: Slice.ACCOUNT,
  initialState,
  reducers: {
    tempNameUpdated: (
      state,
      { payload }: PayloadAction<{ firstName: string }>
    ) => {
      state.tempData.firstName = payload.firstName;
    },
    tempEmailUpdated: (
      state,
      { payload }: PayloadAction<{ email: string; isEmailOptin: boolean }>
    ) => {
      state.tempData.email = payload.email;
      state.isEmailOptin = payload.isEmailOptin;
    },
    tempNewPasswordUpdated: (
      state,
      { payload }: PayloadAction<{ newPassword: string }>
    ) => {
      state.tempData.newPassword = payload.newPassword;
    },
    waitlistStateNulled: state => {
      state.isWaitlisted = null;
    },
  },
  extraReducers: {
    /****************************
     * THUNK - CadeAuthenticate
     */

    [authenticateCadeUser.pending.type]: state => {
      state.isReady = false;
    },

    [authenticateCadeUser.fulfilled.type]: (
      state,
      { payload }: PayloadAction
    ) => {
      mapPayloadToState(payload, state);
      state.isInit = true;
      state.isReady = true;
    },

    /****************************
     * THUNK - GetAccount
     */

    [getCadeAccount.pending.type]: state => {
      state.isReady = false;
    },
    [getCadeAccount.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ email: string }>
    ) => {
      mapPayloadToState(payload, state);
      state.isTemporary = !payload.email;
      state.isReady = true;
    },

    /****************************
     * THUNK - CreateAccount
     */

    [postCadeAccountInfo.pending.type]: state => {
      state.isReady = false;
    },
    [postCadeAccountInfo.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ email: string; firstName: string | null }>
    ) => {
      mapPayloadToState(payload, state);

      // If we get a real email in the payload, null out the temp value
      if (payload.email !== nullChar) {
        state.tempData.email = nullChar;
      }

      // If we get a real first name in the payload, null out the temp value
      if (payload.firstName !== null && payload.firstName !== nullChar) {
        state.tempData.firstName = null;
      }

      state.isReady = true;
    },

    /****************************
     * THUNK - UpdatePassword
     */

    [updatePassword.fulfilled.type]: state => {
      state.tempData.newPassword = null;
    },

    /****************************
     * THUNK - SetWaitlistOptInStatus
     */

    [setWaitlistOptInStatus.pending.type]: state => {
      state.isReady = false;
    },
    [setWaitlistOptInStatus.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{ isWaitlisted: boolean; waitlistResponse: string }>
    ) => {
      const { isWaitlisted, waitlistResponse = 'I am a timestamp' } = payload;
      state.isWaitlisted = isWaitlisted;
      state.waitlistResponse = waitlistResponse;
      state.isReady = true;
    },

    [postAdditionalData.pending.type]: state => {
      state.isReady = false;
    },
    [postAdditionalData.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ additionalData: TAdditionalData }>
    ) => {
      state.additionalData = payload.additionalData;
      state.isReady = true;
    },
  },
});
