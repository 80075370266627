import Page from '../Page';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import { Box, List, ListItem } from '@chakra-ui/react';
import { Copy, H2 } from '../../components/Prose';
import {
  buttonItemProps,
  buttonListProps,
  cellInteriorProps,
  CellType,
  contentHeadingProps,
  contentRowsProps,
  createCellProps,
  createContentRowProps,
  createHeaderCellProps,
  createImageProps,
  createPageProps,
  createTrialButtonProps,
  ctaWrapProps,
  headerRowProps,
  innerProps,
  pageBodyProps,
  pageHeadingAreaProps,
  pageHeadingProps,
  signInProps,
} from './props';
import { TGenericFunction } from '../../global/types';

import { IPageView } from '../Page/PageView';
import { ButtonRoute } from '../../components/ButtonRoute';
import { Button } from '../../components/Button';
import VideoPlayer from '../../components/VideoPlayer';
import PageBody from '../../components/PageBody';
import Image from '../../components/Image';
import Testimonials from '../../components/Testimonials';

export interface IPageLandingView extends IPageView {
  handleTrialClick: TGenericFunction;
}

/**
 * @constructor PageLandingView
 * @description Landing page.  Marketing, app introduction
 * @param handleTrialClick - Trial button handler callback
 * @param loadedConditions - Array of booleans indicating whether page is loaded
 * @returns {JSX.Element}
 */
const PageLandingView: React.FC<IPageLandingView> = ({
  handleTrialClick,
  loadedConditions,
}) => {
  const trialButtonProps = createTrialButtonProps(handleTrialClick);
  const ctaProps = {
    ...trialButtonProps,
    w: '192px',
  };
  const videoProps = {
    url: 'https://player.vimeo.com/video/745839272?h=d44873200d&badge=0&autopause=0&player_id=0&app_id=58479',
  };
  return (
    <Page {...createPageProps(loadedConditions)}>
      <Box {...pageHeadingAreaProps}>
        <Box {...innerProps}>
          <Box {...headerRowProps}>
            <Box {...createHeaderCellProps({ type: CellType.CONTENT })}>
              <Box mb={'35px'}>
                <H2 {...pageHeadingProps}>Get help now.</H2>
                <Copy>
                  If you think your child has ADHD, autism, or anxiety, don't
                  wait. Get a screening test here and start helping your child
                  today. You do not have to sit on a therapist's couch to get
                  help for you and your child. Instead of waiting months or
                  years to know what your child needs, you can do an assessment
                  anytime anywhere. We are pioneering new ground in child
                  psychology.
                </Copy>
                <Box {...ctaWrapProps}>
                  <List {...buttonListProps}>
                    <ListItem {...buttonItemProps}>
                      <Button {...trialButtonProps}>Start Assessment</Button>
                    </ListItem>
                    <ListItem {...buttonItemProps}>
                      <ButtonRoute {...signInProps}>Sign In</ButtonRoute>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Box>

            <Box {...createHeaderCellProps({ type: CellType.MEDIA })}>
              <VideoPlayer {...videoProps} />
            </Box>
          </Box>
        </Box>
      </Box>

      <PageBody {...pageBodyProps}>
        <Box {...innerProps}>
          <Box {...contentRowsProps}>
            {/* Row 1 */}
            <Box {...createContentRowProps()}>
              <Box {...createCellProps({ type: CellType.MEDIA })}>
                <Image {...createImageProps({ src: img1 })} />
              </Box>
              <Box {...createCellProps({ type: CellType.CONTENT })}>
                <Box {...cellInteriorProps}>
                  <H2 {...contentHeadingProps}>Tests with Immediate Results</H2>
                  <Copy>
                    Get answers today. Cadey takes less than an hour and gives
                    you instant screening test results. The expert licensed
                    child psychologists behind Cadey created autism and ADHD
                    symptom tests and more that you can do at home. Formal
                    testing in an office can be costly and often involves a long
                    wait for answers.
                  </Copy>
                </Box>
              </Box>
            </Box>

            {/* Row 2 */}
            <Box {...createContentRowProps()}>
              <Box {...createCellProps({ type: CellType.MEDIA, alt: true })}>
                <Image {...createImageProps({ src: img2 })} />
              </Box>
              <Box {...createCellProps({ type: CellType.CONTENT, alt: true })}>
                <Box {...cellInteriorProps}>
                  <H2 {...contentHeadingProps}>
                    Customized On-Demand Courses and Tools
                  </H2>
                  <Copy>
                    Have you ever gotten a barrage of parenting advice that
                    didn't help? Are you ready to take action to support your
                    child but don't know where to start? We get it. At Cadey,
                    our online tests for ADHD, autism, and other childhood
                    disorders help you know your child's symptoms and find quick
                    virtual courses you can access anytime.
                  </Copy>
                  <Box {...ctaWrapProps}>
                    <Button {...ctaProps}>Get Help Now</Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Row 3 */}
            <Box {...createContentRowProps()}>
              <Box {...createCellProps({ type: CellType.MEDIA })}>
                <Image {...createImageProps({ src: img3 })} />
              </Box>
              <Box {...createCellProps({ type: CellType.CONTENT })}>
                <Box {...cellInteriorProps}>
                  <H2 {...contentHeadingProps}>
                    Make Progress in 2 Minutes a Day
                  </H2>
                  <Copy>
                    Make significant changes quickly when you work on the
                    essential skills. Most parenting programs don't work because
                    they aren't based on a thourough understanding of your
                    child. Cadey's digital mini-courses use your test results to
                    give you targeted strategies that can make a difference in
                    just minutes a day.
                  </Copy>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PageBody>
      <Testimonials />
    </Page>
  );
};
export default PageLandingView;
