import { useState } from 'react';
import Button from '../../components/Button';
import Page from '../Page';
import PageBody from '../../components/PageBody';
import { H1, H3 } from '../../components/Prose';
import { acAccountSetNewPassword } from '../../store';
import { useDispatch } from 'react-redux';
import { useResultsRoute } from '../../quests/useResultsRoute';
import { useForm } from 'react-hook-form';
import { useRedirect } from '../../hooks/useRedirect';
import { useRef } from 'react';
import { validate, validationScenarios } from './core/validate';
import {
  errorMsgProps,
  getInputOptInProps,
  getInputProps,
  getLabelProps,
  getPageProps,
  getSubmitProps,
  headingProps,
} from '../../components/FormRequestMissingInfo/core/props';
import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Icon } from '../../components/Icon';
import { InputPassword } from '../../components/InputPassword';
import { Quest } from '../../quests/core/data';

const PageRequestPassword = () => {
  const missingInfoInputName = 'missingInfo';

  // Hooks
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const refAutoFocus = useRef(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const [candidatePassword, setCandidatePassword] = useState('');
  const isCandidatePasswordValid = validationScenarios.reduce(
    (canHazPreviousValid, { test }) =>
      canHazPreviousValid && test(candidatePassword),
    true
  );
  const infoDetails = {
    title: `One last step. What is your password?`,
    headline: `Create a password.`,
    label: `Enter a password.`,
    hfProps: {
      validate,
    },
  };
  const acMissingInfoUpdated = acAccountSetNewPassword;
  const nextRoute = useResultsRoute(Quest.PASSWORD);

  /**
   * Raw Component
   */

  // Variables
  const optInName = 'value-opt-in';
  const { [missingInfoInputName]: error } = errors; // Hook Form Errors
  const { hfProps: inputHookFormProps = {}, submitText = 'Next Step' } =
    infoDetails;

  // Handlers
  const onFormSubmit = valuesRaw => {
    return new Promise(resolve => {
      setTimeout(() => {
        const values = Object.values(valuesRaw);
        dispatch(acMissingInfoUpdated(...values));
        redirect(nextRoute);
        resolve();
      }, 1000);
    });
  };
  const onPasswordChange = ({ target }) => {
    setCandidatePassword(target.value);
  };

  // Props

  // Build MissingInfo HF Registration separately, for clarity
  const hfReg_MissingInfoProps = register(missingInfoInputName, {
    required: infoDetails.requiredText,
    ...inputHookFormProps,
  });

  // Build MissingInfo Input props and register with Hook Form
  const inputProps = {
    value: candidatePassword,
    onInput: onPasswordChange,
    ...getInputProps(missingInfoInputName, infoDetails),
    ...hfReg_MissingInfoProps,
    refAutoFocus,
  };

  // Build Opt-In Input props and register with Hook Form
  const optInProps = {
    ...getInputOptInProps(optInName),
    ...register(optInName),
  };

  const iconSize = 25;
  const valStatusListProps = {};

  const createValStatusItemProps = i => ({
    key: `pw-req-${i}`,
    position: 'relative',
    pl: `${iconSize * 0.9}px`,
    mt: i > 0 ? '3px' : 0,
  });
  const createIconContainerProps = isValid => ({
    color: isValid ? 'green.800' : 'brown.200',
    left: 0,
    position: 'absolute',
    top: 0,
    transform: 'translate(-15%, -7%)',
    transition: 'color 100ms',
  });
  const valStatusIconProps = {
    type: 'checkCircle',
    size: iconSize,
  };

  return (
    <Page {...getPageProps(infoDetails)}>
      <PageBody>
        <Box mb={'80px'}>
          <H3 {...headingProps}>{infoDetails.headline}</H3>
          <Box {...valStatusListProps}>
            {validationScenarios.map(({ test, msg }, i) => (
              <Box {...createValStatusItemProps(i)}>
                <Box {...createIconContainerProps(test(candidatePassword))}>
                  <Icon {...valStatusIconProps} />
                </Box>
                {msg}
              </Box>
            ))}
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormControl isInvalid={error}>
            <FormLabel {...getLabelProps(missingInfoInputName)}>
              {infoDetails.label}
            </FormLabel>
            <InputPassword {...inputProps} ref={refAutoFocus} />
            {!!error && (
              <FormErrorMessage {...errorMsgProps}>
                {error.message}
              </FormErrorMessage>
            )}
          </FormControl>
          {!!infoDetails.optInText && (
            <FormControl mt={'20px'}>
              <Checkbox {...optInProps}>{infoDetails.optInText}</Checkbox>
            </FormControl>
          )}
          <Button
            isEnabled={isCandidatePasswordValid}
            {...getSubmitProps(isSubmitting)}
          >
            {submitText}
          </Button>
        </form>
      </PageBody>
    </Page>
  );
};

export default PageRequestPassword;
