import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';

import {
  acInitIsComplete,
  acSetGaClientId,
  acSetPaywallStatus,
  acSetDownloadAppStatus,
} from '../../../../../store';
import {
  allTrue,
  getQueryStringParams,
  parseBooleanString,
} from '../../../../../global/helpers';

import Amplify, { I18n } from 'aws-amplify';
import { Translations } from '@aws-amplify/ui-components';

type HookSig = (isInitComplete: boolean) => void;
/**
 * useInitSession
 * @description This hook is responsible for initializing App dependencies and setting baseline values in the Session slice.
 * @param isInitComplete
 * @returns {void}
 */
export const useInitSession: HookSig = isInitComplete => {
  const dispatch = useDispatch();

  // useAppInit becomes Ready when we know auth tokens are in memory
  useEffect(() => {
    // Proceed with Session Init when Each Local Var is Checked, and Session is not yet ready
    if (allTrue(!isInitComplete)) {
      /**
       *
       */
      const searchParams = getQueryStringParams(window.location.search);
      const {
        REACT_APP_ENABLE_PAYWALL_FEATURE_FLAG: isPaywallFlaggingEnabled,
      } = process.env;

      const paywallFlag = 'hasPaywall';

      // Paywall presence defaults to `true`
      let hasPaywall = true;

      // Paywall presence can only be modified with a Flag if the following conditions are true
      const paywallFlaggingConditions = [
        searchParams.hasOwnProperty(paywallFlag), // The paywall flag exists in the Querystring
        parseBooleanString(isPaywallFlaggingEnabled || 'false'), // Paywall flagging is enabled in this environment
      ];

      if (allTrue(...paywallFlaggingConditions)) {
        hasPaywall = parseBooleanString(searchParams[paywallFlag]);
      }

      dispatch(acSetPaywallStatus(hasPaywall));

      // Allow downloading of the mobile app from the results page if the appropriate feature-flag is enabled in configuration.
      const {
        REACT_APP_ENABLE_APP_DOWNLOAD_FEATURE_FLAG: isDownloadAppEnabled,
      } = process.env;

      let downloadAppEnabled = parseBooleanString(
        isDownloadAppEnabled || 'false'
      );

      dispatch(acSetDownloadAppStatus(downloadAppEnabled));

      /**
       * Initialize Amplify
       */

      const {
        REACT_APP_AWS_REGION: region,
        REACT_APP_COGNITO_IDENTITY_POOL_ID: identityPoolId,
        REACT_APP_COGNITO_USER_POOL_ID: userPoolId,
        REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID: userPoolWebClientId,
      } = process.env;
      Amplify.configure({
        // ...awsconfig,
        Auth: {
          region,
          identityPoolId,
          userPoolId,
          userPoolWebClientId,
          oauth: {},
        },
      });
      // Amplify.Logger.LOG_LEVEL = 'DEBUG';

      I18n.putVocabulariesForLanguage('en-US', {
        [Translations.SIGN_IN_HEADER_TEXT]: 'Sign In',
        [Translations.SIGN_IN_ACTION]: 'Login',
      });

      /**
       * Google Tag Manager
       */

      const { REACT_APP_GOOGLE_TAG_MANAGER_ID } = process.env;

      const tagManagerArgs = {
        gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID as string,
      };

      TagManager.initialize(tagManagerArgs);

      var gaClientId = Cookies.get('_ga')?.split('.').slice(-2).join('.') || '';

      dispatch(acSetGaClientId(gaClientId));

      dispatch(acInitIsComplete());
    }
  }, [isInitComplete]);
};
