import React from 'react';
import Essay from '../Essay';
import EssayInterior from '../EssayInterior';
import { IContainer } from '../../global/interfaces';
import { cardDefaults } from './props';


interface ICard extends IContainer {
  p?: string;
  bgColor?: string;
  rounded?: number;
  boxShadow?: string;
  overflow?: string;
}

/**
 * @constructor Card
 * @description Basic app card component.  Renders with optional props.  Makes use of Essay and EssayInterior, which are abstracted content containers.
 * @param bgColor - optional background color
 * @param boxShadow - optional box shadow
 * @param rounded - optional border radius
 * @param children - optional children
 * @param overflow - optional overflow
 * @param p - optional padding
 * @param rest -  optional props to pass to Essay
 * @returns {JSX.Element}
 */
const Card: React.FC<ICard> = ({
  bgColor = cardDefaults.bgColor,
  boxShadow = cardDefaults.boxShadow,
  rounded = cardDefaults.rounded,
  children,
  overflow = 'hidden',
  p,

  ...rest
}) => {
  const cardProps = {
    bgColor,
    boxShadow,
    overflow,
    rounded,
    w: '100%',
    ...rest,
  };
  const interiorProps = {
    p,
  };
  return (
    <Essay {...cardProps}>
      <EssayInterior {...interiorProps}>{children}</EssayInterior>
    </Essay>
  );
};

export default Card;
