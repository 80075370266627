import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @constructor ScrollToTop
 * @description Scroll Restore component.  Return to top of page on route change.
 * @returns null
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
