import {
  shallowEqual,
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { TRootState } from '../store';

export const useSelector: TypedUseSelectorHook<TRootState> = useReduxSelector;
/**
 * @function useSlices
 * @description Hook to get multiple slices from the store.  Reduces boilerplate.
 * @param sliceNames - Names of the slices to get from the store.
 */
export const useSlices = (...sliceNames: string[]): TRootState[] =>
  useSelector(state => sliceNames.map((name: string) => state[name]));

/**
 * @function useSlicesShallow
 * @description Hook to get multiple slices from the store.  Reduces boilerplate.  Uses shallowEqual to compare slices.
 * @param sliceNames - Names of the slices to get from the store.
 */
export const useSlicesShallow = (...sliceNames: string[]): TRootState[] =>
  useSelector(
    state => sliceNames.map((name: string) => state[name]),
    shallowEqual
  );
