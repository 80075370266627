import React from 'react';
import { Box, Link, List, ListItem } from '@chakra-ui/react';
import ButtonSignOut from '../ButtonSignOut';
import { utilityButtonProps } from '../AppHeader/AppHeaderView/props';
import { pageGutter } from '../../pages/Page/props';
import LinkRoute from '../LinkRoute';
import { getDomainsPath } from '../../global/helpers';
import { getTypography } from '../../theme/_';
import { createGutterProps } from '../../theme/helpers';

export interface IMenuMainView {
  isLoggedIn: boolean;
  onMenuItemClick: () => void;
}

const [gWrap, gItem] = createGutterProps(pageGutter);

/**
 * @constructor MenuMainView
 * @description Renders the main menu.  View component.  Receives props from MenuMain and renders the view.
 * @param isLoggedIn
 * @param onMenuItemClick
 * @param rest
 * @constructor
 */
export const MenuMainView: React.FC<IMenuMainView> = ({
  isLoggedIn,
  onMenuItemClick,
  ...rest
}) => {
  const containerProps = {
    pt: '50px',
    px: `${pageGutter}px`,
    ...rest,
  };
  const listProps = {
    ...gWrap,
  };
  const listItemProps = {
    ...gItem,
  };

  const linkStyleProps = {
    color: 'blue.800',
    _hover: {
      textDecoration: 'underline',
    },
    ...getTypography('h5'),
  };

  const linkProps = {
    ...linkStyleProps,
    onClick: onMenuItemClick,
  };
  const signoutProps = {
    ...utilityButtonProps,
    ...linkStyleProps,
    callback: onMenuItemClick,
    height: 'auto',
  };

  return (
    <Box {...containerProps}>
      <List {...listProps}>
        <ListItem {...listItemProps}>
          <LinkRoute {...linkProps} to={getDomainsPath()}>
            Profile
          </LinkRoute>
        </ListItem>
        <ListItem {...listItemProps}>
          <Link
            {...linkProps}
            href={'https://courses.cadey.co/'}
            target={'_blank'}
          >
            Courses
          </Link>
        </ListItem>
        <ListItem {...listItemProps}>
          <Link {...linkProps} href={'https://cadey.co/app'} target={'_blank'}>
            Mobile App
          </Link>
        </ListItem>
        <ListItem {...listItemProps}>
          <ButtonSignOut {...signoutProps} />
        </ListItem>
      </List>
    </Box>
  );
};

export default MenuMainView;
