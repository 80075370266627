import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Slice } from '../global/constants';
import { acSetAssessmentDomainId } from '../store';
import { allTrue } from '../global/helpers';
import { useSlices } from './useSlices';

/**
 * @function useSelectedDomain
 * @description Hook which returns a Domain object for the given domain slug.
 * @param domainSlug - The slug of the domain to return.
 */
export const useSelectedDomain = domainSlug => {
  const dispatch = useDispatch();

  const [domainsState, personsState] = useSlices(Slice.DOMAINS, Slice.PERSONS);
  const { domains } = domainsState;
  const { currentPersonId } = personsState;
  const selectedDomain =
    domains.find(domain => domain.slug === domainSlug) || {};
  const { id: selectedDomainId } = selectedDomain;

  useEffect(() => {
    if (allTrue(!!currentPersonId, !!selectedDomainId)) {
      dispatch(acSetAssessmentDomainId(selectedDomainId));
    }
  }, [currentPersonId, selectedDomainId]);

  return {
    ...selectedDomain,
  };
};
