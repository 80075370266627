import { Slice } from '../../../../../global/constants';
import { allTrue } from '../../../../../global/helpers';
import { createAssessmentAPIInteractions } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSlices } from '../../../../../hooks/useSlices';

/**
 * useInitDomains
 * @description This hook is responsible for initializing the Domains slice of the store.
 * @param isAssessmentsInit
 * @returns isDomainsInit
 */
export const useInitDomains: (isAssessmentsInit: boolean) => boolean =
  isAssessmentsInit => {
    const dispatch = useDispatch();
    const [{ currentAssessmentId: assessmentId }, { isInit: isDomainsInit }] =
      useSlices(Slice.ASSESSMENTS, Slice.DOMAINS);

    const { acGetAssessmentDomains } =
      createAssessmentAPIInteractions(assessmentId);
    // If session is ready, then we are free to hit the API.  Get Domain Definitions
    useEffect(() => {
      if (allTrue(isAssessmentsInit, !isDomainsInit)) {
        dispatch(acGetAssessmentDomains());
      }
    }, [isAssessmentsInit, assessmentId, isDomainsInit]);
    return isDomainsInit;
  };
