import React, {
  useRef,
  useState,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { Box } from '@chakra-ui/react';
import { SystemBreakpoints, TBreakpoints, TUIStyles } from '../../theme/theme';
import { useResizeObserver } from './core/useResizeObserver';
import { TGenericObject } from '../../global/types';

// TODO: Convert to Stateful Component Pattern

/**
 * Utility Functions
 */

const flattenUIStyles = (
  breakpoints: string[],
  uiStyleProps: TUIStyles
): TGenericObject =>
  breakpoints.reduce(
    (acc: TGenericObject, bp: string) => ({
      ...acc,
      ...uiStyleProps[bp],
    }),
    {}
  );

const getActiveBreakpointsNames = (
  breakpoints: TBreakpoints,
  containerWidth: number
) =>
  Object.entries(breakpoints)
    .map(([bpName, width]) => ({ bpName, width }))
    .filter(({ width }) => width < containerWidth)
    .map(({ bpName }) => bpName);

/**
 * Responsive Context
 */
interface IResponsiveContext {
  activeSystemBPNames: string[];
  containerWidth: number;
}
const ResponsiveContext = createContext<IResponsiveContext>({
  activeSystemBPNames: [],
  containerWidth: -1,
});

/**
 * `ResponsiveContainer` Component
 */

interface IResponsiveContainer {
  children?: React.ReactNode;
  ui?: TUIStyles;
}

/**
 * @constructor QueryContainer
 * @description Container component that provides a responsive context to its children.  Imparts the functionality of a container query.  It applies any container query logic that may have been passed to it, and then determines which breakpoints are active, and makes that available to its children.
 * @param children - Children to be rendered
 * @param ui - UI styles associated with each its applicable breakpoints
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const QueryContainer: React.FC<IResponsiveContainer> = ({
  children,
  ui = {},
  ...rest
}) => {
  const ref = useRef(null);
  const [activeSystemBPNames, setActiveContainerBPNames] = useState<
    Array<string>
  >([]);

  const maybeSetBreakpoints = (clientRect: any) => {
    const activeBreakpointNames = getActiveBreakpointsNames(
      SystemBreakpoints,
      clientRect.width
    );
    setActiveContainerBPNames(activeBreakpointNames);
  };

  const [containerWidth] = useResizeObserver(ref, maybeSetBreakpoints);

  const bpString = activeSystemBPNames.join(' ');
  const getUIStyles = useCallback(
    ui => flattenUIStyles(activeSystemBPNames, ui),
    [bpString]
  );
  const boxProps = {
    ref,
    ...rest,
    ...getUIStyles(ui),
  };

  const value = { activeSystemBPNames, containerWidth };

  return (
    <Box {...boxProps}>
      <ResponsiveContext.Provider {...{ value }}>
        {children}
      </ResponsiveContext.Provider>
    </Box>
  );
};

/**
 * Hook to retrieve `activeBreakpoints` from Context
 */

type TUseContainerQuery = (localBreakpoints?: TBreakpoints) => {
  activeBreakpointNames: string[];
  getUIStyles: (ui: TUIStyles) => TGenericObject;
};

/**
 * @function useContainerQuery
 * @description Hook to retrieve `activeBreakpoints` from Context, and to provide a function to format a system-usable set of UI styles based on the active breakpoints.
 * @param localBreakpoints - Optional Local Breakpoints to be used in addition to the System Breakpoints
 */
export const useContainerQuery: TUseContainerQuery = (
  localBreakpoints = {}
) => {
  // Retrieve System Breakpoint info from the Context
  const { activeSystemBPNames, containerWidth } = useContext(ResponsiveContext);

  // Determine which of the Local Breakpoint names are active
  const activeLocalBPNames = getActiveBreakpointsNames(
    localBreakpoints,
    containerWidth
  );

  // Combine `System` breakpoints with `Local` breakpoints.  `Local` overrides `System`
  const activeBreakpointNames = [
    // @ts-ignore
    ...new Set([...activeSystemBPNames, ...activeLocalBPNames]),
  ];

  // Memoize UIStyle formatter.  Update it when the active breakpoints change
  const bpString = activeBreakpointNames.join(' ');
  const getUIStyles: (ui: TUIStyles) => TGenericObject = useCallback(
    ui => flattenUIStyles(activeBreakpointNames, ui),
    [bpString]
  );

  return {
    activeBreakpointNames,
    getUIStyles,
  };
};
export default QueryContainer;
