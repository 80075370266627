import { useEffect } from 'react';

// TODO: Get Autofocus Hook Working
export const useInputAutofocus = (
  refInput: React.RefObject<HTMLInputElement>
) => {
  useEffect(() => {
    if (!!refInput.current) {
      console.log('autofocus!!', refInput);
      refInput.current.focus();
    }
  }, []);
};
