export const mainNavHeight = '56px';
export const uiNavMain = {
  alignItems: 'center',
  bgColor: 'sand.500',
  borderBottom: `1px`,
  borderColor: 'brown.400',
  color: 'blue.800',
  height: mainNavHeight,
  padding: 5,
  position: 'relative',
  width: '100%',
};
export const uiCell = {
  minH: 1,
  display: 'flex',
  flex: 1,
};
