import GaugeChart from '../../Charts/GaugeChart';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Copy, H2 } from '../../Prose';
import { IconDomain } from '../../IconDomain';
import {
  contentAreaProps,
  copyProps,
  GaugeCopyMap,
  getIconSize,
  IGaugeView,
  wrapProps,
} from './common';

const headingProps = {
  mb: '-0.19em',
};
const diameter = 150;

/**
 * @constructor GaugeViewLarge
 * @description A GaugeViewLarge is a component that displays a score and a concern level in a Large form factor.
 * @param concernLevel - concern level of the domain
 * @param domainDefId - domain definition id
 * @param isScored - whether the domain has been scored
 * @param score - score of the domain
 * @returns {JSX.Element}
 */
const GaugeViewLarge: React.FC<IGaugeView> = ({
  concernLevel,
  domainDefId,
  isScored,
  score,
}) => {
  const gaugeChartProps = {
    score,
    diameter,
    concernLevel: concernLevel,
  };

  const domainIconProps = {
    domainId: domainDefId,
    size: getIconSize(diameter),
  };

  const ringContent =
    isScored ? (
      <>
        <H2 {...headingProps}>{score}</H2>
        <Copy {...copyProps}>{GaugeCopyMap.get(concernLevel)}</Copy>
      </>
    ) : (
      <IconDomain {...domainIconProps} />
    );

  return (
    <Box {...wrapProps}>
      <Box {...contentAreaProps}>{ringContent}</Box>
      <GaugeChart {...gaugeChartProps} />
    </Box>
  );
};

export default GaugeViewLarge;
