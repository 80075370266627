import React from 'react';
import { IDomainButton } from './interfaces';
import { buttonUIProps } from './props';
import { useEditDomain } from '../../hooks/useEditDomain';
import Button from '../Button';

/**
 * @constructor ButtonRetakeSurvey
 * @description Button to retake a survey
 * @param domainDefId
 * @param callback
 * @returns {JSX.Element}
 */
export const ButtonRetakeSurvey: React.FC<IDomainButton> = ({
  domainDefId,
  callback,
}) => {
  const editDomain = useEditDomain();
  const completeDomainProps = {
    ...buttonUIProps,
    onClick: () => {
      if (!!callback) {
        callback();
      }
      editDomain(domainDefId);
    },
  };
  return <Button {...completeDomainProps}>Retake</Button>;
};

export default ButtonRetakeSurvey;
