import { getTypography } from '../../../theme/_';

const errorColor = 'red.800';
const uiCommon = {
  borderRadius: 0,
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
};

const uiInvalid = {
  _invalid: {
    borderColor: errorColor,
    ...uiCommon,
  },
};
const uiPlaceholder = {
  _placeholder: {
    color: 'brown.500',
  },
};

export const uiInput = {
  ...uiCommon,
  ...uiInvalid,
  ...uiPlaceholder,
  ...getTypography('h2'),
  autocomplete: 'off',
  borderColor: 'brown.400',
  color: 'blue.800',
  px: 0,
};

export const uiLabel = {
  ...getTypography('caption'),
  fontWeight: 'bold',
};

export const uiSubmit = {
  variant: 'primary',
  type: 'submit',
  mt: '50px',
};

export const uiErrorMsg = {
  color: errorColor,
};

const checkboxBorderColor = 'brown.400';
export const uiOptInCheckbox = {
  alignItems: 'flex-start',
  iconColor: 'blue.800',
  defaultChecked: true,
  borderColor: checkboxBorderColor,
  _active: {
    borderColor: checkboxBorderColor,
  },
  sx: {
    '.chakra-checkbox__control': {
      transform: 'translateY(3px)',
    },
  },
};

export const uiHeading = {
  mb: '35px',
};
