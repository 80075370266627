import React from 'react';
import * as Showdown from 'showdown';
import ReactHtmlParser from 'react-html-parser';
import { current } from '@reduxjs/toolkit';
import { differenceInYears, parse } from 'date-fns';
import { TGenericObject, TNullable } from './types';
import { getThemeColor } from '../theme/_';
import { navHeight } from './constants';
import * as _ from 'lodash';
import { kebabCase } from 'lodash';
// Convert `Raw Text Markup` to `JSX`

/**
 * @function stringToJSX
 * @description Convert `Raw Text Markup` to `JSX`
 * @param text - Raw Text Markup string
 * @returns JSX.Element
 */
export const stringToJSX = (text: TNullable<string>): React.ReactNode =>
  ReactHtmlParser(text ? text : '');

const mdConverter = new Showdown.Converter();
mdConverter.setFlavor('github');


/**
 * @function mdToJSX
 * @description Convert `Markdown` to `JSX`
 * @param md - Markdown string
 * @returns JSX.Element
 */
export const mdToJSX = (md: string): React.ReactNode =>
  stringToJSX(mdConverter.makeHtml(md));

/**
 * @function immerLog
 * @description Log the current state of an Immer draft
 * @param stateVal - Immer draft value
 * @returns void
 */
export const immerLog = (stateVal: any): void => {
  console.log(current(stateVal));
};


/**
 * @function mapPayloadToState
 * @description Map all payload values to State
 * @param payload - Payload object
 * @param state - State object
 * returns {Object}
 */
export const mapPayloadToState = (payload: any, state: any) => {
  // Map all payload values to State
  for (const key of Object.keys(payload)) state[key] = payload[key];
  return state;
};

/**
 * @function allTrue
 * @description Check if all arguments are true.  Returns false if no arguments are passed.
 * @param args - Boolean arguments
 * returns {boolean}
 */
export const allTrue = (...args: boolean[]): boolean =>
  !args.length || args.every(cond => cond);

/**
 * @function noneTrue
 * @description Check if no arguments are true.  Returns false if no arguments are passed.
 * @param args - Boolean arguments
 * returns {boolean}
 */
export const noneTrue = (...args: boolean[]): boolean =>
  !args.length || args.every(cond => !cond);


/**
 * @function noneTrue
 * @description Check if at least 1 argument is true.  Returns false if no arguments are passed.
 * @param args - Boolean arguments
 * returns {boolean}
 */
export const someTrue = (...args: boolean[]): boolean =>
  !args.length || args.some(cond => cond);

/**
 * @function maybePlural
 * @description Return the singular or plural form of a word based on the quantity
 * @param quantity - Value to determine plurlarity
 * @param singular - Singular form of the word
 * @param plural - Plural form of the word
 * returns {string}
 */
export const maybePlural = (
  quantity: number,
  singular: string,
  plural: string
): string => (quantity === 1 ? singular : plural);

/**
 * @function makePossessive
 * @description Modify a name to make it possessive
 * @param name - Name to modify
 */
export const makePossessive = (name = ''): string =>
  `${name}'${name.toLowerCase().slice(-1) !== 's' ? 's' : ''}`;

/**
 * @function isString
 * @description Check if a value is a string
 * @param value - Value to check
 * returns {boolean}
 */
export const isString = (value: any): boolean =>
  Object.prototype.toString.call(value) === '[object String]';

type TPathFragment = string;
/**
 * @function maybeJoinFrags
 * @description Join two path fragments if the second is not undefined
 * @param frag1 - First path fragment
 * @param frag2 - Second path fragment
 * returns {string}
 */
const maybeJoinFrags = (frag1: TPathFragment, frag2?: TPathFragment): string =>
  [frag1, ...(!!frag2 ? [frag2] : [])].join('/');

/**
 * @function getRootPath
 * @description Generate Root path.  If a path fragment is passed, it will be appended to the root path.
 * @param pathFrag - Path fragment
 * returns {string}
 */
export const getRootPath = (pathFrag?: TPathFragment): string =>
  maybeJoinFrags('', pathFrag);


/**
 * @function getAuthPath
 * @description Generate Auth path.  If a path fragment is passed, it will be appended to the auth path.
 * @param pathFrag - Path fragment
 * returns {string}
 */
export const getAuthPath = (pathFrag?: TPathFragment): string =>
  maybeJoinFrags(getRootPath('auth'), pathFrag);


/**
 * @function getSideQuestPath
 * @description Generate Side Quest path with provided quest slug
 * @param quest - Quest slug
 */
export const getSideQuestPath = (quest: string): string => {
  return maybeJoinFrags(getAuthPath(`${quest}-entry`));
};


/**
 * @function getDomainPath
 * @description Generate Domain path.  If a path fragment is passed, it will be appended to the domain path.
 * @param pathFrag
 */
export const getDomainsPath = (pathFrag?: TPathFragment): string =>
  maybeJoinFrags(getAuthPath('facets'), pathFrag);


/**
 * @function getResultsPath
 * @description Generate Results (Plural) path.  If a path fragment is passed, it will be appended to the results path.
 * @param pathFrag - Path fragment
 * returns {string}
 */
export const getResultsPath = (pathFrag?: TPathFragment): string =>
  maybeJoinFrags(getAuthPath('results'), pathFrag);


/**
 * @function getResultsPath
 * @description Generate Result (Singular) path.  If a path fragment is passed, it will be appended to the result path.
 * @param pathFrag - Path fragment
 * returns {string}
 */
export const getResultPath = (pathFrag?: TPathFragment): string =>
  maybeJoinFrags(getAuthPath('result'), pathFrag);

/**
 * @function getSymptomDetailPath
 * @description Generate Symptom Detail path.  If a path fragment is passed, it will be appended to the symptom detail path.
 * @param pathFrag - Path fragment
 * returns {string}
 */
export const getSymptomDetailPath = (pathFrag?: TPathFragment): string =>
  maybeJoinFrags(getAuthPath('symptom'), pathFrag);


/**
 * @function getQuestionnairePath
 * @description Generate Questionnaire path from domain slug.  If no domain slug is passed, the path fragment will be a param variable
 * @param domainSlug - Domain slug
 * returns {string}
 */
export const getQuestionnairePath = (
  domainSlug: string = ':domainSlug'
): string => maybeJoinFrags(getDomainsPath('questionnaire'), domainSlug);

/**
 * @function fireGTMEvent
 * @description Fire a GTM event
 * @param event - Event name
 * @param payload - Event payload
 * returns void
 */
export const fireGTMEvent = (event: any, payload: any): void => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({ event, ...payload });
};

const titleCaseSep = ' ';
/**
 * @function toTitleCase
 * @description Convert a string to title case
 * @param s - String to convert
 * returns {string}
 */
export const toTitleCase = (s: string): string =>
  s
    .split(titleCaseSep)
    .map(word => (word.length > 3 ? _.capitalize(word) : word))
    .join(titleCaseSep);


/**
 * @function parseValue
 * @description Generac function to convert a string to its actual value.
 * @param value
 * returns {any}
 */
export const parseValue = (value: any): any => JSON.parse(value);


/**
 * @function createComponentProps
 * @description General Treatment for Component props.  Appends common props to the passed props object.
 * @param dataTestId - Data test id for Jest testing
 * @param props - Props object
 * returns {TGenericObject}
 */
export const createComponentProps = (
  dataTestId: string,
  props: TGenericObject
) => ({
  'data-testid': dataTestId,
  ...props,
});

/**
 * @function normalizeColor
 * @description Normalize a Chakra color string (Blue.200) to it's actual hex value
 * @param rawColor
 * returns {string}
 */
export const normalizeColor = (rawColor: string) => {
  const [color, value] = rawColor.split('.');
  return !!value ? getThemeColor(color, parseInt(value)) : color;
};

/**
 * @function boolToText
 * @description Convert a boolean to a string.  Used for visual testing of boolean values.
 * @param value
 */
export const boolToText = (value: TNullable<boolean>) =>
  value === true ? 'Yep' : value === false ? 'Nope' : 'Meh';

/**
 * @function performScrollTo
 * @description Perform a scroll to a specific position. Defaults to auto behavior.
 * @param top - Position to scroll to
 * @param behavior - Scroll behavior
 */
export const performScrollTo = (
  top: number,
  behavior: ScrollBehavior = 'auto'
) => {
  window.scrollTo({
    top,
    behavior,
  });
};

/**
 * @function getScrollPosition
 * @description Get the current scroll position
 * returns {number}
 */
export const getScrollPosition = (): number => window.scrollY;

/**
 * @function getQueryStringParams
 * @description Get query string params from a query string, and return them as an object
 * @param queryString
 * returns {TGenericObject}
 */
export const getQueryStringParams = (
  queryString: string
): {
  [key: string]: string;
} => {
  if (!queryString) return {};

  return queryString
    .slice(1)
    .split(';')
    .reduce(
      (acc, keyval) => ({
        ...acc,
        [keyval.split('=')[0]]: keyval.split('=')[1],
      }),
      {}
    );
};
/**
 * @function parseBooleanString
 * @description Parse a boolean string to a boolean value
 * @param s - String to parse
 * returns {boolean}
 */
export const parseBooleanString = (s: string) => s === 'true' || false;

export const centerFloatProps = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

/**
 * @function getCadeySlug
 * @description Wrapper for a string case conversion.  Converts a string of any case to a specific case.  In this case, kebab case.
 * @param text
 */
export const getCadeySlug = (text:string) => kebabCase(text)
