import api from '../../../../services/api/api';
import { Slice } from '../../../../global/constants';
import { TAssessmentItem } from '../../sliceAssessments';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { maybeGetResponseData } from '../../../../services/api/core/maybeGetResponseData';

export const getQuestionnaire = createAsyncThunk<
  { items: TAssessmentItem[] },
  { assessmentId: number; domainId: number }
>(`${Slice.QUESTIONNAIRE}/get`, async ({ assessmentId, domainId }) => {
  const assessmentItemsResponse = await api.get(
    `assessment/${assessmentId}/domain/${domainId}/sorttype/display/items`
  );
  const items = maybeGetResponseData(assessmentItemsResponse) || [];

  return { items };
});
