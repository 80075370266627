import { createAsyncThunk } from '@reduxjs/toolkit';
import { Slice } from '../../../../global/constants';
import api from '../../../../services/api/api';
import { allTrue } from '../../../../global/helpers';
import { acSetAssessment } from '../../../store';
import { prepareAssessmentDomain, preparePlainDomain } from './helpers';
import { maybeGetResponseData } from '../../../../services/api/core/maybeGetResponseData';
import { TDomain } from '../sliceDomains';

export const getCadeDomains = createAsyncThunk<
  { domains: TDomain[] },
  { assessmentId: number }
>(
  `${Slice.DOMAINS}/get/type/definitions`,
  async ({ assessmentId }, { dispatch }) => {
    // We grab Domains from one of 2 places, based on the existence of an assessmentId
    const domainsFetchUrl = !assessmentId
      ? `definition/domainList`
      : `assessment/${assessmentId}/includeitems/true`;

    const response = await api.get(domainsFetchUrl);
    const responseData = maybeGetResponseData(response) || null;

    // Domains will be populated in one or the other
    const { domains: plainDomains, assessmentDomains } = responseData;

    // Select which of the above domains is not null
    const contextualDomains = plainDomains || assessmentDomains || [];

    // Prepare differently, based on what kind of Domains we are dealing with
    const domainPrepFunction = !!assessmentId
      ? prepareAssessmentDomain
      : preparePlainDomain;

    if (allTrue(!!assessmentId, !!responseData)) {
      // @ts-ignore
      dispatch(acSetAssessment(responseData));
    }

    const domains = (contextualDomains as TDomain[])
      .map(domainPrepFunction)
      .sort((a, b) => a.displayOrder - b.displayOrder);

    return {
      domains,
    };
  }
);

export const getPersonalizedDomains = createAsyncThunk<
  { domains: TDomain[] },
  { keyphrase: string }
>(`${Slice.DOMAINS}/search/Domains`, async ({ keyphrase }) => {
  // We grab Domains from one of 2 places, based on the existence of an assessmentId
  const response = await api.post(`search`, { keyphrase });

  return {
    domains: maybeGetResponseData(response) || [],
  };
});
