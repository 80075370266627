import { TGenericObject } from '../../../global/types';
import { useEffect, useRef } from 'react';
import { allTrue } from '../../../global/helpers';
import { Box } from '@chakra-ui/react';

type TD3FuncProps<TDatum> = {
  data: TDatum[];
  d3Canvas: SVGSVGElement;
  w: number;
  h: number;
};
export type TD3Func<TDatum> = (props: TD3FuncProps<TDatum>) => void;

export interface ID3Chart {
  w?: number;
  h?: number;
  data: TGenericObject[];
  func: TD3Func<any>;
}

/**
 * @constructor D3Chart
 * @description Wrapper component that abstracts and simplifies the juxtaposition of D3 and React
 * @param data - Data to be used in the D3 Chart
 * @param func - Function to be used to render the D3 Chart
 * @param w - Width of the D3 Chart
 * @param h - Height of the D3 Chart
 * @param rest - Props to be passed to the wrapping Box
 * @returns {JSX.Element}
 */
const D3Chart: React.FC<ID3Chart> = ({
  data,
  func,
  w = 300,
  h = 300,
  ...rest
}) => {
  const canvasRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (allTrue(!!data, !!canvasRef.current)) {
      const d3Canvas = canvasRef.current!;
      func({ data, d3Canvas, w, h });
    }
  }, [data, canvasRef.current]);

  const wrapProps = {
    width: w,
    height: h,
  };
  const canvasProps = {
    ref: canvasRef,
    transform: `translate(${w / 2},${h / 2})`,
  };
  return (
    <Box {...rest}>
      <svg {...wrapProps}>
        <g {...canvasProps}></g>
      </svg>
    </Box>
  );
};

export default D3Chart;
