import { Box } from '@chakra-ui/react';
import { createHtmlFormatting } from './core/ui';
import { getTypography } from '../../theme/_';
import { ICore } from '../../global/interfaces';
import React from 'react';
import { createComponentProps, stringToJSX } from '../../global/helpers';

interface IContent extends ICore {
  elSeparation?: string;
  children?: React.ReactNode;
}

export const testId = 'content';
/**
 * @constructor Content
 * @description Renders readable copy content and applies consistent formatting
 * @param childrenRaw - if string, will be converted to JSX
 * @param elSeparation  - Spacing between content elements
 * @param dataTestId - Test ID for Jest
 * @param rest  - Props to be passed to the wrapping Box
 * @returns {JSX.Element}
 */
export const Content: React.FC<IContent> = ({
  children: childrenRaw,
  elSeparation = '1em',
  dataTestId = testId,
  ...rest
}) => {
  const contentProps = createComponentProps(dataTestId, {
    ...rest,
    ...getTypography('copy'),
    sx: createHtmlFormatting(elSeparation),
  });

  const children = (typeof childrenRaw === 'string') ? stringToJSX(childrenRaw) : childrenRaw
  return <Box {...contentProps}>{children}</Box>;
};
export default Content;
