import { Box, ResponsiveValue } from '@chakra-ui/react';
import { uiBody } from './core/ui';
import { uiPageInterior } from '../../pages/Page/props';
import { maxPageInteriorWidth } from '../../global/constants';
import React from 'react';

interface IPageBody {
  children?: React.ReactNode;
  bgColor?: string;
  maxW?: string;
}

/**
 * @constructor PageBody
 * @description Renders the body of a page. Applies consistent styling.
 * @param children
 * @param maxW
 * @param rest
 * @constructor
 */
export const PageBody: React.FC<IPageBody> = ({
  children,
  maxW = `${maxPageInteriorWidth}px`,
  ...rest
}) => {
  const pageBodyProps = {
    position: 'relative' as ResponsiveValue<any>,
    flex: 1,
    ...uiBody,
    ...rest,
  };
  const pageInteriorProps = {
    ...uiPageInterior,
    maxW,
    position: 'relative' as ResponsiveValue<any>,
  };
  return (
    <Box {...pageBodyProps}>
      <Box {...pageInteriorProps}>{children}</Box>
    </Box>
  );
};
export default PageBody;
