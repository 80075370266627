import { Slice, UserStatus } from '../../../global/constants';
import { performTemporaryLogin, updatePassword } from './core/thunks';
import { authenticateCadeUser } from '../sliceAccount';
import { buildSlice } from '../core/helpers';
import { PayloadAction } from '@reduxjs/toolkit';
import { TNullable } from '../../../global/types';

export type TSessionState = {
  authTokens: null;
  hasPaywall: boolean;
  downloadApp: boolean;
  isAuthZone: boolean;
  isInitComplete: boolean;
  isReady: boolean;
  isTempLogging: boolean;
  isTimeout: boolean;
  isUserAuthenticated: boolean;
  scrollPosition: number;
  userStatus: number;
  gaClientId: TNullable<string>;
};
interface ISessionState extends TSessionState {}

const initialState: ISessionState = {
  authTokens: null,
  hasPaywall: true,
  downloadApp: false,
  isAuthZone: false,
  isInitComplete: false,
  isReady: false,
  isTempLogging: false,
  isTimeout: false,
  isUserAuthenticated: false,
  scrollPosition: 0,
  userStatus: UserStatus.PERMANENT,
  gaClientId: null,
};

export const sliceSession = buildSlice({
  name: Slice.SESSION,
  initialState,
  reducers: {
    userStatusSet: (
      state,
      { payload }: PayloadAction<{ userStatus: number }>
    ) => {
      state.userStatus = payload.userStatus;
    },
    isAuthZoneSet: (
      state,
      { payload }: PayloadAction<{ isAuthZone: boolean }>
    ) => {
      state.isAuthZone = payload.isAuthZone;
    },
    timeoutIsSet: (
      state,
      { payload }: PayloadAction<{ isTimeout: boolean }>
    ) => {
      state.isTimeout = payload.isTimeout;
    },
    sessionIsReady: state => {
      state.isReady = true;
    },
    initIsComplete: state => {
      state.isInitComplete = true;
    },
    scrollPositionChanged: (
      state,
      { payload }: PayloadAction<{ scrollPosition: number }>
    ) => {
      state.scrollPosition = payload.scrollPosition;
    },
    paywallStatusSet: (
      state,
      { payload }: PayloadAction<{ hasPaywall: boolean }>
    ) => {
      state.hasPaywall = payload.hasPaywall;
    },
    downloadAppStatusSet: (
      state,
      { payload }: PayloadAction<{ isDownloadAppEnabled: boolean }>
    ) => {
      state.downloadApp = payload.isDownloadAppEnabled;
    },
    gaClientIdSet: (
      state,
      { payload }: PayloadAction<{ gaClientId: string }>
    ) => {
      state.gaClientId = payload.gaClientId;
    },
  },
  extraReducers: {
    [performTemporaryLogin.pending.type]: state => {
      state.isTempLogging = true;
    },
    [performTemporaryLogin.fulfilled.type]: state => {
      state.isTempLogging = false;
      state.userStatus = UserStatus.TRIAL;
    },
    [authenticateCadeUser.fulfilled.type]: state => {
      state.isUserAuthenticated = true;
    },
    [updatePassword.fulfilled.type]: state => {
      state.userStatus = UserStatus.PERMANENT;
    },
  },
});
