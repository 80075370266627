const transitionTimeInMs = 500;
export const uiBase = {
  transition: `opacity ${transitionTimeInMs}ms`,
};

/**
 * @function uiVisibility
 * @description Returns the UI properties for the visibility of the element.
 * @param isElementVisible
 * @returns {{pointerEvents: (string), opacity: (number)}}
 */
export const uiVisibility = isElementVisible => ({
  pointerEvents: isElementVisible ? 'auto' : 'none',
  opacity: isElementVisible ? 1 : 0,
});
