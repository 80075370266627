import useEmbarkConditions_Inquiry from './useEmbarkConditions_Inquiry';
import useEmbarkConditions_Person from './useEmbarkConditions_Person';
import { Quest } from '../core/data';
import { getCurrentQuestPath } from '../core/helpers';
import { getQuestionnairePath } from '../../global/helpers';
import { useDomainMap } from '../../hooks/useDomainMap';

/**
 *
 * @param domainId
 * @param completedQuest
 */
export const useQuestionnaireRoute = (
  domainId: number,
  completedQuest?: string
): string => {
  const { shouldEmbarkName, shouldEmbarkAge } = useEmbarkConditions_Person();
  const { shouldEmbarkInquiry } = useEmbarkConditions_Inquiry();

  // Set final destination
  const questionnairePath = getQuestionnairePath(useDomainMap()[domainId]?.slug || 'invalid');

  const questSpecs = [
    { quest: Quest.NAME, shouldEmbark: shouldEmbarkName },
    { quest: Quest.AGE, shouldEmbark: shouldEmbarkAge },
    { quest: Quest.Q_INQUIRY, shouldEmbark: shouldEmbarkInquiry },
  ];

  console.log(questSpecs);

  return getCurrentQuestPath(questSpecs, completedQuest, questionnairePath);
};
