export const incrementQuestionIdx = state => {
  const { currentQuestionIdx } = state;
  const nextQuestionIdx = currentQuestionIdx + 1;

  state.currentQuestionIdx =
    nextQuestionIdx < state.questionCount
      ? nextQuestionIdx
      : currentQuestionIdx;
};

export const decrementQuestionIdx = state => {
  const { currentQuestionIdx } = state;
  const nextQuestionIdx = currentQuestionIdx - 1;
  state.currentQuestionIdx =
    nextQuestionIdx >= 0 ? nextQuestionIdx : currentQuestionIdx;
};
