import React from 'react';
import { ButtonKind } from '../Button';
import Button from '../Button';
import { TGenericFunction } from '../../global/types';
import { Icon } from '../Icon';
import { IconType } from '../Icon/core/enum';

export interface IButtonBackView {
  clickAction: TGenericFunction;
}

/**
 * @constructor ButtonBackView
 * @description View element of BackButton component
 * @param clickAction
 * @param rest
 * @constructor
 */
export const ButtonBackView: React.FC<IButtonBackView> = ({
  clickAction,
  ...rest
}) => {
  // const blah = clickAction
  const buttonProps = {
    kind: ButtonKind.UTILITY,
    onClick: clickAction,
    variant: 'unstyled',
    ...rest,
  };
  return (
    <Button {...buttonProps}>
      <Icon type={IconType.ARROW_LEFT} />
    </Button>
  );
};

export default ButtonBackView;
