import React from 'react';
import { Box, List, ListItem } from '@chakra-ui/react';
import { TAssessmentItem } from '../../../store/slices';
import { createListItemProps, createListProps, wrapProps } from './props';
import Question, { IQuestion } from '../_/Question/';

export interface IQuestionnaireView {
  assessmentItems: TAssessmentItem[];
  currentQuestionIdx: number;
  domainId: number;
  domainName: string;
  numberOfItems: number;
  numberOfItemsCompleted: number;
}

/**
 * @constructor QuestionnaireView
 * @description Renders a series of Assessment Questions to be answered by the user.  Uses props from shell to render the questions.
 * @param assessmentItems - Array of Assessment Items
 * @param currentQuestionIdx - Index of the current question
 * @param domainId - Assessment Domain ID
 * @param domainName - Assessment Domain Name
 * @param numberOfItems - Total number of questions in the domain
 * @param numberOfItemsCompleted - Number of questions completed
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const QuestionnaireView: React.FC<IQuestionnaireView> = ({
  assessmentItems,
  currentQuestionIdx,
  domainId,
  domainName,
  numberOfItems,
  numberOfItemsCompleted,
  ...rest
}) => {
  return (
    <Box {...wrapProps}>
      <List {...createListProps(rest)}>
        {assessmentItems.map((assessmentItem, itemIndex) => {
          const { id: assessmentItemId } = assessmentItem;

          const questionProps: IQuestion = {
            assessmentItemId,
            itemDefId: assessmentItem.itemDefId,
            domainId,
            domainName,
            helpTexts: assessmentItem.helpTexts,
            questionCount: numberOfItems,
            questionNumber: itemIndex + 1,
            responseId: assessmentItem.responseId,
            text: assessmentItem.text,
          };

          const listItemProps = createListItemProps(
            assessmentItemId,
            itemIndex,
            numberOfItems,
            currentQuestionIdx
          );

          return (
            <ListItem {...listItemProps}>
              <Question {...questionProps} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
export default QuestionnaireView;
