import { Slice } from '../../global/constants';
import { acSetCurrentQuestionIdx, acSetIsEditMode } from '../../store';
import { allTrue } from '../../global/helpers';
import { useCurrentQuestionnaire } from './core/useCurrentQuestionnaire';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useResultsRoute } from '../../quests/useResultsRoute';
import { useGetVisibleSurveyProps } from '../../store/slices/sliceAssessments/core/useGetVisibleSurveyProps';
import { useParams } from 'react-router-dom';
import { useRedirect } from '../../hooks/useRedirect';
import { useSlices } from '../../hooks/useSlices';
import PageQuestionnaireView, {
  IPageQuestionnaireView,
} from './PageQuestionnaireView';
import withInquiry from './withInquiry';

/**
 * @constructor PageQuestionnaire
 * @description Page component for Questionnaire page. Generates props and passes them to PageQuestionnaireView.
 * @returns {JSX.Element} or null
 */
const PageQuestionnaire = () => {
  const dispatch = useDispatch();
  const getVisibleSurveyProps = useGetVisibleSurveyProps();
  const redirect = useRedirect();
  const feedRoute = useResultsRoute();

  // Param Values
  const { domainSlug } = useParams<{ domainSlug: string }>();

  // State Values
  const [{ currentQuestionIdx, isEditMode }] = useSlices(Slice.QUESTIONNAIRE);

  const { domain } = useCurrentQuestionnaire(domainSlug);
  const domainDefId = domain?.domainDefId;

  const { numberOfItems, numberOfItemsCompleted } =
    getVisibleSurveyProps(domainDefId);

  // Redirect to Results when all questions are answered, and Edit Mode is off
  useEffect(() => {
    const redirectConditions = [
      !isEditMode,
      numberOfItems > 0,
      numberOfItemsCompleted === numberOfItems,
    ];

    if (allTrue(...redirectConditions)) {
      redirect(feedRoute);
    }
  }, [isEditMode, domainSlug, numberOfItems, numberOfItemsCompleted]);

  // On exit of Questionnaire Page
  useEffect(() => {
    return () => {
      // reset `questionIdx` back to `0` when leaving the Questionnaire page
      dispatch(acSetCurrentQuestionIdx(0));

      // Set `isEditMode` to `false`
      dispatch(acSetIsEditMode(false));
    };
  }, []);

  if (!domain) return null;

  const viewProps: IPageQuestionnaireView = {
    loadedConditions: [!!domain.assessmentItems.length],
    domain,
    currentQuestionIdx,
  };
  return <PageQuestionnaireView {...viewProps} />;
};

// export default withPersonName(withPersonDOB(withAssessment(PageQuestionnaire)));
// export default withInquiry(PageQuestionnaire);
export default PageQuestionnaire;
