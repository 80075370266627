import React from 'react';
import { ButtonKind } from '../Button';
import { ButtonRoute } from '../ButtonRoute';
import { ConcernColorMap } from '../../theme/_';
import { Box } from '@chakra-ui/react';

export interface IButtonSymptomView {
  text: string;
  concernId: number;
  to: string;
}
const iconHW = '10px';
/**
 * @constructor ButtonSymptomView
 * @description ButtonSymptom View component. Renders component with props passed from ButtonSymptom
 * @param text
 * @param concernId
 * @param to
 * @returns {JSX.Element}
 */
export const ButtonSymptomView: React.FC<IButtonSymptomView> = ({
  text,
  concernId,
  to,
}) => {
  const buttonProps = {
    pl: '45px',
    variant: 'tertiary',
    h: '40px',
    kind: ButtonKind.UTILITY,
    to,
    position: 'relative' as 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
  };

  const iconProps = {
    h: iconHW,
    w: iconHW,
    borderRadius: '100000px',
    bgColor: ConcernColorMap.get(concernId),
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '15px',
  };

  return (
    <ButtonRoute {...buttonProps}>
      <Box {...iconProps} />
      {text}
    </ButtonRoute>
  );
};

export default ButtonSymptomView;
