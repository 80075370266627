import Page from '../Page';
import { Copy, H4 } from '../../components/Prose';
import { IPageView } from '../Page/PageView';
import { headerSectionProps, pageBodyProps, pageHeaderProps } from './props';

import Courses from '../../components/Courses';
import PageBody from '../../components/PageBody';
import PageSection from '../../components/PageSection';
import ResultsSubFooter from '../../components/ResultsSubFooter';

export interface IPageResultsView extends IPageView {
  firstName: string;
}
const PageResultsView: React.FC<IPageResultsView> = ({
  loadedConditions,
  firstName,
}) => {
  const pageProps = {
    loadedConditions,
    pageTitle: 'Results',
  };

  return (
    <Page {...pageProps}>
      <PageBody {...pageBodyProps}>
        <PageSection {...headerSectionProps}>
          <H4 {...pageHeaderProps}>Results for {firstName}</H4>
          <Copy>
            The graph below is based on your screening assessment. See whether
            your results fall into the typical range, at-risk range, or if there
            are significant or very significant concerns.
          </Copy>
        </PageSection>
        <PageSection>Results go here</PageSection>
        <PageSection {...headerSectionProps}>
          <H4 {...pageHeaderProps}>Recommended Courses</H4>
          <Copy>
            Listed below are course recommendations based on your test results
            and your child’s unique psychological profile. You can complete
            these quick video mini-lessons and additional assessments in less
            than 2 minutes a day.
          </Copy>
        </PageSection>
        <PageSection>
          <Courses />
        </PageSection>
      </PageBody>
      <ResultsSubFooter />
    </Page>
  );
};

export default PageResultsView;
