import { Slice } from '../../../../global/constants';
import { TAssessment } from './types';
import { useSlices } from '../../../../hooks/useSlices';
import { allTrue } from '../../../../global/helpers';

type TUseAssessment = (assessmentId?: number) => TAssessment | undefined;

/**
 * @function useAssessment
 * @description Resolves an `assessmentId` to an Assessment.  If no `assessmentId` is provided, it will return current Assessment
 */
export const useAssessment: TUseAssessment = assessmentId => {
  const [
    {
      isInit: isAssessmentsInit,
      isReady: isAssessmentsReady,
      currentAssessmentId,
      assessments,
    },
  ] = useSlices(Slice.ASSESSMENTS);

  const thisAssessmentId = assessmentId || currentAssessmentId;
  if (allTrue(isAssessmentsInit, isAssessmentsReady, !!thisAssessmentId)) {
    return (assessments as TAssessment[]).find(
      ({ id }) => id === thisAssessmentId
    );
  }
};
