import api from '../../../../services/api';
import { Slice } from '../../../../global/constants';
import { TScore } from '../sliceResults';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { maybeGetResponseData } from '../../../../services/api/core/maybeGetResponseData';
import { TCourse } from '../../sliceAssessments';

type TResultsApiOutput = {
  domainConcerns: TScore[];
  courses: TCourse[];
};

type TGetResults_Output = { scores: TScore[]; courses: TCourse[] };
type TGetResults_Input = { assessmentId: number };
export const getResults = createAsyncThunk<
  TGetResults_Output,
  TGetResults_Input
>(`${Slice.RESULTS}/get`, async ({ assessmentId }) => {
  const resultsResponse = await api.get(`/assessment/${assessmentId}/results`);

  const { domainConcerns: scores, courses }: TResultsApiOutput =
    maybeGetResponseData(resultsResponse) || {};

  return {
    scores,
    courses,
  };
});
