import React, { useCallback } from 'react';
import {
  createAssessmentAPIInteractions,
  TGenericActionCreator,
} from '../../../../store';
import { THelpText } from '../../../../store/slices';
import QuestionView, { IQuestionView } from './QuestionView';
import { useSlices } from '../../../../hooks/useSlices';
import { Slice } from '../../../../global/constants';
import { performScrollTo } from '../../../../global/helpers';
import { useDispatch } from 'react-redux';
import { TClickEvent } from '../../../../global/types';

export interface IQuestion {
  acSendRetort?: TGenericActionCreator;
  assessmentItemId: number;
  itemDefId: number;
  domainId: number;
  domainName: string;
  helpTexts: THelpText[];
  questionCount: number;
  questionNumber: number;
  responseId: number;
  text: string;
}

/**
 * @constructor Question
 * @description Renders a Question associated with an Assessment Item
 * @param assessmentItemId - Assessment Item ID
 * @param itemDefId - Assessment Item Definition ID
 * @param domainId - Assessment Domain ID
 * @param domainName - Assessment Domain Name
 * @param helpTexts - Array of Help Texts associated with each potential answer to the question
 * @param questionCount - Total number of questions in the domain
 * @param questionNumber - Question number.  Indexed from 1.
 * @param responseId - Assessment Item Response ID.  This is the ID of the response that was selected by the user.  Corresponts to a Concern Level in the database.  See ConcernLevel enum (/global/constants.ts)
 * @param text - Question text
 * @constructor
 */
export const Question: React.FC<IQuestion> = ({
  assessmentItemId,
  itemDefId,
  domainId,
  domainName,
  helpTexts,
  questionCount,
  questionNumber,
  responseId,
  text,
}) => {
  const dispatch = useDispatch();

  const [{ isBusy: isQuestionnaireBusy }, { currentAssessmentId }] = useSlices(
    Slice.QUESTIONNAIRE,
    Slice.ASSESSMENTS
  );
  const { createAssessmentDomainInteractions } =
    createAssessmentAPIInteractions(currentAssessmentId);
  const { createAssessmentItemInteractions } =
    createAssessmentDomainInteractions(domainId);

  const { acSendRetort } = createAssessmentItemInteractions(
    assessmentItemId,
    itemDefId
  );

  const createConcernClickHandler = useCallback<
    (concernLevel: number) => TClickEvent
  >(
    concernLevel => e => {
      e.preventDefault();
      e.stopPropagation();

      if (!isQuestionnaireBusy) {
        (async () => {
          await dispatch(acSendRetort(concernLevel));
          performScrollTo(0, 'smooth');
        })();
      }
    },
    [assessmentItemId, itemDefId, isQuestionnaireBusy]
  );

  const viewProps: IQuestionView = {
    createConcernClickHandler,
    domainName,
    helpTexts,
    questionCount,
    questionNumber,
    responseId,
    text,
  };

  return <QuestionView {...viewProps} />;
};
export default Question;
