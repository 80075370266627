export const createImageProps = (src?: string) => ({
  isHidden: true,
  w: 3,
  h: 2,
  src,
});
export const wrapProps = {
  display: 'flex',
  h: '126px',
};

/**
 * @function createImageCellProps
 * @description This function is used to create the image-cell UI props
 * @param backgroundUrl
 * @returns {TGenericObject}
 */
export const createImageCellProps = (backgroundUrl: string) => ({
  background: `url(${backgroundUrl}) center center no-repeat`,
  backgroundSize: 'cover',
  flex: '0 1 190px',
});
export const contentCellProps = {
  flex: 1,
  minW: '230px',
};
export const contentContainerProps = {
  flexDirection: 'column',
  alignItems: 'center',
};
const commonContentProps = {
  mb: '10px',
};
export const headingProps = {
  ...commonContentProps,
  noOfLines: 2,
};
export const copyProps = {
  ...commonContentProps,
};
