import PageQuestionnaireInquiryView, {
  IPageQuestionnaireInquiryView,
} from './PageQuestionnaireInquiryView';
import { useCallback, useMemo, useState } from 'react';
import produce from 'immer';
import { someTrue } from '../../global/helpers';
import { useDispatch } from 'react-redux';
import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import { createAccountAPIInteractions } from '../../store';
import { useQuestionnaireRoute } from '../../quests/useQuestionnaireRoute';
import { Quest } from '../../quests/core/data';
import { useRedirect } from '../../hooks/useRedirect';

const checklistValues = [
  'Talk to my school about the results',
  'Discuss results with my spouse',
  'Discuss results with a licensed psychologist',
  'Learn more about my child’s symptoms',
  'Take an online course',
  'Nothing, just curious',
];

const PageQuestionnaireInquiry = () => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const [{ id: accountId }, { currentDomainId: currentAssessmentDomainId }] =
    useSlices(Slice.ACCOUNT, Slice.ASSESSMENTS);
  const { acPostAdditionalData } = createAccountAPIInteractions(accountId);
  const [checklistState, setChecklistState] = useState(
    Array(checklistValues.length).fill(false)
  );
  const nextRoute = useQuestionnaireRoute(
    currentAssessmentDomainId,
    Quest.Q_INQUIRY
  );
  const handleCheckChange = useCallback(
    (i: number) => () => {
      setChecklistState(
        produce(checklistState, newChecklistState => {
          newChecklistState[i] = !checklistState[i];
        })
      );
    },
    [checklistState]
  );

  const isButtonEnabled = someTrue(...checklistState);
  const loadedConditions: boolean[] = [];

  const checklistData = useMemo(
    () =>
      checklistValues.map((text, i) => ({
        text,
        isChecked: checklistState[i],
      })),
    [checklistState]
  );

  const handleSubmit = () => {
    const additionalData = checklistData
      .filter(({ isChecked }) => isChecked)
      .map(({ text }) => text)
      .join('|');

    (async () => {
      await dispatch(acPostAdditionalData(additionalData));
      redirect(nextRoute);
    })();
  };

  const viewProps: IPageQuestionnaireInquiryView = {
    checklistData,
    loadedConditions,
    handleCheckChange,
    handleSubmit,
    isButtonEnabled,
  };
  return <PageQuestionnaireInquiryView {...viewProps} />;
};

export default PageQuestionnaireInquiry;
