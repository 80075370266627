import { useLayoutEffect, useState, useCallback, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { TNullable } from '../../../global/types';

export interface IResizeObserverEntry {
  target: HTMLElement;
  contentRect: DOMRectReadOnly;
}

/**
 * @function useResizeObserver
 * @description A hook that returns the dimensions of an element on browser resize.
 * @param ref
 * @param callback
 */
export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: DOMRectReadOnly) => void
) => {
  const [dimensions, setDimensions] = useState<number[]>([0, 0]);
  const handleResize = useCallback(
    (entries: IResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const { contentRect } = entries[0];
      setDimensions([contentRect.width, contentRect.height]);

      if (callback) {
        callback(contentRect);
      }
    },
    [callback]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO: TNullable<ResizeObserver> = new ResizeObserver((entries: any[]) => {
      handleResize(entries);
    });
    RO.observe(ref.current);

    return () => {
      RO!.disconnect();
      RO = null;
    };
  }, [ref]);

  return dimensions;
};
