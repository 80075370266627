import { pageColor } from './colors';

export const styles: { [key: string]: any } = {
  global: {
    body: {
      bgColor: pageColor,
      color: 'brown.900',
    },
  },
};
