export const containerProps = {
  pt: '6px',
  transition: 'background-color 500ms',
};

export const auxHeaderProps = {
  fontWeight: 'bold',
  mb: '9px',
};

export const headingAreaProps = { mb: '1.5em' };

export const introCopyProps = {
  mb: `28px`,
};
