const transitionTime = '500ms';
export const containerProps = ({ isActive }: {isActive: boolean}) => ({
  className: 'Loader',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  bottom: 0,
  right: 0,
  left: 0,
  opacity: isActive ? 1 : 0,
  pointerEvents: isActive ? 'all' : 'none' as 'all',
  position: 'fixed' as 'fixed',
  top: 0,
  transition: `opacity ${transitionTime}`,
  zIndex: isActive ? 100000 : -1,
});

export const spinnerProps = {
  label: '',
};
