import { createGutterProps } from '../../../theme/helpers';
import { TGenericObject } from '../../../global/types';

const [gListWrap, gListItem] = createGutterProps(20);
const breakpoint = 'lg';
export const listProps = {
  ...gListWrap,
  display: 'flex',
  flexWrap: 'wrap' as 'wrap',
  m: 'auto',
  maxW: {
    base: '560px',
    [breakpoint]: '100%',
  },
};
/**
 * @function createListItemProps
 * @description This function is used to create the list item props
 * @param courseId
 * @returns {TGenericObject}
 */
export const createListItemProps = (courseId: number): TGenericObject => ({
  key: `course-id-${courseId}`,
  ...gListItem,
  flex: {
    base: '0 0 100%',
    [breakpoint]: '0 0 50%',
  },
});
