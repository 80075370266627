/**
 * @function createUIEssayStyles
 * @description Creates the styles for the Button's Essay component.
 * @param isActive
 * @returns {Object}
 */
export const createUIEssayStyles = (isActive: boolean) => ({
  variant: 'card',
  borderWidth: '1px',
  position: 'relative',
  borderColor: isActive ? 'green.800' : 'transparent',
});
/**
 * @function createUIButtonStyles
 * @description Creates the styles for the Button's Button component.
 * @param isHover
 * @param isDepressed
 * @returns {Object}
 */
export const createUIButtonStyles = (
  isHover: boolean,
  isDepressed: boolean
) => ({
  backgroundColor: isDepressed ? 'green.800' : isHover ? 'brown.400' : 'white',
  color: isDepressed ? 'white' : 'brown.800',
  p: '20px',
  variant: 'unstyled',
  overflow: 'hidden',
});

/**
 * @function createUIHeadingStyles
 * @description Creates the styles for the Button's Heading component.
 * @param isDepressed
 * @returns {Object}
 */
export const createUIHeadingStyles = (isDepressed: boolean) => ({
  color: isDepressed ? 'white' : 'green.800',
});
