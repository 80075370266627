import PageDomains from '../../pages/PageDomains';
import PageQuestionnaire from '../../pages/PageQuestionnaire';
import PageRequestAge from '../../pages/PageRequestAge';
import PageRequestEmail from '../../pages/PageRequestEmail';
import PageRequestName from '../../pages/PageRequestName';
import PageRequestPassword from '../../pages/PageRequestPassword';
import PageResults from '../../pages/PageResults';
import { Quest } from '../../quests/core/data';
import { Route, Switch } from 'react-router-dom';
import {
  getDomainsPath,
  getSideQuestPath,
  getQuestionnairePath,
  getResultsPath,
  getResultPath,
  getSymptomDetailPath,
  getAuthPath,
} from '../../global/helpers';
import PageResult from '../../pages/PageResult';
import PageSymptomDetail from '../../pages/PageSymptomDetail';
import PageQuestionnaireInquiry from '../../pages/PageQuestionnaireInquiry';

const domainRouteParam = ':domainSlug';
/**
 * @constructor AppAuthenticatedView
 * @description Authenticated Application view component.  Serves as router for authenticated routes.
 * @returns {JSX.Element}
 */
const AppAuthenticatedView = () => {
  return (
    <Switch>
      <Route
        path={getQuestionnairePath(domainRouteParam)}
        render={() => <PageQuestionnaire />}
        exact
      />
      <Route
        path={getSideQuestPath(Quest.Q_INQUIRY)}
        render={() => <PageQuestionnaireInquiry />}
        exact
      />
      <Route
        path={getSideQuestPath(Quest.NAME)}
        render={() => <PageRequestName />}
        exact
      />
      <Route
        path={getSideQuestPath(Quest.AGE)}
        render={() => <PageRequestAge />}
        exact
      />
      <Route
        path={getSideQuestPath(Quest.EMAIL)}
        render={() => <PageRequestEmail />}
        exact
      />
      <Route
        path={getSideQuestPath(Quest.PASSWORD)}
        render={() => <PageRequestPassword />}
        exact
      />
      <Route
        path={getResultPath(domainRouteParam)}
        render={() => <PageResult />}
        exact
      />
      <Route
        path={getSymptomDetailPath(':symptomSlug/:concernLevel')}
        render={() => <PageSymptomDetail />}
      />
      <Route
        path={getSymptomDetailPath(':symptomSlug')}
        render={() => <PageSymptomDetail />}
      />
      <Route path={getResultsPath()} render={() => <PageResults />} exact />
      <Route path={getDomainsPath()} render={() => <PageDomains />} exact />
    </Switch>
  );
};

export default AppAuthenticatedView;
