import { navHeight } from '../../global/constants';
import { TGenericObject } from '../../global/types';

export const pageGutter = 20;
export const navProps = {
  h: `${navHeight}px`,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 10000,
};
export const createPageProps = (rest: TGenericObject) => ({
  display: 'flex' as 'flex',
  flex: 1,
  flexDirection: 'column' as 'column',
  m: 0,
  overflow: 'hidden',
  ...rest,
});

export const uiPageInterior = {
  width: '100%',
  m: 'auto',
};
