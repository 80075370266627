import { Slice } from '../../../../../global/constants';
import { allTrue } from '../../../../../global/helpers';
import { createAccountAPIInteractions } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSlices } from '../../../../../hooks/useSlices';

type HookSig = (isPersonsInit: boolean) => void;
/**
 * useSubscribePersonCreate
 * @description Subscription for creating a `Person` in the Account
 * @param isPersonsInit
 * @returns void
 */
export const useSubscribePersonCreate: HookSig = isPersonsInit => {
  const dispatch = useDispatch();

  const [{ id: accountId }, { tempData: tempPersonData, currentPersonId }] =
    useSlices(Slice.ACCOUNT, Slice.PERSONS);

  const { acCreatePerson } = createAccountAPIInteractions(accountId);

  const { firstName: tempFirstName, age: tempAge } = tempPersonData;

  useEffect(() => {
    // Boolean conditions for creating a `Person`.
    const effectConditions = [
      isPersonsInit,
      !currentPersonId, // a current person does not exist

      !!tempFirstName, // a candidate First Name exists
      !!tempAge, // a candidate Birthdate exists
    ];

    if (allTrue(...effectConditions)) {
      dispatch(acCreatePerson(tempFirstName, tempAge));
    }
  }, [isPersonsInit, currentPersonId, tempFirstName, tempAge]);
};
