import Page from '../Page';
import PageBody from '../../components/PageBody';
import { IPageView } from '../Page/PageView';
import Card from '../../components/Card';
import { Box, Checkbox, CheckboxProps, List, ListItem } from '@chakra-ui/react';
import { Copy, H4 } from '../../components/Prose';
import PageSection from '../../components/PageSection';
import { Button } from '../../components/Button';
import { createGutterProps } from '../../theme/helpers';

const outerCardProps = {
  rounded: 8,
  p: '10px',
};
const innerCardProps = {
  bgColor: 'tea.200',
  boxShadow: 'none',
};

const contentAreaProps = {
  textAlign: 'center' as 'center',
};

export interface IPageQuestionnaireInquiryView extends IPageView {
  checklistData: { text: string; isChecked: boolean }[];
  isButtonEnabled: boolean;
  handleCheckChange: (i: number) => () => void;
  handleSubmit: () => void;
}


const checkboxHW = '24px'
const [gWrap, gItem] = createGutterProps(25);

const checkboxEl = '.chakra-checkbox__control'
const checkboxUI = {
  sx: {
    [checkboxEl]: {
      boxShadow: 'none',
      rounded: 1000,
      height: checkboxHW,
      width: checkboxHW,
      borderWidth: '1px',
      borderColor: 'brown.600'
    },
    [`${checkboxEl}[data-checked]`]: {
      bgColor: 'blue.800',
      borderColor: 'blue.800'
    }
  }

}


const PageQuestionnaireInquiryView: React.FC<IPageQuestionnaireInquiryView> = ({
  checklistData,
  handleCheckChange,
  handleSubmit,
  isButtonEnabled,
  loadedConditions,
}) => {
  const pageProps = {
    loadedConditions,
  };

  const submitButtonProps = {
    isEnabled: isButtonEnabled,
    onClick: handleSubmit,
    variant: 'primary',
  };

  return (
    <Page {...pageProps}>
      <PageBody>
        <Card {...outerCardProps}>
          <Card {...innerCardProps}>
            <Box {...contentAreaProps}>
              <H4>
                Great job so far! A quick question before we continue&hellip;
              </H4>
              <Copy>
                What will you do with the results from the previous assessment?
                Select all that apply.
              </Copy>
            </Box>
            <PageSection>
              <List {...gWrap}>
              {checklistData.map(({ text, isChecked }, i) => {
                const checkboxProps: CheckboxProps = {
                  defaultChecked: isChecked,
                  onChange: handleCheckChange(i),
                  children: text,
                  ...checkboxUI
                };

                return (
                  <ListItem {...gItem}>
                    <Checkbox {...checkboxProps} />
                  </ListItem>
                );
              })}
              </List>
            </PageSection>
            <PageSection>
              <Button {...submitButtonProps}>Continue to Assessment</Button>
            </PageSection>
          </Card>
        </Card>
      </PageBody>
    </Page>
  );
};

export default PageQuestionnaireInquiryView;
