import { getTypography } from '../../../theme/_';
import InputMask from 'react-input-mask';
import { TGenericObject, TNullable } from '../../../global/types';
const errorColor = 'red.800';

export const getLabelProps = (forInput: string) => ({
  ...getTypography('caption'),
  fontWeight: 'bold',
  htmlFor: forInput,
});

export const getSubmitProps = (isSubmitting: boolean) => ({
  variant: 'primary',
  type: 'submit',
  mt: '50px',
  isLoading: isSubmitting,
});
const uiCommon = {
  borderRadius: 0,
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
};

export const errorMsgProps = {
  color: errorColor,
};

type TInputOptions = {
  type: string;
  placeholder: string;
  mask: string;
  maskChar: TNullable<string>;
  maskPlaceholder: TNullable<string>;
};
type TGetInputProps = (
  inputName: string,
  inputOptions: TInputOptions
) => TGenericObject;

export const getInputProps: TGetInputProps = (
  inputName,
  {
    type = 'text',
    placeholder = '',
    mask,
    maskChar = null,
    maskPlaceholder = null,
  }
) => ({
  ...uiCommon,
  _invalid: {
    borderColor: errorColor,
    ...uiCommon,
  },
  _placeholder: {
    color: 'brown.500',
  },
  ...getTypography('h2'),
  autocomplete: 'off',
  borderColor: 'brown.400',
  color: 'blue.800',
  px: 0,
  id: inputName,
  name: inputName,
  type,
  placeholder,
  ...(!!mask && {
    as: InputMask,
    mask,
    maskChar,
    maskPlaceholder,
  }),
});

export const headingProps = { mb: '35px' };
