import { TGenericObject } from '../../../global/types';

const transitionTimeInMs = 1000;
export const uiListProps = {
  display: 'grid',
  gridTemplateColumns: '1fr',
};

type TPlacement = {
  boxShadow?: string;
  transform?: string;
  pointerEvents: string;
};
const placementProps: { [key: string]: TPlacement } = {
  aside: {
    boxShadow: 'md',
    transform: 'translateX(-110%)',
    pointerEvents: 'none',
  },
  visible: {
    boxShadow: 'none',
    transform: 'translate(0)',
    pointerEvents: 'all',
  },
  obscured: {
    pointerEvents: 'none',
    // opacity: 0
  },
};

type TUIListItemProps = ({
  itemIndex,
  placement,
  itemCount,
}: {
  itemIndex: number;
  placement: string;
  itemCount: number;
}) => TGenericObject;

export const createUIListItemProps: TUIListItemProps = ({
  itemIndex,
  placement,
  itemCount,
}) => ({
  ...placementProps[placement],
  backgroundColor: 'sand.400',
  gridColumnStart: 1,
  gridRowStart: 1,
  transition: `transform ${transitionTimeInMs}ms`,
  zIndex: itemCount - itemIndex,
  p: '20px',
});

type Sig_CreateGetPlacement = (itemIdx: number, questionIdx: number) => string;
const getQuestionnaireItemPlacement: Sig_CreateGetPlacement = (
  itemIdx,
  questionIdx
) =>
  itemIdx < questionIdx
    ? 'aside'
    : itemIdx > questionIdx
    ? 'obscured'
    : 'visible';
export const wrapProps = {
  overflow: 'hidden',
};
export const createListProps = (outsideProps: TGenericObject) => ({
  ...uiListProps,
  ...outsideProps,
});
export const createListItemProps = (
  assessmentItemId: number,
  itemIndex: number,
  numberOfItems: number,
  currentQuestionIdx: number
) => ({
  key: `assessment-item-${assessmentItemId}`,
  ...createUIListItemProps({
    itemIndex,
    itemCount: numberOfItems,
    placement: getQuestionnaireItemPlacement(itemIndex, currentQuestionIdx),
  }),
});
