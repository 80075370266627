import React from 'react';
import { Box, List, ListItem } from '@chakra-ui/react';
import Course from '../_/Course';
import { createListItemProps, listProps } from './props';
import { TCourse } from '../../../store/slices';

export interface ICoursesView {
  courses: TCourse[];
}

export const testId = 'courses';
/**
 * @constructor CoursesView
 * @description Renders a list of Cadey courses with props passed from Courses component
 * @param courses
 * @param rest
 * @constructor
 */
export const CoursesView: React.FC<ICoursesView> = ({ courses, ...rest }) => {
  return (
    <Box {...rest}>
      <List {...listProps}>
        {courses.map(course => (
          <ListItem {...createListItemProps(course.courseId)}>
            <Course {...course} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CoursesView;
