import D3Chart, { ID3Chart } from '../D3Chart';
import { gaugeFunc } from './gaugeFunc';

interface IGaugeChart {
  score: number;
  diameter?: number;
  concernLevel: number;
}

/**
 * @constructor GaugeChart
 * @description Renders a pure Gauge Chart, without any labels or other information
 * @param diameter  - Diameter of the Gauge Chart
 * @param concernLevel - Concern Level of the Gauge Chart
 * @param score - Score of the Gauge Chart
 * @param rest - Props to be passed to the wrapping D3Chart
 * @returns {JSX.Element}
 */
const GaugeChart: React.FC<IGaugeChart> = ({
  diameter = 100,
  concernLevel,
  score,
  ...rest
}) => {
  const gaugeProps: ID3Chart = {
    data: [{ score, concernLevel }],
    func: gaugeFunc,
    h: diameter,
    w: diameter,
    ...rest,
  };
  return <D3Chart {...gaugeProps} />;
};

export default GaugeChart;
