import { cardDefaults } from '../Card/props';

export const cardProps = {
  ...cardDefaults,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  variant: 'card',
  w: '100%',
};
/**
 * @function createDomainAvatarProps
 * @description Create the UI props for the domain avatar
 * @param domainId
 * @returns {object}
 */
export const createDomainAvatarProps = (domainId: number) => ({
  domainId,
  bgColor: 'sand',
  bgValue: 500,
});

/**
 * @function createDomainNameProps
 * @description Create the UI props for the domain Completion state
 * @param domainDefId
 * @returns {object}
 */
export const createCompleteProps = (domainDefId: number) => ({
  flex: 0,
  domainDefId,
  bgColor: 'sky.900',
  color: 'white',
  textAlign: 'center' as 'center',
  py: '1.125em',
});
