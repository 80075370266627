import { useAssessment } from './useAssessment';
import { LookupMap } from '../../../../global/LookupMap';
import { TAssessmentDomain } from './types';
import { TNullable } from '../../../../global/types';


export const useAssessmentDomain = (idOrSlug: number | string) => {
  const currentAssessment = useAssessment()

  const assessmentDomains = currentAssessment?.assessmentDomains;
  if(!assessmentDomains) return null;

  const AssessmentDomainDict = (assessmentDomains as TAssessmentDomain[]).reduce((acc, domain)=>({
    ...acc,
    [domain.domainDefId]: domain,
    [domain.slug]: domain,
  }), {});

  const AssessmentDomainMap = new LookupMap<TNullable<TAssessmentDomain>>(AssessmentDomainDict, null)

  return AssessmentDomainMap.get(idOrSlug);
}