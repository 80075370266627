import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createAccountAPIInteractions } from '../../../../../store';
import { allTrue } from '../../../../../global/helpers';
import { Slice } from '../../../../../global/constants';
import { useSlices } from '../../../../../hooks/useSlices';

type HookSig = (
  isAccountInit: boolean,
  isPersonsInit: boolean,
  isPersonsReady: boolean
) => boolean;

/**
 * useInitAssessments
 * @description This hook is responsible for initializing the Assessments slice of the store.
 * @param isAccountInit
 * @param isPersonsInit
 * @param isPersonsReady
 * @returns isAssessmentsInit
 */
export const useInitAssessments: HookSig = (
  isAccountInit,
  isPersonsInit,
  isPersonsReady
) => {
  const dispatch = useDispatch();
  const [
    { id: accountId },
    { currentPersonId },
    { isInit: isAssessmentsInit },
  ] = useSlices(Slice.ACCOUNT, Slice.PERSONS, Slice.ASSESSMENTS);

  const { createPersonInteractions } = createAccountAPIInteractions(accountId);
  const { acInitAssessments } = createPersonInteractions(currentPersonId);

  useEffect(() => {
    // Session and Account and Person are initialized, but no Assessments are yet populated
    const effectConditions = [
      isAccountInit,
      isPersonsInit,
      !isAssessmentsInit,
      !!accountId,
    ];

    if (allTrue(...effectConditions)) {
      dispatch(acInitAssessments(isPersonsReady));
    }
  }, [
    isAccountInit,
    isPersonsInit,
    isPersonsReady,
    isAssessmentsInit,
    accountId,
    currentPersonId,
  ]);

  return isAssessmentsInit;
};
