import { TGenericObject, TStrategyMetaItem } from './types';
import { LookupMap } from './LookupMap';

//region Values
export const nullChar = '~';
export const nullEffect = () => {};
export const nullUserName = 'CADE_TEMP';
export const maxPageInteriorWidth = 850;

export const navHeight = 50;
export const footerHeight = 56;
export const welcomeVideoUrl = 'https://vimeo.com/780772078/47f132fc03';
//endregion

/**
 * @enum Slice
 * @description Redux Slice names.  Used to keep Slice names and sub-namespaces centralized.
 */
export enum Slice {
  ACCOUNT = 'ACCOUNT',
  ASSESSMENTS = 'ASSESSMENTS',
  CORE = 'CORE',
  DOMAINS = 'DOMAINS',
  PERSONS = 'PERSONS',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  RESULTS = 'RESULTS',
  SESSION = 'SESSION',
  SYMPTOMS = 'SYMPTOMS',
}

export enum UserStatus {
  TRIAL,
  PERMANENT,
}

export enum UserActivity {
  VISIT_MY_PLAN = 'VisitMyPlan',
  CLICK_MAIN_MENU = 'ClickMainMenu',
}

// Incluseive Left bound, Exclusive Right bound
export enum AgeGroup {
  BETWEEN_0_AND_5 = 1,
  BETWEEN_5_AND_13 = 2,
  BETWEEN_13_AND_18 = 3,
  BETWEEN_18_AND_INFINITY = 4,
}
export enum ConcernLevel {
  NO_SCORE,
  NO_CONCERN,
  MILD,
  MODERATE,
  SIGNIFICANT,
  UNKNOWN,
  NO_RESPONSE,
}
export enum CadeDomainId {
  UNDERSTANDING = 1,
  LEARNING,
  COMMUNICATING,
  SOCIALIZING,
  DAILY_LIVING,
  MOVING_SENSING,
  FOCUSING,
  REMEMBERING,
  ORGANIZING,
  FEELING,
  BEHAVING,
  INATTENTION_IMPULSIVITY,
  STRESS_WORRY,
  SADNESS_ANGER,
  LEARNING_ACADEMIC,
  SOCIAL_COMMUNICATION,
  DAILY_LIFE,
}

//endregion

//region Objects
export const OverallDomain: TGenericObject = {
  id: -1,
  slug: 'overall',
  text: 'Summary',
};
export const StrategyMeta: TStrategyMetaItem[] = [
  { strategyType: 'home', tagText: 'At Home', displayOrder: 0 },
  { strategyType: 'book', tagText: 'Book', displayOrder: 10 },
  { strategyType: 'school', tagText: 'At School', displayOrder: 20 },
];
//endregion

export const ConcernTextMap = new LookupMap<string>(
  {
    [ConcernLevel.NO_CONCERN]: 'No Concern',
    [ConcernLevel.MILD]: 'Moderate',
    [ConcernLevel.MODERATE]: 'Significant',
    [ConcernLevel.SIGNIFICANT]: 'Very Significant',
    [ConcernLevel.NO_SCORE]: 'No Score',
    [ConcernLevel.NO_RESPONSE]: 'No Response',
    [ConcernLevel.UNKNOWN]: 'Unknown',
  },
  'Unknown'
);
