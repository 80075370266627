import Card from '../Card';
import Gauge from '../Gauge';
import React from 'react';
import { Button } from '../Button';
import { GaugeViewName, IGauge } from '../Gauge/Gauge';
import { H5 } from '../Prose';
import { Box } from '@chakra-ui/react';
import { TGenericObject } from '../../global/types';

export interface ICardDashboardView {
  buttonProps: TGenericObject;
  concernLevel: number;
  domainName: string;
  domainDefId: number;
  score: number;
}

  const cardProps = {
    p: '15px',
  };
  const containerProps = {
    alignItems: 'center' as 'center',
    display: 'flex',
    flexDir: 'column' as 'column',
    pb: '6px',
    textAlign: 'center' as 'center',
  };
  const headingWrapProps = {
    alignItems: 'center',
    display: 'flex',
    height: '46px',
    mb: '15px',
  };
  const headingProps = {
    noOfLines: 2,
  };
/**
 * @constructor CardDashboardView
 * @description Renders a Card with a Gauge and Button with props passed from Shell
 * @param concernLevel - Concern Level of the Domain
 * @param domainName - Name of the Domain
 * @param domainDefId - ID of the Domain
 * @param score - Score of the Domain
 * @param buttonProps - Props for the Card's Button
 * @returns {JSX.Element}
 */
export const CardDashboardView: React.FC<ICardDashboardView> = ({
  concernLevel,
  domainName,
  domainDefId,
  score,
  buttonProps,
}) => {

  const gaugeProps: IGauge = {
    view: GaugeViewName.SMALL,
    domainDefId,
    concernLevel,
    score,
    // @ts-ignore
    mb: '12px',
  };

  return (
    <Card {...cardProps}>
      <Box {...containerProps}>
        <Box {...headingWrapProps}>
          <H5 {...headingProps}>{domainName}</H5>
        </Box>
        <Gauge {...gaugeProps} />
        <Button {...buttonProps} />
      </Box>
    </Card>
  );
};
export default CardDashboardView;
