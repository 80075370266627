import AppAuthenticatedView from './AppAuthenticatedView';
import { useEffect } from 'react';
import useAppAuthInit from './hooks/useAppAuthInit';
import { acSetIsAuthZone } from '../../store';
import { authenticatorTheme } from '../../theme/amplify';
import { useDispatch } from 'react-redux';
import { withAuthenticator } from 'aws-amplify-react';

/**
 * @constructor AppAuthenticated
 * @description Authenticated Application shell component.  Serves as router for authenticated routes. Interacts with Store, and then displays View
 * @returns {JSX.Element}
 */
export const AppAuthenticated = () => {
  const dispatch = useDispatch();

  useAppAuthInit();
  useEffect(() => {
    dispatch(acSetIsAuthZone(true));
  }, []);

  return <AppAuthenticatedView />;
};

// TODO: Reapply `withPaywall` HOC
export default //withPaywall(
withAuthenticator(AppAuthenticated, {
  usernameAttributes: 'email',
  theme: authenticatorTheme,
} as any);
//);
