import React from 'react';
import { List, ListItem } from '@chakra-ui/react';
import { ButtonSymptom, ISymptomDatum } from '../ButtonSymptom/ButtonSymptom';
import { createGutterProps } from '../../theme/helpers';
import { TGenericObject } from '../../global/types';

export const buttonGutter = 14;
const [gWrap, gItem] = createGutterProps(buttonGutter);
const createListProps = (rest: TGenericObject) => ({
  ...gWrap,
  ...rest,
});
const createListItemProps = (symptomId: number, idx: number) => ({
  key: `${symptomId}-${idx}`,
  ...gItem,
});
const createButtonProps = (symptomId: number, concernId: number) => ({
  symptomId,
  concernId,
});

export interface IButtonSymptomsView {
  data: ISymptomDatum[];
}

/**
 * @constructor ButtonSymptomsView
 * @description Accepts factory props from Shell.  Renders a list of ButtonSymptom components
 * @param data
 * @param rest
 * @constructor
 */
export const ButtonSymptomsView: React.FC<IButtonSymptomsView> = ({
  data,
  ...rest
}) => {
  return (
    <List {...createListProps(rest)}>
      {data.map(({ symptomId, concernId }, i) => (
        <ListItem {...createListItemProps(symptomId, i)}>
          <ButtonSymptom {...createButtonProps(symptomId, concernId)} />
        </ListItem>
      ))}
    </List>
  );
};

export default ButtonSymptomsView;
