import React from 'react';
import { Box } from '@chakra-ui/react';
import { ICore } from '../../global/interfaces';
import { createComponentProps } from '../../global/helpers';

interface IEssay extends ICore {
  variant?: string;
}

export const testId = 'essay';
/**
 * @constructor Essay
 * @description An Essay is an abstracted concept of a short piece of content.  It can have a title, copy, and an image.  It can be styled as a "plain" or "card" variant.  It is a remnant from an early version of the app where different content elements were used.  Essays are really only used to render "Cards" now.
 * @param variant - "plain" or "card"
 * @param dataTestId  - data-testid attribute for Jest
 * @param rest  - additional props passed to the wrapper
 * @returns {JSX.Element}
 */
export const Essay: React.FC<IEssay> = ({
  variant = 'plain',
  dataTestId = testId,
  ...rest
}) => {
  const boxProps = createComponentProps(dataTestId, {
    ...rest,
  });

  return <Box {...boxProps} />;
};
export default Essay;
