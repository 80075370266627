import React, { useState } from 'react';
import { ICore } from '../../global/interfaces';
import { Box, Input } from '@chakra-ui/react';
import Button from '../Button';
import { createComponentProps } from '../../global/helpers';
import { createUIButton, createUIInput, uiWrap } from './core/ui';
import { useInputAutofocus } from '../FormRequestMissingInfo/core/useInputAutofocus';
import { TNullable } from '../../global/types';

// TODO: Convert to Stateful Component Pattern
interface IInputPassword extends ICore {
  refAutoFocus?: TNullable<HTMLInputElement>;
}

export const testId = 'inputPassword';
export const btnToggleTestId = 'btnToggle';
export const pwInputTestId = 'passwordInput';
/**
 * @constructor InputPassword
 * @description This component is used to provide a password input with a toggle button to show/hide the password.
 * @param dataTestId - The data-testid attribute for Jest testing.
 * @param refAutoFocus - A reference to the input element to be auto-focused.
 * @param rest  - Any other props to pass to the component.
 * @returns {JSX.Element}
 */
export const InputPassword: React.FC<IInputPassword> = ({
  dataTestId = testId,
  refAutoFocus = null,
  ...rest
}) => {
  useInputAutofocus(refAutoFocus);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleVisibilityClick = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const wrapProps = createComponentProps(dataTestId, uiWrap);
  const inputProps = createComponentProps(pwInputTestId, {
    ...rest,
    ...(!!refAutoFocus && { ref: refAutoFocus }),
    ...createUIInput(isPasswordVisible),
  });
  const buttonProps = createComponentProps(btnToggleTestId, {
    ...createUIButton(isPasswordVisible),
    onClick: handleVisibilityClick,
  });

  return (
    <Box {...wrapProps}>
      <Input {...inputProps} />
      <Button {...buttonProps} />
    </Box>
  );
};
export default InputPassword;
