import { Box, Image as CImage, Link } from '@chakra-ui/react';
import Button, { ButtonKind } from '../Button';
import Essay from '../Essay';
import Image from '../Image';
import { Copy, H4, H6 } from '../Prose';

export interface ICardMobileAppView {
  image_cadey_parent: string;
}

const cardProps = {
  p: '15px',
  h: 800,
};

export const wrapProps = {
  h: '550px',
  w: '300px',
  ml: 'auto',
  mr: 'auto',
  backgroundColor: '#BED9E9',
  textAlign: 'center' as 'center',
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  borderTopRadius: '10%',
};

const boxProps = {
  pt: '0px',
};

const copyProps = {
  color: '#384F5F',
  pl: '10px',
  pr: '10px',
};

const buttonProps = {
  as: Link,
  href: 'https://cadey.co/app/',
  // href: 'https://play.google.com/store/apps/details?id=co.cadey.liteapp&hl=en_US&gl=US',
  //href: 'https://testflight.apple.com/join/R1JnIFKM',
  kind: ButtonKind.UTILITY,
  target: '_blank',
  variant: 'primary',
  w: '250px',
  mb: '10px',
  mt: '20px',
};

const headerProps = {
  mb: '10px',
};

export const CardMobileAppView: React.FC<ICardMobileAppView> = ({
  image_cadey_parent,
}) => {
  const imageProps = {
    src: image_cadey_parent,
    display: 'block',
    h: 300,
    borderRadius: '10%',
    m: 'auto',
    maxW: 300,
    w: 300,
    mb: '15px',
  };

  return (
    <Box {...wrapProps}>
      <Box {...boxProps}>
        <CImage {...imageProps}></CImage>
        <H4>Want more help?</H4>
        <H4 {...headerProps}>Try our mobile app</H4>
        <Copy {...copyProps}>
          Congrats! You completed an assessment for your child. Now get
          strategies and recommendations that will help today.
        </Copy>
        <Button {...buttonProps}>Start Free Trial</Button>
      </Box>
    </Box>
  );
};
export default CardMobileAppView;
