import { Slice } from '../../../../../global/constants';
import { allTrue } from '../../../../../global/helpers';
import { acGetResults } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSlices } from '../../../../../hooks/useSlices';
import {
  HotjarEvent,
  useTriggerHotjarEvent,
} from '../../../../../contexts/HotjarContext';

type HookSig = (isAssessmentsInit: boolean) => void;
/**
 * useSubscribeResults
 * @description Subscribe to the retrieval of `Results` for the current completed `Assessment`.
 * @param isAssessmentsInit
 * @returns void
 */
export const useSubscribeResults: HookSig = isAssessmentsInit => {
  const dispatch = useDispatch();
  const triggerHotjarEvent = useTriggerHotjarEvent();
  const [{ currentAssessmentId }, { triggerFetchResults }] = useSlices(
    Slice.ASSESSMENTS,
    Slice.RESULTS
  );

  // Init and Subscribe Results
  useEffect(() => {
    const effectConditions = [isAssessmentsInit, !!currentAssessmentId];

    if (allTrue(...effectConditions)) {
      dispatch(acGetResults(currentAssessmentId));
      triggerHotjarEvent(HotjarEvent.RESULTS_RETRIEVED);
    }
  }, [isAssessmentsInit, triggerFetchResults, currentAssessmentId]);
};
