import { Slice } from '../../../../global/constants';
import { useInitSession } from './_/useInitSession';
import { useSlices } from '../../../../hooks/useSlices';

type HookSig = () => void;
/**
 * useAppInit
 * @description This hook is responsible for initializing App dependencies.  It retrieves `isInitComplete` from the Store and passes it to the initializer.
 * @returns {void}
 */
export const useAppInit: HookSig = () => {
  const [{ isInitComplete }] = useSlices(Slice.SESSION);

  // Initialize Amplify
  // Check localStorage for auth tokens.
  // if it finds none, it logs into Cognitio, and places auth tokens in localStorage
  useInitSession(isInitComplete);
};

export default useAppInit;
