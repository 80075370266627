import { TAssessmentDomain } from '../../store/slices';
import CardMobileAppView, { ICardMobileAppView } from './CardMobileAppView';
import image_cadey_parent from './assets/img-cadey-parent.jpg';

interface ICardMobileApp {}

export const CardMobileApp: React.FC<ICardMobileApp> = ({ ...rest }) => {
  const viewProps: ICardMobileAppView = {
    image_cadey_parent,
    ...rest,
  };

  return <CardMobileAppView {...viewProps} />;
};
export default CardMobileApp;
