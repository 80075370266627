import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import Image from '../Image';
import { H2 } from '../Prose';
import {
  createCarProps,
  createImageProps,
  createNavItemProps,
  createNavProps,
  createTrainProps,
  imageWrapProps,
  testimonialTextProps,
  viewportProps,
} from './props';

export type TTestimonialItem = {
  src: string;
  text: string;
};

export interface ITestimonials {
  data: TTestimonialItem[];
  w?: number;
  g?: number;
}

/**
 * @constructor TestimonialsView
 * @description Renders a carousel of testimonials
 * @param data - array of objects containing Testimonial metadata
 * @returns {JSX.Element}
 */
const TestimonialsView: React.FC<ITestimonials> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNavigationClick = (index: number) => () => {
    setActiveIndex(index);
  };

  return (
    <Box {...viewportProps}>
      <Box {...createTrainProps(data, activeIndex)}>
        {data.map(({ src, text }, i) => (
          <Box {...createCarProps(i)}>
            <Box {...imageWrapProps}>
              <Image {...createImageProps(src)} />
            </Box>
            <H2 {...testimonialTextProps}>&ldquo;{text}&rdquo;</H2>
          </Box>
        ))}
      </Box>
      <Box {...createNavProps(data)}>
        {data.map(({}, i) => (
          <Box
            {...createNavItemProps(i, activeIndex, handleNavigationClick(i))}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TestimonialsView;
