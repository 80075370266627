import React from 'react';
import { ICore } from '../../global/interfaces';
import { Icon as CIcon } from '@chakra-ui/react';
import { TIconFactory, IconMenagerie, iconTypeFallback } from './core/library';
import { getThemeColor } from '../../theme/_';
import { createComponentProps } from '../../global/helpers';

interface IIcon extends ICore {
  type?: string;
  size?: number;
  color?: string;
  colorValue?: number;
  containerType?: number;
}

export const testId = 'icon';
/**
 * @constructor Icon
 * @description A wrapper for Chakra's Icon component.  This component is used to render standardized icons throughout the App.
 * @param type - The name of the icon to render.  If no type is provided, the fallback icon will be rendered.
 * @param size - The size of the icon to render.  If no size is provided, the default size will be used.
 * @param color - The color of the icon to render.  If no color is provided, the default color will be used.
 * @param colorValue - The color value of the icon to render.  If no color value is provided, the default color value will be used.
 * @param dataTestId  - The data-testid attribute for Jest.
 * @param containerType - The shape of the sourrounding container.  Can be `square`, `circle`, or none.  Defaults to none.
 * @param rest - Any other props to pass to the component.
 * @returns {JSX.Element}
 */
export const Icon: React.FC<IIcon> = ({
  type = iconTypeFallback,
  size = 32,
  color = null,
  colorValue = 800,
  dataTestId = testId,
  containerType,
  ...rest
}) => {
  const iconType =
    Object.keys(IconMenagerie).find(key => key === type) || iconTypeFallback;
  const createIconBody: TIconFactory = IconMenagerie[iconType];

  const hw = `${size}px`;
  const viewBoxHW = 32;
  const iconProps = createComponentProps(dataTestId, {
    ...rest,
    sx: {
      pointerEvents: 'none',
    },
    'data-type': iconType,
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: `0 0 ${viewBoxHW} ${viewBoxHW}`,
    h: hw,
    w: hw,
  });

  const iconColor = !!color ? getThemeColor(color, colorValue) : 'currentColor';
  return (
    <CIcon {...iconProps}>
      {createIconBody(iconColor, size, containerType)}
    </CIcon>
  );
};
export default Icon;
