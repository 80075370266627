import React from 'react';
import { ICore } from '../../global/interfaces';
import { Icon } from '../Icon';
import { DomainToIconTypeMap } from './props';

interface IIconDomain extends ICore {
  domainId: number;
}

export const testId = 'icon-domain';

/**
 * @constructor IconDomain
 * @description A wrapper for the Icon component.  This component is used render Domain icons.  Specifically, it translates domainIds to icon slugs.
 * @param domainId - The domainId to translate to an icon slug.
 * @param rest - Any other props to pass to the component.
 * @returns {JSX.Element}
 */
export const IconDomain: React.FC<IIconDomain> = ({ domainId, ...rest }) => {
  const iconProps = {
    type: DomainToIconTypeMap.get(domainId),
    ...rest,
  };
  return <Icon {...iconProps} />;
};

export default IconDomain;
