import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createAssessmentAPIInteractions } from '../../../../../store';
import { allTrue } from '../../../../../global/helpers';
import { Slice } from '../../../../../global/constants';
import { useSlices } from '../../../../../hooks/useSlices';

type HookSig = (isAssessmentsInit: boolean) => void;
/**
 * useSubscribeQuestionnaire
 * @description Subscribe to the creation of a `Questionnaire` for the current `Assessment` and `Person`.
 * @param isAssessmentsInit
 * @returns void
 */
export const useSubscribeQuestionnaire: HookSig = isAssessmentsInit => {
  const dispatch = useDispatch();
  const [
    { currentAssessmentId: assessmentId, currentDomainId: assessmentDomainId },
  ] = useSlices(Slice.ASSESSMENTS);

  const { createAssessmentDomainInteractions } =
    createAssessmentAPIInteractions(assessmentId);
  const { acGetQuestionnaire } =
    createAssessmentDomainInteractions(assessmentDomainId);

  useEffect(() => {
    if (allTrue(isAssessmentsInit, !!assessmentId, !!assessmentDomainId)) {
      dispatch(acGetQuestionnaire());
    }
  }, [isAssessmentsInit, assessmentId, assessmentDomainId]);
};
