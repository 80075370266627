import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useInputAutofocus } from './core/useInputAutofocus';
import { useRedirect } from '../../hooks/useRedirect';

import Page from '../../pages/Page';
import PageBody from '../PageBody';
import Button from '../Button';
import Content from '../Content';
import { H1, H3 } from '../Prose';

import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import {
  errorMsgProps,
  getInputOptInProps,
  getInputProps,
  getLabelProps,
  getPageProps,
  getSubmitProps,
  headingProps,
} from './core/props';
import { getDomainsPath } from '../../global/helpers';
import { maxPageInteriorWidth } from '../../global/constants';
import { getTypography } from '../../theme/_';

// TODO: Add jsDoc comments
const FormRequestMissingInfo = ({
  acMissingInfoUpdated,
  infoDetails,
  nextRoute = getDomainsPath(),
}) => {
  // Hooks
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const refMissingInfoInput = useRef(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  useInputAutofocus(refMissingInfoInput);

  // Variables
  const missingInfoInputName = 'missingInfo';
  const optInName = 'value-opt-in';
  const { [missingInfoInputName]: error } = errors; // Hook Form Errors
  const { hfProps: inputHookFormProps = {}, submitText = 'Next Step' } =
    infoDetails;

  // Handlers
  const onSubmit = valuesRaw => {
    return new Promise(resolve => {
      setTimeout(() => {
        const values = Object.values(valuesRaw);
        dispatch(acMissingInfoUpdated(...values));
        redirect(nextRoute);
        resolve();
      }, 1000);
    });
  };

  // Props

  // Build MissingInfo HF Registration separately, for clarity
  const hfReg_MissingInfoProps = register(missingInfoInputName, {
    required: infoDetails.requiredText,
    ...inputHookFormProps,
  });

  // Build MissingInfo Input props and register with Hook Form
  const inputProps = {
    ...getInputProps(missingInfoInputName, infoDetails),
    ...hfReg_MissingInfoProps,
    ...getTypography('h3'),
  };

  // Build Opt-In Input props and register with Hook Form
  const optInProps = {
    ...getInputOptInProps(optInName),
    ...register(optInName),
  };

  return (
    <Page {...getPageProps(infoDetails)}>
      <PageBody>
        <Box mb={'80px'}>
          <H3 {...headingProps}>{infoDetails.headline}</H3>
          {!!infoDetails.desc && <Content>{infoDetails.desc}</Content>}
        </Box>
        <Box maxW={`${maxPageInteriorWidth / 2}px`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={error}>
              <FormLabel {...getLabelProps(missingInfoInputName)}>
                {infoDetails.label}
              </FormLabel>
              <Input {...inputProps} ref={refMissingInfoInput} />
              {!!error && (
                <FormErrorMessage {...errorMsgProps}>
                  {error.message}
                </FormErrorMessage>
              )}
            </FormControl>
            {!!infoDetails.optInText && (
              <FormControl mt={'20px'}>
                <Checkbox {...optInProps}>{infoDetails.optInText}</Checkbox>
              </FormControl>
            )}
            <Button {...getSubmitProps(isSubmitting)}>{submitText}</Button>
          </form>
        </Box>
      </PageBody>
    </Page>
  );
};

export default FormRequestMissingInfo;
