import DomainAssessListView, {
  IDomainAssessListView,
} from './DomainAssessListView';
import { useAssessment } from '../../store/slices/sliceAssessments/core/useAssessment';

interface IDomainList {}

/**
 * @constructor DomainAssessList
 * @description Renders a list of Domain Assessment Cards.  Each Card provides a link to an Assessment for that Domain. Shell component. Generates props and passes them to DomainAssessListView
 * @param rest
 * @constructor
 */
const DomainAssessList: React.FC<IDomainList> = ({ ...rest }) => {
  const currentAssessment = useAssessment();
  if (!currentAssessment) return null;

  const { assessmentDomains: domains = [] } = currentAssessment;

  const viewProps: IDomainAssessListView = {
    domains,
    ...rest,
  };

  return <DomainAssessListView {...viewProps} />;
};

export default DomainAssessList;
