import DomainAssessList from '../../../components/DomainAssessList';
import Page from '../../Page';
import PageBody from '../../../components/PageBody';
import { H4 } from '../../../components/Prose';
import { IPageView } from '../../Page/PageView';
import { makePossessive } from '../../../global/helpers';
import { pageBodyProps } from '../props';
import PageSection from '../../../components/PageSection';

const headingProps = {
  textAlign: 'center',
};
const domainsProps = {
  m: 'auto',
};

export interface IPageAssessDomainsView extends IPageView {
  firstName: string;
}
const DefDomains: React.FC<IPageAssessDomainsView> = ({
  loadedConditions,
  firstName,
}) => {
  const pageProps = {
    loadedConditions,
    pageTitle: 'Free Child Psychology Tests - Cadey',
  };
  return (
    <Page {...pageProps}>
      <PageBody {...pageBodyProps}>
        <PageSection>
          <H4 {...headingProps}>{makePossessive(firstName)} Profile </H4>
        </PageSection>
        <PageSection>
          <DomainAssessList {...domainsProps} />
        </PageSection>
      </PageBody>
    </Page>
  );
};
export default DefDomains;
