import { buildSlice } from '../core/helpers';
import { Slice } from '../../../global/constants';
import { createCadePerson, initCadePersons } from './core/thunks';
import { mapPayloadToState } from '../../../global/helpers';
import { PayloadAction } from '@reduxjs/toolkit';
import { TPerson, TPersonTempData } from './core/types';
import { TNullable } from '../../../global/types';

interface IPersonsState {
  isInit: boolean;
  isReady: boolean;
  currentPersonId: TNullable<number>;
  tempData: TPersonTempData;
  persons: TPerson[];
}

const initialState: IPersonsState = {
  isInit: false,
  isReady: false,
  currentPersonId: null,
  tempData: {
    firstName: null,
    age: null,
  },
  persons: [],
};
export const slicePersons = buildSlice({
  name: Slice.PERSONS,
  initialState,
  reducers: {
    tempNameUpdated: (
      state,
      { payload }: PayloadAction<{ firstName: string }>
    ) => {
      state.tempData.firstName = payload.firstName;
    },
    tempAgeUpdated: (state, { payload }: PayloadAction<{ age: number }>) => {
      state.tempData.age = payload.age;
    },
  },
  extraReducers: {
    /****************************
     * THUNK - CreatePerson
     */
    [initCadePersons.pending.type]: state => {
      state.isReady = false;
    },
    [initCadePersons.fulfilled.type]: (state, { payload }) => {
      state.isInit = true;

      if (payload !== null) {
        mapPayloadToState(payload, state);
        state.isReady = true;
      }
    },
    [createCadePerson.pending.type]: state => {
      state.isReady = false;
    },
    [createCadePerson.fulfilled.type]: (
      state,
      { payload }: PayloadAction<TPerson>
    ) => {
      // Add new person.  Set current id.
      state.persons.push(payload);
      state.currentPersonId = payload.id;

      // Null out Temp data
      state.tempData.firstName = null;
      state.tempData.age = null;

      state.isReady = true;
    },
  },
});
