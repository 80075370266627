import { TGenericObject } from '../../global/types';

export const pageBodyProps = {
  mb: { base: '50px' },
};
export const headerSectionProps = {
  textAlign: 'center',
  maxW: '530px',
  margin: 'auto',
};
export const pageHeaderProps = {
  mb: '0.25em',
};

export const createImageProps = (props: TGenericObject) => ({
  ...props,
  // Grabbed image size to set aspect ratio from Thinkific site.
  w: 380,
  h: 247,
});
