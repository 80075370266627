import React, { useEffect } from 'react';
import { fireGTMEvent } from '../../global/helpers';
import withLoader from './core/withLoader';
import PageView from './PageView';
import { pageColor } from '../../theme/_';

interface IPage {
  heading: string;
  bgColor?: string;
  children: React.ReactNode;
  pageTitle: string;
  renderDeps: any[];
}

/**
 * @constructor Page
 * @description Base Page component from which all other pages are derived.  Provides base styling.  Fires GTM event on change of page title.  Updates page title through Helmet component.  Shell component. Generates props and passes them to PageView.
 * @param children - Page content
 * @param bgColor - Background color
 * @param pageTitle - Page title
 * @param renderDeps - Array of dependencies to trigger re-render.  Result of previous attemp to memoize component. Not currently used.
 * @param rest - Props to pass to PageView
 * @returns {JSX.Element}
 */
export const Page: React.FC<IPage> = ({
  children,
  bgColor = pageColor,
  pageTitle = '',
  renderDeps = [],
  ...rest
}) => {
  useEffect(() => {
    console.groupCollapsed(pageTitle);
    console.trace();
    console.groupEnd();
    fireGTMEvent('pageview', { pageTitle });
  }, [pageTitle]);

  const viewProps: IPage = {
    bgColor,
    children,
    pageTitle,
    renderDeps,
    ...rest,
  };
  return <PageView {...viewProps} />;
};
export default withLoader(Page);
