import CardDashboard from '../CardDashboard';
import { Box, List, ListItem } from '@chakra-ui/react';
import { TAssessmentDomain } from '../../store/slices';
import { createListItemProps, listProps, createListWrapProps } from './props';

export interface IDomainAssessListView {
  domains: TAssessmentDomain[];
}

/**
 * @constructor DomainAssessListView
 * @description Renders a list of Domain Assessment Cards.  Each Card provides a link to an Assessment for that Domain.  Props passed from DomainAssessList shell component
 * @param domains - Array of Assessment Domains
 * @param rest - additional props passed to the wrapper
 * @returns {JSX.Element}
 */
const DomainAssessListView: React.FC<IDomainAssessListView> = ({
  domains,
  ...rest
}) => {
  return (
    <Box {...createListWrapProps(rest)}>
      <List {...listProps}>
        {domains.map(domain => (
          <ListItem {...createListItemProps(domain)}>
            <CardDashboard {...{ domain }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DomainAssessListView;
