import Button from '../Button';
import { Link } from 'react-router-dom';

/**
 * @function ButtonRoute
 * @description General app Button cast as Router Link
 * @param props
 * @return {JSX.Element}
 */
export const ButtonRoute = props => <Button as={Link} {...props} />;
export const WithButtonRoute = ({ children, to }) => (
  <ButtonRoute
    {...{
      to,
      variant: 'unstyled',
    }}
  >
    {children}
  </ButtonRoute>
);

export default ButtonRoute;
