import React from 'react';
import { acSetAssessmentDomainId } from '../../../../store';
import { useDispatch } from 'react-redux';
import { useQuestionnaireRoute } from '../../../../quests/useQuestionnaireRoute';
import { useRedirect } from '../../../../hooks/useRedirect';
import NotCompleteView from './NotCompleteView';

interface IContainer {
  domainId: number;
  approximateTimeToComplete?: number;
  numberOfItems?: number;
}
export const NotComplete: React.FC<IContainer> = ({
  domainId,
  approximateTimeToComplete = 0,
  numberOfItems = 0,
}) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const questionnaireRoute = useQuestionnaireRoute(domainId);
  const buttonClickHandler = () => {
    dispatch(acSetAssessmentDomainId(domainId));
    redirect(questionnaireRoute);
  };

  const notStartedProps = {
    approximateTimeToComplete,
    buttonClickHandler,
    numberOfItems,
  };
  return <NotCompleteView {...notStartedProps} />;
};
export default NotComplete;
