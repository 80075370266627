export const uiHeading = {
  cell: {
    icon: {
      justifyContent: 'center' as 'center',
      mb: '14px',
      flex: 0,
    },
    header: {
      textAlign: 'center' as 'center',
      mb: '8px',
      flex: 0,
    },
    content: {
      textAlign: 'center' as 'center',
      mb: '24px',
      maxW: '30em',
      flex: 1,
    },
    button: {
      textAlign: 'center' as 'center',
      mb: '15px',
      width: '220px',
      flex: 0,
    },
    aux: {
      textAlign: 'center' as 'center',
      flex: 0,
    },
  },
};
export const wrapProps = {
  display: 'flex' as 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center' as 'center',
  w: '100%',
};
