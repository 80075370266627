import React from 'react';
import { TAssessmentDomain } from '../../store/slices';
import CardDashboardView, { ICardDashboardView } from './CardDashboardView';
import { ButtonKind } from '../Button';
import { ConcernLevel } from '../../global/constants';
import { useDispatch } from 'react-redux';
import { useRedirect } from '../../hooks/useRedirect';
import { useQuestionnaireRoute } from '../../quests/useQuestionnaireRoute';
import { acSetAssessmentDomainId } from '../../store';
import { getResultPath } from '../../global/helpers';

/**
 * @function createButtonUI
 * @description Creates the UI props for the Card's Button
 * @param isScored
 * @returns {TGenericObject}
 */
export const createButtonUI = (isScored: boolean) => ({
  ...(isScored
    ? {
        children: 'Learn More',
        variant: 'quaternary',
      }
    : {
        children: 'Assess',
        variant: 'tertiary',
      }),
  kind: ButtonKind.UTILITY,
});

interface ICardDashboard {
  domain: TAssessmentDomain;
}

/**
 * @constructor CardDashboard
 * @description UI Card that displays a Domain's Score and Concern Level on Results page.  If the Domain has not been scored, the Card button will redirect to the Questionnaire.  If the Domain has been scored, the Card button will redirect to the Result Page.  Shell component.  Generates props and passes to View.
 * @param domain - Cadey Domain to display
 * @returns {JSX.Element}
 */
export const CardDashboard: React.FC<ICardDashboard> = ({ domain }) => {
  const {
    concernLevel,
    displayName: domainName,
    domainDefId,
    score,
    slug,
  } = domain;

  const dispatch = useDispatch();
  const redirect = useRedirect();
  const questionnairePath = useQuestionnaireRoute(domainDefId);

  console.log('questionnairePath!', questionnairePath);
  const createButtonClickHandler = (isScored: boolean) => () => {
    if (isScored) {
      redirect(getResultPath(slug));
      return;
    }

    dispatch(acSetAssessmentDomainId(domainDefId));
    redirect(questionnairePath);
  };
  // The Score is valid if the ConcernLevel is *NOT* invalid
  const invalidConcernLevels = [ConcernLevel.NO_SCORE, ConcernLevel.UNKNOWN];
  const isScored = !invalidConcernLevels.includes(domain.concernLevel);

  const buttonProps = {
    ...createButtonUI(isScored),
    onClick: createButtonClickHandler(isScored),
  };

  const viewProps: ICardDashboardView = {
    concernLevel,
    domainName,
    domainDefId,
    score,
    buttonProps,
  };

  return <CardDashboardView {...viewProps} />;
};
export default CardDashboard;
