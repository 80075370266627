import React from 'react';
import { TGenericObject } from '../../../global/types';
import { LookupMap } from '../../../global/LookupMap';
import { IconType } from './enum';

export type TIconFactory = (
  color: string,
  size: number,
  containerType?: number
) => React.ReactElement;
export enum ContainerType {
  NONE,
  SQUARE,
  CIRCLE,
}
type TIconMenagerie = {
  [key: string]: TIconFactory;
};
export const iconTypeFallback = 'fallback';
export const getStrokeWidth = (size: number): number => {
  const baselineStrokeWidth = 1.1;
  const baselineSize = 32;
  return (baselineStrokeWidth * baselineSize) / size;
};

/**
 * @function createCommonElProps
 * @description Creates common props for SVG elements.
 * @param color - The color of the element.
 * @param size  - The size of the element.
 * @param addl - Any additional props to add to the element.
 */
const createCommonElProps = (
  color: string,
  size: number,
  addl: TGenericObject = {}
): TGenericObject => ({
  stroke: color,
  strokeWidth: getStrokeWidth(size),
  ...addl,
});

type TTreatIcon = (
  iconBody: any,
  color: string,
  size: number,
  containerType?: number
) => any;

const containerXY = 24.9;
/**
 * @constant IconContainerMap
 * @description A map of SVG elements to use as containers for icons.
 */
const IconContainerMap = new LookupMap<React.ReactNode>(
  {
    [ContainerType.SQUARE]: (
      <rect
        x="3.55"
        y="3.55"
        width={containerXY}
        height={containerXY}
        rx="1.45"
      />
    ),
    [ContainerType.CIRCLE]: <circle cx="16" cy="16" r={15} />,
  },
  null
);

/**
 * @function treatIcon
 * @description Wraps an icon in a container (if applicable) and applies common props.
 * @param iconBody - The icon to wrap.
 * @param color - The color of the icon.
 * @param size  - The size of the icon.
 * @param containerType - The type of container to use.
 * @returns {JSX.Element}
 */
const treatIcon: TTreatIcon = (
  iconBody,
  color,
  size,
  containerType = ContainerType.NONE
) => {
  const container = IconContainerMap.get(containerType);
  return (
    <g {...createCommonElProps(color, size)}>
      {container}
      {iconBody}
    </g>
  );
};

const strokeMiterlimit = 10;
/**
 * @constant IconMenagerie
 * @description A map of icon factories. Serves as the Icon palette for the application.
 */
export const IconMenagerie: TIconMenagerie = {
  [IconType.ARROW_LEFT]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path
        d="M20.0835 16H11.9168"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 11.9166L11.9167 16L16 20.0833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  [IconType.ARROW_UP]: (color, size) => (
    <path
      d="M11 12h10M12 20l4-5 4 5"
      {...createCommonElProps(color, size, {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      })}
    />
  ),
  [IconType.BOOKMARK]: (color, size) => (
    <g {...createCommonElProps(color, size, { strokeMiterlimit })}>
      <path d="M24.754 5h-14.61C8.45 5 7 6.3 7 7.999v18.353a.69.69 0 0 0 .179.46.57.57 0 0 0 .431.188.568.568 0 0 0 .349-.115l6.78-4.95 6.774 4.95c.134.098.3.135.459.104a.622.622 0 0 0 .397-.268.71.71 0 0 0 .108-.369v-11" />
      <path d="M24.754 5c.293-.005.584.052.856.168.273.116.52.29.73.51.209.22.375.482.488.772.114.29.172.601.172.916v8.442h-4.523V7.366c.003-.317.064-.63.18-.92a2.4 2.4 0 0 1 .497-.774c.212-.22.463-.392.737-.508.275-.115.568-.17.863-.163z" />
    </g>
  ),
  [IconType.BROKEN_RULER]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path
        d="m16.938 17.4-.224-1.663-3.085-.726-.719-1.678L2.667 23.49l4.032 4.066L16.939 17.4z"
        strokeLinejoin="round"
      />
      <path
        d="m6 20.754 1.887 1.91M9.754 17l1.887 1.91M7.875 18.879l.704.719M9.778 7.273l2.146 2.29M16.2 9.128l1.764-2.01M14.07 5.333V9.88M22.81 12 21 13.862M26.525 15.861l-1.807 1.863M24.721 13.87l-.673.703"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="m26.07 22.327-9.447-9.257 1.612-.221.7-2.985 1.623-.696 9.447 9.257-3.935 3.902z"
        strokeLinejoin="round"
      />
    </g>
  ),
  [IconType.BULLHORN]: (color, size) => (
    <g {...createCommonElProps(color, size, { strokeMiterlimit })}>
      <path d="M18.813 4.174c-.146.153-.277.32-.392.498-1.325 1.99-2.06 5.913-9.69 8.655l-1.984.712c-1.345.498-1.81 2.534-1.047 4.599.764 2.066 2.48 3.32 3.815 2.832l1.985-.712c7.635-2.742 10.701-.224 13.018.433a1.484 1.484 0 0 0 1.141-.06" />
      <path d="M19.414 3.612c-1.489.547-1.27 4.917.466 9.76s4.321 8.332 5.8 7.77c1.478-.563 1.275-4.918-.467-9.76-1.741-4.844-4.34-8.313-5.8-7.77z" />
      <path d="M19.453 11.401a1.885 1.885 0 0 0-.188.075 28.531 28.531 0 0 0 1.701 4.564c.051-.017.101-.037.15-.06a2.483 2.483 0 0 0 1.36-1.272 2.496 2.496 0 0 0 .098-1.864 2.436 2.436 0 0 0-1.263-1.37 2.424 2.424 0 0 0-1.858-.073zM8.643 13.268c.127 1.333.442 2.642.937 3.887a12.903 12.903 0 0 0 1.831 3.549s1.737 7.54 2.977 7.73c.878.149 2.113-1.26 2.113-1.26s.56-.527.392-.995l-1.176-.941-.06-1.424c-.223-.582-.614-.433-.689-.955-.188-1.389.804-1.747.392-2.872" />
    </g>
  ),
  [IconType.BULLSEYE]: (color, size) => (
    <g {...createCommonElProps(color, size, { strokeMiterlimit })}>
      <path d="m25.057 7.808 2.21.354a.497.497 0 0 0 .358-.15l2.449-2.445a.498.498 0 0 0 .11-.548.498.498 0 0 0-.463-.31h-1.573V3.17a.498.498 0 0 0-.314-.463.498.498 0 0 0-.547.105l-2.449 2.45a.498.498 0 0 0-.149.354l.368 2.192z" />
      <path d="m24.997 7.863-9.038 9.027" strokeLinecap="round" />
      <path d="M15.88 20.537a3.513 3.513 0 1 0 0-7.026 3.513 3.513 0 0 0 0 7.026z" />
      <path d="M15.88 24.043c3.884 0 7.032-3.142 7.032-7.019 0-3.876-3.148-7.019-7.032-7.019s-7.033 3.143-7.033 7.02c0 3.876 3.149 7.018 7.033 7.018z" />
      <path d="M15.88 27.556c5.824 0 10.545-4.715 10.545-10.532 0-5.816-4.721-10.53-10.546-10.53-5.824 0-10.546 4.714-10.546 10.53 0 5.817 4.722 10.532 10.546 10.532z" />
    </g>
  ),
  [IconType.CHART]: (color, size, containerType) =>
    treatIcon(
      <>
        <rect x="7.05" y="18.189" width="4.05" height="6.076" rx="1" />
        <rect x="13.126" y="10.088" width="4.05" height="14.176" rx="1" />
        <rect x="20.214" y="13.126" width="4.05" height="11.139" rx="1" />
      </>,
      color,
      size,
      containerType
    ),
  [IconType.CHAT_BUBBLES]: (color, size) => (
    <path
      d="M15.664 22.19c0 .757.28 1.483.78 2.019.5.535 1.178.836 1.885.836h2.665l3.19 3.4v-3.4h1.595c.707 0 1.385-.3 1.885-.836s.78-1.262.78-2.02v-2.266c0-.757-.28-1.484-.78-2.02a2.579 2.579 0 0 0-1.885-.836M18.71 3.556H7.882a4.048 4.048 0 0 0-1.652.348c-.525.232-1.001.573-1.403 1.003-.402.43-.721.941-.94 1.504a4.95 4.95 0 0 0-.331 1.777V12.8a4.95 4.95 0 0 0 .332 1.777c.218.563.537 1.074.939 1.504.402.43.878.771 1.403 1.003.524.232 1.085.35 1.652.348H9.51v4.613l4.326-4.632h4.874a4.05 4.05 0 0 0 1.655-.34 4.301 4.301 0 0 0 1.41-.995c.404-.429.726-.939.947-1.501a4.951 4.951 0 0 0 .341-1.777V8.163a4.953 4.953 0 0 0-.342-1.775 4.678 4.678 0 0 0-.948-1.499 4.304 4.304 0 0 0-1.409-.994 4.05 4.05 0 0 0-1.654-.34z"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...createCommonElProps(color, size)}
    />
  ),
  [IconType.CHECK_CIRCLE]: (color, size) => (
    <>
      <path
        d="M16 6C10.48 6 6 10.48 6 16C6 21.52 10.48 26 16 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 16 6Z"
        fill={color}
      />
      <path
        d="M10 16L14 20L22 12"
        stroke="white"
        strokeWidth={getStrokeWidth(size)}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  [IconType.CHECKMARK]: (color, size, containerType) =>
    treatIcon(
      <path
        d="M10 17.1364L13.7188 20.7424L23.5 11"
        strokeLinecap="round"
        strokeLinejoin="round"
      />,
      color,
      size,
      containerType
    ),
  [IconType.CLOSE]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path d="M23 9 9 23M23 23 9 9" strokeLinecap="round" />
    </g>
  ),
  [IconType.EKG]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path
        d="M3 19.2753H6.02041L10.6301 11.8427L14.1761 26L19.1404 5L21.6225 19.2753H28.5652"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{' '}
    </g>
  ),
  [IconType.GRADUATION_CAP]: (color, size) => (
    <g {...createCommonElProps(color, size, { strokeMiterlimit })}>
      <path d="M28.444 14.222v5.576M28.336 14.096a1 1 0 0 0 0-1.804L16.099 6.434a1 1 0 0 0-.88.008L3.556 12.3a1 1 0 0 0 0 1.788l11.66 5.854a1 1 0 0 0 .88.008l12.24-5.854z" />
      <path d="M24.89 16v6.92a.933.933 0 0 1-.301.7c-1.796 1.61-4.99 3.047-8.631 3.047-3.634 0-6.731-1.455-8.533-3.027a.94.94 0 0 1-.314-.712V16M26.667 22.465l1.776-2.667 1.78 2.667h-3.556z" />
    </g>
  ),
  [IconType.HAMBURGER]: (color, size) => (
    <g
      {...createCommonElProps(color, size, {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      })}
    >
      <path d="M7 9H24.5" />
      <path d="M7 16H24.5" />
      <path d="M7 23H24.5" />
    </g>
  ),
  [IconType.HEART]: (color, size) => (
    <path
      d="M21.455 6a6.076 6.076 0 0 0-3.27.957A5.793 5.793 0 0 0 16 9.5a5.793 5.793 0 0 0-2.186-2.545A6.077 6.077 0 0 0 10.54 6C6.459 6 4 9.765 4 12.87 4 21.71 16 26 16 26s12-4.29 12-13.13C28 9.765 25.541 6 21.455 6z"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...createCommonElProps(color, size)}
    />
  ),
  [IconType.LIGHT_BULB]: (color, size) => (
    <g {...createCommonElProps(color, size, { strokeMiterlimit })}>
      <g strokeLinecap="round">
        <path d="m16.1387 3.55566v4.00839" />
        <path d="m7.11133 9.23328 2.7164 1.58892" />
        <path d="m25.1663 9.23328-2.7163 1.58892" />
      </g>
      <path d="m13.2748 22.4339h-.0762c-.2547 0-.4991.1009-.6795.2807-.1805.1797-.2824.4236-.2835.6783.0004.2072.0678.4086.1922.5742.1244.1657.2991.2866.4979.3446v1.5769c0 .2309.0918.4524.2551.6157s.3847.255.6156.255h.0482v.325c0 .6179.5015 1.3602 1.1194 1.3602h2.3834c.6179 0 1.1194-.7423 1.1194-1.3602v-.325h.0482c.231 0 .4524-.0917.6157-.255s.255-.3848.255-.6157v-1.5769c.1988-.058.3735-.1789.4979-.3446.1244-.1656.1919-.367.1922-.5742 0-.1259-.0248-.2506-.073-.367-.0482-.1163-.1188-.222-.2078-.3111-.0891-.089-.1948-.1597-.3112-.2079-.1163-.0482-.241-.073-.3669-.073h-.0763c.0369-1.2213.511-2.3889 1.3361-3.2901.9936-1.0664 1.5444-2.4707 1.5408-3.9282-.0003-.7899-.1621-1.5714-.4754-2.2966-.3132-.7252-.7713-1.3787-1.3462-1.9204-.5712-.5437-1.2494-.9626-1.9913-1.22993s-1.5313-.37729-2.318-.32288c-1.4195.10171-2.7523.72091-3.7456 1.74001-.9933 1.0192-1.5779 2.3675-1.6431 3.7891-.033.764.0875 1.5269.3542 2.2436s.6742 1.3727 1.1986 1.9293c.8211.9012 1.2911 2.0674 1.3241 3.2861z" />
      <path d="m12.9099 24.2998h6.46" />
      <path d="m13.2539 22.4219h5.7698" />
      <path d="m13.8276 26.7472h4.6223" />
      <path d="m17.01 22.4981v-7.2022c0-.3026.1199-.5929.3335-.8072s.5034-.3353.806-.3364c.3026.0011.5924.1221.806.3364s.3335.5046.3335.8072-.1199.5928-.3335.8072c-.2136.2143-.5034.3353-.806.3363h-4.0124c-.3026-.001-.5925-.122-.806-.3363-.2136-.2144-.3335-.5046-.3335-.8072s.1199-.5929.3335-.8072c.2135-.2143.5034-.3353.806-.3364.3026.0011.5924.1221.806.3364s.3335.5046.3335.8072v7.2022" />
    </g>
  ),
  [IconType.MY_PLAN]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path d="M22.185 9H9.815A.815.815 0 0 0 9 9.815v12.37c0 .45.365.815.815.815h12.37c.45 0 .815-.365.815-.815V9.815A.815.815 0 0 0 22.185 9z" />
      <path d="M16 14h4M16 18h4" strokeLinecap="round" />
      <path d="M13 19a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM13 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    </g>
  ),
  [IconType.MY_PLAN_LARGE]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <rect x="3.55" y="3.55" width="24.9" height="24.9" rx="1.45" />
      <path d="M16 11.937h6.5M16 20.063h6.5" strokeLinecap="round" />
      <circle cx="10.313" cy="20.063" r="1.887" />
      <circle cx="10.313" cy="12.438" r="1.887" />
    </g>
  ),
  [IconType.PAPERCLIP]: (color, size) => (
    <path
      d="M26.574 16.177 16.3 26.417a6.907 6.907 0 0 1-4.916 2.027 6.914 6.914 0 0 1-4.911-2.026 6.92 6.92 0 0 1-2.03-4.893 6.92 6.92 0 0 1 2.03-4.893l12.16-11.697a4.674 4.674 0 0 1 3.34-1.38 4.694 4.694 0 0 1 3.341 1.38 4.706 4.706 0 0 1 1.376 3.323c0 1.244-.494 2.439-1.375 3.323l-12.15 11.696a2.513 2.513 0 0 1-1.77.726c-.664 0-1.3-.26-1.77-.726a2.473 2.473 0 0 1-.73-1.766 2.48 2.48 0 0 1 .724-1.756L20.199 9.598"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...createCommonElProps(color, size)}
    />
  ),
  [IconType.RAIN_CLOUD]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path
        d="M24.6431 19.6103L24.6431 19.6102H24.6343H7.92541V19.61L7.91275 19.6104C7.89276 19.6109 7.87332 19.6108 7.84652 19.6108C7.84033 19.6108 7.83375 19.6108 7.82668 19.6108C5.9894 19.6108 4.5 18.1214 4.5 16.2841C4.5 14.5683 5.79936 13.1554 7.46744 12.9766C7.60907 12.9614 7.7375 12.8865 7.8205 12.7708C7.9035 12.655 7.93319 12.5094 7.90211 12.3704C7.85903 12.1776 7.8362 11.9768 7.8362 11.77C7.8362 10.2579 9.06203 9.03205 10.5742 9.03205C10.8176 9.03205 11.0529 9.0637 11.2765 9.12283C11.4058 9.15705 11.5435 9.13798 11.6587 9.0699C11.7739 9.00181 11.857 8.8904 11.8894 8.76057C12.3571 6.88726 14.0518 5.5 16.0692 5.5C17.8973 5.5 19.4606 6.63888 20.086 8.24762C20.1365 8.37753 20.239 8.48047 20.3686 8.5316C20.4983 8.58273 20.6434 8.57741 20.769 8.51692C21.1273 8.34433 21.5293 8.24738 21.9554 8.24738C23.4675 8.24738 24.6933 9.4732 24.6933 10.9853C24.6933 11.4353 24.5852 11.8585 24.3939 12.2318C24.3159 12.3841 24.3211 12.5656 24.4078 12.7131C24.4945 12.8606 24.6505 12.9536 24.8215 12.9595C26.6039 13.0215 28.0301 14.4864 28.0301 16.2841C28.0301 18.0927 26.5868 19.5642 24.7894 19.6097L24.7637 19.6103C24.7634 19.6103 24.7631 19.6103 24.7628 19.6103C24.743 19.6106 24.7232 19.6108 24.7034 19.6108C24.6833 19.6108 24.6632 19.6106 24.6431 19.6103Z"
        strokeLinejoin="round"
      />
      <path
        d="M11.5005 24.2805V26.2429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7134 22.6741V24.6365"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3535 21.8705V23.8329"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1404 24.2682V26.2306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9268 22.6741V24.6365"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01636 21.8705V23.8329"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  [IconType.RIBBON]: (color, size, containerType) =>
    treatIcon(
      <>
        <path d="M9 23.068V9a1 1 0 0 1 1-1h11.136a1 1 0 0 1 1 1v8.273" />
        <path d="M9 22.682v.742a1 1 0 0 0 1.426.905l4.716-2.22a1 1 0 0 1 .852 0l4.717 2.22a1 1 0 0 0 1.425-.905v-8.47" />
      </>,
      color,
      size,
      containerType
    ),
  [IconType.RIBBON_STRIKE]: (color, size, containerType) =>
    treatIcon(
      <>
        <path d="M9 23.0682V9C9 8.44772 9.44772 8 10 8H21.1364C21.6886 8 22.1364 8.44772 22.1364 9V17.2727" />
        <path d="M13 12L18 17" strokeLinecap="round" />
        <path d="M18 12L13 17" strokeLinecap="round" />
        <path d="M9 22.6819V23.4243C9 24.1573 9.76255 24.6413 10.4258 24.3291L15.1424 22.1096C15.4121 21.9827 15.7243 21.9827 15.994 22.1096L20.7106 24.3291C21.3738 24.6413 22.1364 24.1573 22.1364 23.4243V14.9547" />
      </>,
      color,
      size,
      containerType
    ),
  [IconType.STRESSED_FACE]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <circle cx="16" cy="16" r="11.5" />
      <path
        d="M11.6362 11.6365L13.818 13.3819L11.6362 15.1274"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3638 11.6365L18.182 13.3819L20.3638 15.1274"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6362 20.3635C12.0241 20.7272 12.7999 21.4544 14.5453 21.4544C16.2908 21.4544 17.4544 19.2726 20.3635 21.4544"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  [IconType.SWIRLY_ARROWS]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path
        d="m24.686 21.141 2.186-2.282a6.68 6.68 0 0 0 1.573-4.337 6.698 6.698 0 0 0-1.85-4.615A6.143 6.143 0 0 0 22.165 8c-.824 0-1.64.168-2.4.497a6.26 6.26 0 0 0-2.036 1.418 6.572 6.572 0 0 0-1.36 2.123 6.799 6.799 0 0 0-.48 2.505l.226 2.91c0 .86-.163 1.712-.48 2.506a6.578 6.578 0 0 1-1.36 2.125 6.265 6.265 0 0 1-2.038 1.419 6.047 6.047 0 0 1-4.804 0 6.265 6.265 0 0 1-2.036-1.419 6.577 6.577 0 0 1-1.362-2.125 6.802 6.802 0 0 1-.48-2.506 6.68 6.68 0 0 1 1.578-4.333l2.186-2.282"
        strokeMiterlimit="10"
      />
      <path
        d="M28.444 21.333h-3.83v-4M3.556 10.667h3.829v4"
        strokeLinecap="round"
      />
    </g>
  ),
  [IconType.THUMBS_DOWN]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path d="M4.68429 13.6946L4.68553 13.6917L8.2183 5.52382C8.6105 4.8917 9.31751 4.50002 10.0665 4.50002H20.8797C22.3249 4.50002 23.4996 5.67753 23.4996 7.10664V17.5598C23.4996 18.1337 23.2724 18.6865 22.8594 19.0996L14.6703 27.2887L14.4105 27.0232L14.4105 27.0232L14.4067 27.0194C13.9672 26.5799 13.6108 26.0775 13.3261 25.521C13.2448 25.3571 13.2201 25.156 13.2607 24.9772L13.2609 24.9772L13.2636 24.9634L14.4903 18.7635L14.6084 18.1665H13.9998H6.66658C5.47608 18.1665 4.49996 17.1903 4.49996 15.9998V14.5598C4.49996 14.2589 4.56429 13.9673 4.68429 13.6946Z" />
      <path d="M28 15.9996L28 6.66645" strokeLinecap="square" />
    </g>
  ),
  [IconType.THUMBS_UP]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path d="M27.3157 18.3051L27.3145 18.3079L23.7817 26.4758C23.3895 27.1079 22.6825 27.4996 21.9335 27.4996H11.1203C9.67509 27.4996 8.50037 26.3221 8.50037 24.893V14.4398C8.50037 13.866 8.72756 13.3131 9.14057 12.9001L17.3297 4.71093L17.5895 4.97639L17.5895 4.97641L17.5933 4.98021C18.0328 5.41969 18.3892 5.92217 18.6739 6.47867C18.7552 6.64254 18.7799 6.84363 18.7393 7.02247L18.7391 7.02243L18.7364 7.03624L17.5097 13.2361L17.3916 13.8332H18.0002H25.3334C26.5239 13.8332 27.5 14.8093 27.5 15.9998V17.4398C27.5 17.7408 27.4357 18.0324 27.3157 18.3051Z" />
      <path d="M4 16L4 25.3332" strokeLinecap="square" />
    </g>
  ),
  [IconType.TOOTHBRUSH]: (color, size) => (
    <g {...createCommonElProps(color, size, { strokeMiterlimit })}>
      <path d="m20.814 14.548 5.992-6.4a1.202 1.202 0 0 0 .337-.869 1.227 1.227 0 0 0-.373-.853 1.17 1.17 0 0 0-.842-.331c-.312.007-.608.14-.826.371l-5.72 6.12-3.595 1.51L5.19 25.438a1.2 1.2 0 0 0-.332.86 1.226 1.226 0 0 0 .363.847 1.16 1.16 0 0 0 .84.341 1.135 1.135 0 0 0 .824-.38L17.51 15.759l3.304-1.21z" />
      <path d="m19.663 12.295-2.55-2.516a1.198 1.198 0 0 1-.363-.847 1.23 1.23 0 0 1 .332-.86l3.814-4.074a1.17 1.17 0 0 1 .824-.37c.312-.008.613.112.84.331l2.547 2.51M17.357 7.787l3.393 3.344M18.448 6.625l3.393 3.344M19.535 5.46l3.397 3.343M20.625 4.294l3.393 3.344" />
    </g>
  ),
  [IconType.TRASH]: (color, size) => (
    <g {...createCommonElProps(color, size, { strokeLinecap: 'round' })}>
      <path d="M12 13v9M15.5 13v9M19 13v9" strokeLinejoin="round" />
      <path d="M9 12v11a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V12" />
      <path d="M9 9h13" strokeLinejoin="round" />
      <path d="M12 9v0a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v0" />
    </g>
  ),
  [IconType.WARNING]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path d="M16 4.21533L28 24.9999H4L16 4.21533Z" strokeLinejoin="round" />
      <path d="M16 20.7404H16" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16 11.6053L16 18.0717"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  [iconTypeFallback]: (color, size) => (
    <g {...createCommonElProps(color, size)}>
      <path d="M23 9 9 23M23 23 9 9" strokeLinecap="round" />
    </g>
  ),
};
