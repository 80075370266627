import { TClickEvent, TGenericObject } from '../../global/types';
import { createGutterProps } from '../../theme/helpers';
import { pageGutter } from '../Page/props';
import { maxPageInteriorWidth } from '../../global/constants';
import { getDomainsPath } from '../../global/helpers';

const breakpoint = 'lg';
const headingGutter = '0.3em';
const maxMediaWidth = '530px';
const [gContentWrap, gContentCell] = createGutterProps(pageGutter);
const [gHeaderWrap, gHeaderCell] = createGutterProps(50);
const [gButtonList, gButtonItem] = createGutterProps(20);

export enum CellType {
  CONTENT,
  MEDIA,
}
export const contentHeadingProps = {
  mb: headingGutter,
};

export const contentRowsProps = {
  ...gContentWrap,
};
export const ctaWrapProps = {
  mt: '20px',
};

export const headerRowProps = {
  ...gHeaderWrap,
  display: 'flex',
  alignItems: { base: 'stretch', [breakpoint]: 'center' },
  flexDirection: {
    base: 'column',
    [breakpoint]: 'row',
  },
} as any;

export const pageBodyProps = {
  bgColor: 'sand.300',
};

export const pageHeadingAreaProps = {
  bgColor: 'sand.500',
  pt: { base: '30px', [breakpoint]: '50px' },
  pb: '60px',
  px: `${pageGutter}px`,
  maxW: `${maxPageInteriorWidth}px`,
  margin: 'auto',
};
export const pageHeadingProps = {
  mb: headingGutter,
};
export const buttonListProps = {
  ...gButtonList,
  display: 'flex',
  flexDirection: {
    base: 'column',
    [breakpoint]: 'row',
  } as any,
};
export const buttonItemProps = {
  ...gButtonItem,
};
const commonButtonProps = {
  w: {
    base: '100%',
    [breakpoint]: 'auto',
  },
};
export const signInProps = {
  ...commonButtonProps,
  variant: 'tertiary',
  to: getDomainsPath(),
};
export const cellInteriorProps = {
  width: '100%',
};
export const innerProps = {
  margin: 'auto',
  maxW: { base: maxMediaWidth, [breakpoint]: '100%' },
};

/**
 * Factories
 */
export const createCellProps = (
  props: { type: number; alt?: boolean } = { type: CellType.MEDIA, alt: false }
) => ({
  ...gContentCell,
  flex: 1,

  // Styles for Content Cell
  ...(props.type === CellType.CONTENT && {
    alignItems: 'center',
    display: 'flex',
  }),

  // Styles for Media Cell
  ...(props.type === CellType.MEDIA && {
    flex: { base: 0, [breakpoint]: `0 0 ${maxMediaWidth}` },
  }),

  // Swap Alt Row cell order on Desktop
  ...(props.alt && {
    order: {
      [breakpoint]: props.type === CellType.CONTENT ? 1 : 2,
    },
  }),
});

export const createContentRowProps = () =>
  ({
    display: 'flex',
    flexDirection: { base: 'column', lg: 'row' },
  } as any);

export const createHeaderCellProps = (props: { type: number }) => ({
  ...gHeaderCell,
  ...(props.type === CellType.CONTENT && {
    flex: 2,
  }),
  ...(props.type === CellType.MEDIA && {
    flex: 3,
  }),
});

export const createImageProps = (props: TGenericObject) => ({
  ...props,
  w: 1040,
  h: 780,
});
export const createPageProps = (loadedConditions: boolean[]) => ({
  textAlign: { base: 'center', [breakpoint]: 'left' },
  loadedConditions,
});
export const createTrialButtonProps = (onClick: TClickEvent) => ({
  ...commonButtonProps,
  variant: 'primary',
  onClick,
});
