import { TAssessment, TAssessmentsState } from './types';
import { ConcernLevel } from '../../../../global/constants';

export const assignAssessment = (
  state: TAssessmentsState,
  assessment: TAssessment
) => {
  state.assessments.push(assessment);
  state.currentAssessmentId = assessment.id;
  state.isReady = true;
};

enum QuestionnaireCompletionStatus {
  COMPLETE,
  INCOMPLETE,
  UNDERWAY,
}

export const getAssessmentCompletionStatus = (assessment?: TAssessment) => {
  const questionnairesStatusTemplate = [
    [] as number[],
    [] as number[],
    [] as number[],
  ];

  if (!assessment) return questionnairesStatusTemplate;

  const { assessmentDomains: questionnaires = [] } = assessment;

  const questionnairesStatus = questionnaires.map(
    ({ domainDefId: domainId, assessmentItems }) => {
      const numAnswered = assessmentItems.filter(
        ({ responseId }) => responseId !== ConcernLevel.NO_RESPONSE
      ).length;
      const numTotal = assessmentItems.length;

      return {
        domainId,
        ratioComplete: numAnswered / numTotal,
      };
    }
  );

  return questionnairesStatus.reduce((acc, { domainId, ratioComplete }) => {
    const completionIdx =
      ratioComplete === 1
        ? QuestionnaireCompletionStatus.COMPLETE
        : ratioComplete === 0
        ? QuestionnaireCompletionStatus.INCOMPLETE
        : QuestionnaireCompletionStatus.UNDERWAY;
    acc[completionIdx].push(domainId);
    return acc;
  }, questionnairesStatusTemplate);
};
