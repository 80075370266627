import Button from '../Button';
import { uiBase, uiVisibility } from './core/ui';
import React, { useEffect, useState } from 'react';
import { getScrollPosition, performScrollTo } from '../../global/helpers';

// TODO: Convert to Stateful Component Pattern

interface IButtonScrollToTop {
  visibilityThreshold?: number;
  children: React.ReactNode;
}

/**
 * @function getVisibility
 * @description Determines whether the button should be visible or not
 * @param scrollPosition
 */
const getVisibility: (scrollPosition: number) => boolean = scrollPosition => {
  const buttonVisibilityThreshold = 100;
  return scrollPosition >= buttonVisibilityThreshold;
};

/**
 * @function handleClick
 * @description Performs scroll animation to the top of the page
 */
const handleClick = () => {
  performScrollTo(0, 'smooth');
};
/**
 * @constructor ButtonScrollToTop
 * @description Button that triggers a scroll animation to the top of the page.  Conditionally visible based on the scroll position
 * @param visibilityThreshold
 * @param children
 * @param rest
 * @returns {JSX.Element}
 */
export const ButtonScrollToTop: React.FC<IButtonScrollToTop> = ({
  visibilityThreshold = 100,
  children,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(
    getVisibility(getScrollPosition())
  );

  const handleAppScroll = () => {
    setIsVisible(getVisibility(getScrollPosition()));
  };

  useEffect(() => {
    document.addEventListener('scroll', handleAppScroll);
    return () => {
      document.removeEventListener('scroll', handleAppScroll);
    };
  }, []);

  const buttonProps = {
    width: 'auto',
    ...uiBase,
    ...uiVisibility(isVisible),
    ...rest,
    onClick: handleClick,
  };

  return <Button {...buttonProps}>{children}</Button>;
};

export default ButtonScrollToTop;
