import Button from '../Button';
import React from 'react';
import { useSignOut } from '../../hooks/useSignOut';

interface IButtonSignOut {
  children?: string;
  variant?: string;
  redirectPath?: string;
  callback?: () => void;
}

/**
 * @constructor ButtonSignOut
 * @description Button that signs the user out of the application, clears the Store, and redirects to the specified path if provided
 * @param children - optional custom text to display in button
 * @param callback - optional callback to run before sign out
 * @param redirectPath - optional path to redirect to after sign out
 * @param variant - optional variant to pass to Button
 * @param rest - optional props to pass to Button
 * @returns {JSX.Element}
 */
export const ButtonSignOut: React.FC<IButtonSignOut> = ({
  children = 'Sign Out',
  callback,
  redirectPath,
  variant = 'quaternary',
  ...rest
}) => {
  const signOut = useSignOut();
  const handleSignOut = () => {
    if (!!callback) {
      callback();
    }
    signOut(redirectPath);
  };

  const buttonProps = {
    onClick: handleSignOut,
    variant,
    ...rest,
  };
  return <Button {...buttonProps}>{children}</Button>;
};

export default ButtonSignOut;
