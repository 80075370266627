import { getTypography } from '../../../theme/_';
import { ContainerType } from '../../Icon/core/library';

export const utilityButtonProps = {
  variant: 'quaternary',
  w: 'auto',
  p: 0,
  textDecoration: 'none',
  ...getTypography('h5'),
};

export const buttonProps = {
  ...getTypography('caption'),
  color: 'blue.800',
  w: '100%',
};
export const iconCommonProps = {
  // size: 20,
  display: 'inline-block',
};
export const iconAssessProps = {
  ...iconCommonProps,
  type: 'chart',
  containerType: ContainerType.SQUARE,
};
export const personHeadingProps = {
  mb: '0.3412969283em',
};
