import React from 'react';
import { Box } from '@chakra-ui/react';
import { LookupMap } from '../../global/LookupMap';
import { IGaugeView } from './GaugeView/common';
import GaugeViewLarge from './GaugeView/Large';
import GaugeViewSmall from './GaugeView/Small';
import { ConcernLevel } from '../../global/constants';

export enum GaugeViewName {
  LARGE = 'large',
  SMALL = 'small',
}

const GaugeViewMap = new LookupMap<React.FC<IGaugeView>>(
  {
    [GaugeViewName.LARGE]: GaugeViewLarge,
    [GaugeViewName.SMALL]: GaugeViewSmall,
  },
  GaugeViewLarge
);

export interface IGauge {
  view: GaugeViewName.SMALL | GaugeViewName.LARGE;
  score: number;
  domainDefId: number;
  concernLevel: number;
}

/**
 * @constructor Gauge
 * @description Component that displays a score and a concern level.  It can be rendered with a "large" or "small" view. Shell component. Generates props and passes them to View corresponding to the view prop.
 * @param concernLevel - concern level of the domain
 * @param domainDefId - domain definition id
 * @param score - score of the domain
 * @param view - "large" or "small"
 * @param rest - additional props passed to the wrapper
 * @returns {JSX.Element}
 */
const Gauge: React.FC<IGauge> = ({
  concernLevel,
  domainDefId,
  score,
  view,
  ...rest
}) => {
  const GaugeView = GaugeViewMap.get(view);
  const isScored = concernLevel !== ConcernLevel.NO_SCORE
  const viewProps: IGaugeView = {
    concernLevel,
    domainDefId,
    isScored,
    score,
  };
  return (
    <Box {...rest}>
      <GaugeView {...viewProps} />
    </Box>
  );
};

export default Gauge;
