import { pageGutter } from '../Page/props';
import headerSrc from '../../assets/header/domains.png';

export const pageHeadingText =
  'Take a quick test to measure your concerns about your child.';
export const headerImageProps = {
  src: headerSrc,
  w: 1125,
  h: 720,
  maxW: '320px',
  margin: 'auto',
};
const vGutter = pageGutter;
const standardHeadingPb = `calc(${vGutter}px + 11.048158615%)`;
export const headingProps = {
  bgColor: 'sand.300',
  px: `${pageGutter}px`,
  pt: `${vGutter}px`,
  pb: standardHeadingPb,
  textAlign: 'center' as 'center',
};
export const pageBodyProps = {
  maxW: '1065px',
};
