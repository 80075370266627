import Page from '../Page';
import { IPageView } from '../Page/PageView';
import { TAssessmentDomain } from '../../store/slices';

import PageBody from '../../components/PageBody';
import Questionnaire from '../../components/Questionnaire';

export interface IPageQuestionnaireView extends IPageView {
  domain: TAssessmentDomain;
  currentQuestionIdx: number;
}
const PageQuestionnaireView: React.FC<IPageQuestionnaireView> = ({
  loadedConditions,
  domain,
  currentQuestionIdx,
}) => {
  const pageProps = {
    loadedConditions,
    renderDeps: [currentQuestionIdx],
    pageTitle: 'Questionnaire',
  };

  return (
    <Page {...pageProps}>
      <PageBody>
        <Questionnaire {...{ domain }} />
      </PageBody>
    </Page>
  );
};

export default PageQuestionnaireView;
