import React from 'react';
import { getThemeColor } from '../../../../theme/_';
import { parseValue } from '../../../../global/helpers';

interface IFooterCap {
  bgColor: string;
}

/**
 * @constructor FooterCap
 * @description Renders a vector-based design flourish
 * @param bgColor
 * @constructor
 */
const FooterCap: React.FC<IFooterCap> = ({ bgColor }) => {
  const [colorName, colorValue = '800'] = bgColor.split('.');
  const colorHex = getThemeColor(colorName, parseValue(colorValue));
  return (
    <svg
      width="100%"
      // height="53"
      viewBox="0 0 1440 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M719.473 0C978.295 0 1222.8 19.0918 1439.26 53H0V52.9514C216.38 19.0735 460.774 0 719.473 0Z"
        fill={colorHex}
      />
    </svg>
  );
};

export default FooterCap;
