import Link from '../Link';
import { Box } from '@chakra-ui/react';
import { currentYear } from './helpers';
import { ICore } from '../../global/interfaces';
import React from 'react';
import {
  createFooterProps,
  linkProps,
  cellProps,
  copyrightCellProps,
} from './props';

interface IFooter extends ICore {}

export const testId = 'footer';
/**
 * @constructor Footer
 * @description Renders the App footer with links to the Privacy Policy, Terms & Conditions, and Contact Us, and displays the copyright.
 * @param dataTestId - data-testid attribute for Jest
 * @param rest - additional props passed to the wrapper
 * @returns {JSX.Element}
 */
export const Footer: React.FC<IFooter> = ({ dataTestId = testId, ...rest }) => {
  return (
    <Box {...createFooterProps(rest, dataTestId)}>
      <Box {...cellProps}>
        <Link href={'mailto:support@cadey.co'} {...linkProps}>
          Contact Us
        </Link>
      </Box>
      <Box {...cellProps}>
        <Link href={'https://clearchildpsychology.com/privacy/'} {...linkProps}>
          Privacy Policy
        </Link>
      </Box>
      <Box {...cellProps}>
        <Link
          href={'https://clearchildpsychology.com/TermsOfUse/'}
          {...linkProps}
        >
          Terms &amp; Conditions
        </Link>
      </Box>
      <Box {...cellProps} {...copyrightCellProps}>
        &copy;{currentYear} Cadey, Inc.
      </Box>
    </Box>
  );
};
export default Footer;
