import { TAssessmentItem } from '../types';
import { fnFilterCompletedAssessmentItems } from '../assessmentItemIterators';

export type TCompletionValues = {
  numberOfItems: number;
  numberOfItemsCompleted: number;
  assessmentItems: TAssessmentItem[];
};
// Output Bundler
type Sig_createCompletionValues = (
  ...params: number[] | TAssessmentItem[][]
) => TCompletionValues;

export const createCompletionValues: Sig_createCompletionValues = (
  ...params: number[] | TAssessmentItem[][]
) => {
  // Params are either (numberOfItems, numberOfItemsCompleted), (assessmentItems), or () / other
  const [param1, param2] = params;

  // DEFAULT - Params are () / other
  let numberOfItems = 0;
  let numberOfItemsCompleted = 0;
  let assessmentItems: TAssessmentItem[] = [];

  // Params are (numberOfItems, numberOfItemsCompleted),
  if (
    params.length === 2 &&
    typeof param1 === 'number' &&
    typeof param2 === 'number'
  ) {
    numberOfItems = param1;
    numberOfItemsCompleted = param2;
  }

  // Params are (assessmentItems)
  if (params.length === 1 && typeof param1 === 'object') {
    assessmentItems = param1;
    numberOfItems = assessmentItems.length;
    numberOfItemsCompleted = assessmentItems.filter(
      fnFilterCompletedAssessmentItems
    ).length;
  }

  return {
    numberOfItems,
    numberOfItemsCompleted,
    assessmentItems,
  };
};
