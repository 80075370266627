import { Slice } from '../../../../global/constants';
import { useInitDomains } from './_/useInitDomains';
import { useInitPersons } from './_/useInitPersons';
import { useInitAssessments } from './_/useInitAssessments';
import { useSubscribeAccountUpdate } from './_/useSubscribeAccountUpdate';
import { useSubscribePersonCreate } from './_/useSubscribePersonCreate';
import { useSubscribeQuestionnaire } from './_/useSubscribeQuestionnaire';
import { useAuthenticateUser } from './_/useAuthenticateUser';
import { useSubscribeAssessmentCreate } from './_/useSubscribeAssessmentCreate';
import { useSubscribePasswordUpdate } from './_/useSubscribePasswordUpdate';
import { useSlices } from '../../../../hooks/useSlices';
import { useSubscribeResults } from './_/useSubscribeResults';

// If the Flow has reached this point, then a Congito login has successfully occurred.
type HookSig = () => void;
/**
 * useAppAuthInit
 * @description This hook is responsible for initializing App State.  Each element of App State is broken into its own hook
 * @returns void
 */
export const useAppAuthInit: HookSig = () => {
  // Is user Authenticated against Cognito
  const [{ isUserAuthenticated }] = useSlices(Slice.SESSION);

  const isAccountInit = useAuthenticateUser(isUserAuthenticated);

  /** TODO: Distinguish between `personInit` and `personReady`.
   * `personInit` happens after the first check is made.
   * `personReady` happens when we actually have a person
   */

  const [isPersonsInit, isPersonsReady] = useInitPersons(isAccountInit);

  const isAssessmentsInit = useInitAssessments(
    isAccountInit,
    isPersonsInit,
    isPersonsReady
  );

  useInitDomains(isAssessmentsInit);
  useSubscribeAccountUpdate(isAccountInit);
  useSubscribePasswordUpdate(isAccountInit);
  useSubscribePersonCreate(isPersonsInit);
  useSubscribeAssessmentCreate(isAssessmentsInit);
  useSubscribeQuestionnaire(isAssessmentsInit);
  useSubscribeResults(isAssessmentsInit);
};

export default useAppAuthInit;
