import PageResultView, { IPageResultView } from './PageResultView';
import { useParams } from 'react-router-dom';
import { useSlices } from '../../hooks/useSlices';
import { ConcernLevel, Slice } from '../../global/constants';
import { useAssessmentDomain } from '../../store/slices/sliceAssessments/core/useAssessmentDomain';
import { TAssessmentItem } from '../../store/slices';
import { format } from 'date-fns';

const concernLevelWhitelist = [
  ConcernLevel.MILD,
  ConcernLevel.MODERATE,
  ConcernLevel.SIGNIFICANT,
];

const PageResult = () => {
  const { domainSlug } = useParams<{ domainSlug: string }>();
  const [
    { isInit: isSymptomsInit },
    { isReady: isAssessmentsReady },
    { downloadApp },
  ] = useSlices(Slice.SYMPTOMS, Slice.ASSESSMENTS, Slice.SESSION);

  const domain = useAssessmentDomain(domainSlug);
  if (!domain) return null;
  const {
    numPersonsCompletingDomain,
    domainVideoUrl,
    pctPersonsLikeMeAsText,
    lastModified,
    assessmentItems,
    domainDefId,
    displayName,
    score,
    concernLevel,
  } = domain;

  const loadedConditions: boolean[] = [isSymptomsInit, isAssessmentsReady];

  const symptomsData = (assessmentItems as TAssessmentItem[])
    .filter(({ responseId }) => concernLevelWhitelist.includes(responseId))
    .map(({ itemDefId: symptomId, responseId: concernId }) => ({
      symptomId,
      concernId,
    }))
    .sort((a, b) => b.concernId - a.concernId);

  const assessDate = !!lastModified
    ? format(new Date(lastModified), 'MMM d, yyyy')
    : '';

  // Assessed on Nov 30, 2022
  const viewProps: IPageResultView = {
    pctPersonsLikeMeAsText,
    assessDate,
    concernLevel,
    domainDefId,
    domainName: displayName,
    domainVideoUrl,
    loadedConditions,
    numPersonsCompletingDomain,
    score,
    symptomsData,
    downloadApp,
  };
  return <PageResultView {...viewProps} />;
};

export default PageResult;
