import GaugeChart from '../../Charts/GaugeChart';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Copy, H4 } from '../../Prose';
import { IconDomain } from '../../IconDomain';
import {
  contentAreaProps,
  copyProps as commonCopyProps,
  GaugeCopyMap,
  getIconSize,
  IGaugeView,
  wrapProps,
} from './common';

const copyProps = {
  ...commonCopyProps,
  textAlign: 'center' as 'center',
  display: 'block',
};

/**
 * @constructor GaugeViewSmall
 * @description A GaugeViewSmall is a component that displays a score and a concern level in a Small form factor.
 * @param concernLevel
 * @param domainDefId
 * @param isScored
 * @param score
 * @returns {JSX.Element}
 */
const GaugeViewSmall: React.FC<IGaugeView> = ({
  concernLevel,
  domainDefId,
  isScored,
  score,
}) => {
  const diameter = 80;

  const domainIconProps = {
    domainId: domainDefId,
    size: getIconSize(diameter),
  };

  const ringContent =
    isScored ? <H4>{score}</H4> : <IconDomain {...domainIconProps} />;

  const gaugeChartProps = {
    score,
    concernLevel,
    diameter,
  };

  return (
    <>
      <Box
        {...{
          ...wrapProps,
          mb: '17px',
        }}
      >
        <Box {...contentAreaProps}>{ringContent}</Box>
        <GaugeChart {...gaugeChartProps} />
      </Box>
      <Copy {...copyProps}>{GaugeCopyMap.get(concernLevel)}</Copy>
    </>
  );
};

export default GaugeViewSmall;
