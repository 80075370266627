import { getSideQuestPath } from '../../global/helpers';
import { TNullable } from '../../global/types';

export type TQuestSpec = {
  quest: string;
  shouldEmbark: boolean;
};

type TQuest = TQuestSpec & {
  path: string;
};

const createQuests = (specs: TQuestSpec[]): TQuest[] =>
  specs.map(({ quest, shouldEmbark }) => ({
    quest,
    shouldEmbark,
    path: getSideQuestPath(quest),
  }));

export const getCurrentQuestPath = (
  questSpecs: TQuestSpec[],
  completedQuest: TNullable<string> = null,
  finalDestination: string
): string => {
  // Build actual Quests from Specs array
  const quests = createQuests(questSpecs);

  // If a completed Quest was provided, find it in the array
  const completedQuestIdx = quests.findIndex(
    ({ quest }) => quest === completedQuest
  );

  // Filter all quests down to just those that have not been completed
  const validQuests =
    completedQuestIdx >= 0
      ? quests.filter(({}, questIdx) => questIdx > completedQuestIdx)
      : quests;

  // Scan embark conditions of valid Quests.  If a condition is found to be true, embark on that Quest
  return (
    validQuests.find(({ shouldEmbark }) => shouldEmbark)?.path ||
    finalDestination
  );
};
