import {
  uiErrorMsg,
  uiHeading,
  uiInput,
  uiLabel,
  uiOptInCheckbox,
  uiSubmit,
} from './ui';
import InputMask from 'react-input-mask';

export const getLabelProps = forInput => ({
  ...uiLabel,
  htmlFor: forInput,
});

export const getSubmitProps = isSubmitting => ({
  ...uiSubmit,
  isLoading: isSubmitting,
});

export const errorMsgProps = {
  ...uiErrorMsg,
};

export const getInputProps = (
  inputName,
  {
    type = 'text',
    placeholder = '',
    mask,
    maskChar = null,
    maskPlaceholder = null,
  }
) => ({
  ...uiInput,
  id: inputName,
  name: inputName,
  type,
  placeholder,
  ...(!!mask && {
    as: InputMask,
    mask,
    maskChar,
    maskPlaceholder,
  }),
});

export const getInputOptInProps = optInName => ({
  ...uiOptInCheckbox,
  id: optInName,
});

export const getPageProps = ({ title }) => ({
  pageTitle: title,
});

export const headingProps = { ...uiHeading };
