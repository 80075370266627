import { acSetCurrentQuestionIdx, acSetIsEditMode } from '../store';
import { useRedirect } from './useRedirect';
import { useDispatch } from 'react-redux';
import { getQuestionnairePath } from '../global/helpers';
import { useDomainMap } from './useDomainMap';

/**
 * @function useEditDomain
 * @description Hook to set the current question index to 0 and set isEditMode to true, then redirect to the questionnaire page for the given domain.
 * @returns {function} - Function that takes a domain ID and redirects to the questionnaire page for that domain.
 */
export const useEditDomain = () => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const DomainMap = useDomainMap();

  return (domainId: number) => {
    dispatch(acSetCurrentQuestionIdx(0));
    dispatch(acSetIsEditMode(true));
    redirect(getQuestionnairePath(DomainMap[domainId].slug));
  };
};
