import ResultsSubfooterView, {
  IResultsSubfooterView,
} from './ResultsSubfooterView';
import { useCurrentPerson } from '../../hooks/useCurrentPerson';
import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import { useAssessmentCompletion } from '../../store/slices/sliceAssessments/core/useAssessmentCompletion';
import { maybePlural } from '../../global/helpers';

/**
 * @constructor ResultsSubFooter
 * @description Renders the subfooter for the Results page.  Shell component. Consults with the Store, Generates props, and passes them to ResultsSubfooterView.
 * returns {JSX.Element}
 */
const ResultsSubFooter = () => {
  const [{ currentAssessmentId }] = useSlices(Slice.ASSESSMENTS);
  const { numSurveysIncomplete } = useAssessmentCompletion(currentAssessmentId);
  const currentPerson = useCurrentPerson();

  const firstName = currentPerson?.firstName || '';
  const isAssessmentComplete = numSurveysIncomplete === 0;

  const auxHeadingText = isAssessmentComplete ? 'Congrats!' : 'Keep Going!';
  const mainHeadingText = isAssessmentComplete
    ? 'You have completed all assessments.'
    : `You have ${numSurveysIncomplete} more ${maybePlural(
        numSurveysIncomplete,
        'area',
        'areas'
      )} to complete for ${firstName}.`;
  const buttonText = isAssessmentComplete
    ? 'Go to Profile'
    : 'Continue Assessment';

  const viewProps: IResultsSubfooterView = {
    auxHeadingText,
    mainHeadingText,
    buttonText,
  };
  return <ResultsSubfooterView {...viewProps} />;
};

export default ResultsSubFooter;
