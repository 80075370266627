import { useDispatch } from 'react-redux';
import { ConcernLevel, Slice } from '../../../global/constants';
import { useSelectedDomain } from '../../../hooks/useSelectedDomain';
import { useEffect } from 'react';
import {
  acSetAssessmentDomainId,
  acSetCurrentQuestionIdx,
} from '../../../store';
import { allTrue } from '../../../global/helpers';
import { useSlices } from '../../../hooks/useSlices';
import { TAssessment, TAssessmentDomain } from '../../../store/slices';
import { useAssessmentItemIterators } from '../../../store/slices/sliceAssessments/core/assessmentItemIterators';

type TUseCurrentQuestionnaire = (domainSlug?: string) => {
  domain?: TAssessmentDomain;
};

export const useCurrentQuestionnaire: TUseCurrentQuestionnaire = domainSlug => {
  const dispatch = useDispatch();

  // State Values
  const selectedDomain = useSelectedDomain(domainSlug);
  const { id: domainId } = selectedDomain;

  const [{ assessments = [], currentAssessmentId }] = useSlices(
    Slice.ASSESSMENTS
  );

  const assessment = (assessments as TAssessment[]).find(
    ({ id }) => id === currentAssessmentId
  );

  const assessmentDomains = assessment?.assessmentDomains || [];
  const assessmentDomain = assessmentDomains.find(
    ({ domainDefId }) => domainDefId === domainId
  );
  const assessmentItems = assessmentDomain?.assessmentItems || [];

  const { fnFilterVisibleItems } = useAssessmentItemIterators();
  const currentIdxRaw = assessmentItems
    .filter(fnFilterVisibleItems)
    .findIndex(({ responseId }) => responseId === ConcernLevel.NO_RESPONSE);
  const currentQuestionIdx = currentIdxRaw >= 0 ? currentIdxRaw : 0;

  useEffect(() => {
    const effectConditions = [!!domainId];

    if (allTrue(...effectConditions)) {
      dispatch(acSetAssessmentDomainId(domainId));
      dispatch(acSetCurrentQuestionIdx(currentQuestionIdx));
    }
  }, [domainId]);

  return {
    domain: assessmentDomain,
  };
};
