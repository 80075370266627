import { useAssessment } from './useAssessment';
import { ConcernLevel } from '../../../../global/constants';

type HookOutput = {
  numSurveysComplete: number;
  numSurveysIncomplete: number;
};
type HookSig = (assessmentId?: number) => HookOutput;

const createOutputBundle: (
  numSurveysComplete: number,
  numSurveysIncomplete: number
) => HookOutput = (numSurveysComplete, numSurveysIncomplete) => ({
  numSurveysComplete,
  numSurveysIncomplete,
});

export const useAssessmentCompletion: HookSig = assessmentId => {
  const assessment = useAssessment(assessmentId);
  if (!assessment) return createOutputBundle(0, 0);
  const { assessmentDomains } = assessment;

  const numSurveysComplete: number = assessmentDomains.filter(
    ({ assessmentItems }) =>
      assessmentItems.every(
        ({ responseId }) => responseId !== ConcernLevel.NO_RESPONSE
      )
  ).length;

  return createOutputBundle(
    numSurveysComplete,
    assessmentDomains.length - numSurveysComplete
  );
};
