import { LookupMap } from '../../global/LookupMap';
import { CadeDomainId } from '../../global/constants';
import { iconTypeFallback } from '../Icon/core/library';
import { IconType } from '../Icon/core/enum';

export const DomainToIconTypeMap = new LookupMap<string>(
  {
    [CadeDomainId.UNDERSTANDING]: IconType.LIGHT_BULB,
    [CadeDomainId.LEARNING]: IconType.GRADUATION_CAP,
    [CadeDomainId.COMMUNICATING]: IconType.BULLHORN,
    [CadeDomainId.SOCIALIZING]: IconType.CHAT_BUBBLES,
    [CadeDomainId.DAILY_LIVING]: IconType.TOOTHBRUSH,
    [CadeDomainId.MOVING_SENSING]: IconType.SWIRLY_ARROWS,
    [CadeDomainId.FOCUSING]: IconType.BULLSEYE,
    [CadeDomainId.REMEMBERING]: IconType.BOOKMARK,
    [CadeDomainId.ORGANIZING]: IconType.PAPERCLIP,
    [CadeDomainId.FEELING]: IconType.HEART,
    [CadeDomainId.BEHAVING]: IconType.BROKEN_RULER,
    [CadeDomainId.INATTENTION_IMPULSIVITY]: IconType.EKG,
    [CadeDomainId.STRESS_WORRY]: IconType.STRESSED_FACE,
    [CadeDomainId.SADNESS_ANGER]: IconType.RAIN_CLOUD,
    [CadeDomainId.LEARNING_ACADEMIC]: IconType.GRADUATION_CAP,
    [CadeDomainId.SOCIAL_COMMUNICATION]: IconType.CHAT_BUBBLES,
    [CadeDomainId.DAILY_LIFE]: IconType.TOOTHBRUSH,
  },
  iconTypeFallback
);
