import React from 'react';
import { Button as CButton } from '@chakra-ui/react';
import { ButtonContent } from './core/ui';
import { createVariantProps } from './core/props';
import { ICore } from '../../global/interfaces';
import { createComponentProps } from '../../global/helpers';
import { Icon } from '../Icon';
import { getTypography } from '../../theme/_';
import { TGenericObject } from '../../global/types';

export enum ButtonKind {
  CTA = 'cta',
  UTILITY = 'utility',
}
const ButtonKindUI: TGenericObject = {
  [ButtonKind.CTA]: {},
  [ButtonKind.UTILITY]: {
    ...getTypography('copy'),
    minW: '90px',
    w: 'auto',
    h: '30px',
  },
};

interface IButton extends ICore {
  dir?: string;
  children?: React.ReactNode;
  colorSpace?: string;
  colorVal?: number;
  icon?: string;
  isDisabled?: boolean;
  isEnabled?: boolean;
  variant?: string;
  kind?: string;
  interiorStyles?: {
    [key: string]: string | number | { [key: string]: string | number };
  };
}

/**
 * @function treatContents
 * @description Applies visual treatment to incoming content under certain conditions.
 * @param contents
 * @param iconType
 * @param direction
 *
 */
const treatContents = (
  contents?: React.ReactNode,
  iconType?: string,
  direction = 'default'
) => (
  <>
    {!!iconType && <Icon type={iconType} />}
    {typeof contents === 'string'
      ? ButtonContent[direction](contents)
      : contents}
  </>
);

export const testId = 'button';
/**
 * @constructor Button
 * @description Button component. Template component for rendering all Buttons.
 * @param direction
 * @param contents
 * @param iconType
 * @param dataTestId
 * @param colorSpace
 * @param colorVal
 * @param isDisabled
 * @param isEnabled
 * @param interiorStyles
 * @param kind
 * @param rest
 * @returns {JSX.Element}
 */
export const Button: React.FC<IButton> = ({
  dir: direction = 'default',
  children: contents,
  icon: iconType,
  dataTestId = testId,
  colorSpace = 'green',
  colorVal = 800,
  isDisabled = false,
  isEnabled = !isDisabled,
  interiorStyles = {},
  kind = ButtonKind.CTA,
  ...rest
}) => {
  // TODO: default variant to `primary`.  Currently, doing so causes unpredictable button color change
  const { variant = 'solid' } = rest;
  const buttonProps = createComponentProps(dataTestId, {
    width: '100%',
    position: 'relative',
    isDisabled: !isEnabled,
    borderRadius: '1000px',
    children: treatContents(contents, iconType, direction),
    sx: {
      '*': {
        pointerEvents: 'none',
      },
      ...interiorStyles,
    },
    ...(createVariantProps(colorSpace, colorVal)[variant] || {}),
    ...ButtonKindUI[kind],
    ...rest,
  });

  // accepts Direction prop.  If `forward` or `backward`, presents button text with direction arrow.

  return <CButton {...buttonProps} />;
};
export default Button;
