import { Slice } from '../global/constants';
import { useSlices } from './useSlices';
import { TPerson } from '../store/slices';

/**
 * @function useCurrentPerson
 * @description Hook to get the current person from the Persons slice
 */
export const useCurrentPerson = () => {
  const [{ currentPersonId, persons }] = useSlices(Slice.PERSONS);
  return (persons as TPerson[]).find(person => person.id === currentPersonId);
};
