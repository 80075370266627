// import { StripeRoute, useCadeyStripe } from '../../hooks/useCadeyStripe';
// manageSubscriptionCallback: () => stripePost(StripeRoute.PORTAL),
// const { stripePost } = useCadeyStripe();
// isSubscribed

import React, { useCallback, useMemo } from 'react';
import AppHeaderView, { IAppHeaderView } from './AppHeaderView/AppHeaderView';
import { allTrue, getDomainsPath, getRootPath } from '../../global/helpers';
import { useSlices } from '../../hooks/useSlices';
import { Slice, UserActivity, UserStatus } from '../../global/constants';
import { useDispatch } from 'react-redux';
import { createAccountAPIInteractions } from '../../store';
import { IconType } from '../Icon/core/enum';
import { useMenuState } from '../../contexts/MenuMainContext';

/**
 * @constructor AppHeader
 * @description AppHeader shell component. Interacts with the Store and provides the view with the necessary props.
 * @param rest
 * @returns {JSX.Element}
 */
const AppHeader = ({ ...rest }) => {
  const dispatch = useDispatch();
  const [{ isUserAuthenticated, userStatus }, { id: accountId }] = useSlices(
    Slice.SESSION,
    Slice.ACCOUNT
  );
  const { isMenuOpen, setIsMenuOpen } = useMenuState();

  const { acLogUserActivity } = createAccountAPIInteractions(accountId);

  const onMenuButtonClick = useCallback(() => {
    dispatch(acLogUserActivity(UserActivity.CLICK_MAIN_MENU));
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const homePath = useMemo(
    () =>
      allTrue(isUserAuthenticated, userStatus === UserStatus.PERMANENT)
        ? getDomainsPath()
        : getRootPath(),
    [isUserAuthenticated, userStatus]
  );

  const menuIconType = isMenuOpen ? IconType.CLOSE : IconType.HAMBURGER;
  const viewProps: IAppHeaderView = {
    homePath,
    isMenuOpen,
    isUserAuthenticated,
    menuButtonProps: { onClick: onMenuButtonClick, iconType: menuIconType },
    ...rest,
  };
  return <AppHeaderView {...viewProps} />;
};

export default AppHeader;
