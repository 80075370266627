import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { acResetState } from '../store';
import { useRedirect } from './useRedirect';

type HookSig = () => (redirectPath?: string) => Promise<void>;

/**
 * @function useSignOut
 * @description Hook to sign out the user and reset the redux store.
 * @returns {function} - Function that takes an optional redirect path and signs out the user, then redirects to the given path.  If no path is given, it redirects to the home page.
 */
export const useSignOut: HookSig = () => {
  const dispatch = useDispatch();
  const redirect = useRedirect();

  //TODO: Rework this with an async IIFE to abstract away the async from client code.
  return async (redirectPath = '/') => {
    await Auth.signOut();
    dispatch(acResetState());
    redirect(redirectPath);
  };
};
