import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createAccountAPIInteractions } from '../../../../../store';
import { allTrue } from '../../../../../global/helpers';
import { Slice } from '../../../../../global/constants';
import { useSlices } from '../../../../../hooks/useSlices';

type HookSig = (isAccountInit: boolean) => boolean[];

/**
 * useInitPersons
 * @description This hook is responsible for initializing the Persons slice of the Global State.
 * @param isAccountInit
 * @returns [isPersonsInit, isPersonReady
 */
export const useInitPersons: HookSig = isAccountInit => {
  const dispatch = useDispatch();

  // Retrieve Persons info from Global State
  const [{ id: accountId }, { isInit: isPersonsInit, isReady: isPersonReady }] =
    useSlices(Slice.ACCOUNT, Slice.PERSONS);

  const { acInitPersons } = createAccountAPIInteractions(accountId);

  // If session is ready, then we are free to hit the API.  Get Domain Definitions
  useEffect(() => {
    // Session and Account are initialized, but no Persons are yet populated
    const effectConditions = [isAccountInit, !!accountId];
    if (allTrue(...effectConditions)) {
      dispatch(acInitPersons());
    }
  }, [isAccountInit, accountId]);
  return [isPersonsInit, isPersonReady];
};
