import { Slice } from '../../../../../global/constants';
import { TAssessment, TAssessmentDomain, TDomain } from '../../../index';
import { createCompletionValues, TCompletionValues } from './props';
import { useAssessmentItemIterators } from '../assessmentItemIterators';
import { useSlices } from '../../../../../hooks/useSlices';

type hookSig = () => (domainId?: number) => TCompletionValues;

/**
 * @function useGetVisibleSurveyProps
 *
 */
export const useGetVisibleSurveyProps: hookSig = () => {
  const { fnFilterVisibleItems } = useAssessmentItemIterators();

  const [
    { domains: domainsDef, isInit: isDomainsInit },
    { currentAssessmentId, assessments },
  ] = useSlices(Slice.DOMAINS, Slice.ASSESSMENTS);

  const assessment = (assessments as TAssessment[]).find(
    ({ id }) => id === currentAssessmentId
  );

  return domainId => {
    // Return the Null completion values.  0's and empty array
    if (!isDomainsInit || !domainId) return createCompletionValues();

    // Normalize `domain`.  End up with a `domain` object that is type `TDomain` or `TAssessmentDomain`
    const assessmentDomains = assessment?.assessmentDomains || null;
    const domains = assessmentDomains || domainsDef;
    const domain: TDomain | TAssessmentDomain = domains.find(
      ({ domainDefId }: { domainDefId: number }) => domainDefId === domainId
    );

    // If domain object is `TDomain`
    if (!('assessmentItems' in domain))
      return createCompletionValues(
        domain.numberOfItems!,
        domain.numberOfItemsCompleted!
      );

    // If domain object is `TAssessmentDomain`
    return createCompletionValues(
      domain.assessmentItems.filter(fnFilterVisibleItems)
    );
  };
};
