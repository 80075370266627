import { useCurrentPerson } from '../../hooks/useCurrentPerson';
import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import PageResultsView from './PageResultsView';

const PageResults = () => {
  // const [{ hasPaywall }] = useSlices(Slice.SESSION);
  const [{ isInit: isResultsInit, isReady: isResultsReady }] = useSlices(
    Slice.RESULTS
  );
  const currentPerson = useCurrentPerson();
  const firstName = currentPerson?.firstName || '';

  // TODO: Create context to handle global loaded conditions.  Need to lift `isSubscribed` to AppAuthenticated level
  // ^^^^^^  I did this.  It's in a hackathon `load-state-context`.

  // TODO: Merge hackathon branch `load-state-context` into regular codebase

  const loadedConditions = [
    !!firstName,
    isResultsInit,
    isResultsReady,
    //!hasPaywall || isSubscribed,
  ];

  const viewProps = {
    firstName,
    loadedConditions,
  };
  return <PageResultsView {...viewProps} />;
};

export default PageResults;
