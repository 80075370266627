import api from '../../../../services/api/api';
import produce from 'immer';
import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Slice } from '../../../../global/constants';
import { TAssessment, TAssessmentItem, TAssessmentScoreItem } from './types';
import { TNullable } from '../../../../global/types';
import { acRefreshResults } from '../../../store';
import { maybeGetResponseData } from '../../../../services/api/core/maybeGetResponseData';
import { prepareAssessmentDomain } from '../../sliceDomains/core/helpers';





type TInitCadeAssessments_Output = {
  assessments: TAssessment[];
  currentAssessmentId: TNullable<number>;
};

type TInitCadeAssessments_Input = {
  accountId: number;
  personId: TNullable<number>;
  isPersonReady: boolean;
};


const prepareAssessments = (assessments: TAssessment[]) => produce(assessments, (newAssessments: TAssessment[]) => {
  for (const assessment of newAssessments) {
    const {assessmentDomains} = assessment
    assessment.assessmentDomains = assessmentDomains.map(prepareAssessmentDomain)
  }
})

const nullAssessmentsOutput: TInitCadeAssessments_Output = { assessments: [], currentAssessmentId: null }
export const initCadeAssessments = createAsyncThunk<
  TInitCadeAssessments_Output,
  TInitCadeAssessments_Input
>(
  `${Slice.ASSESSMENTS}/get`,
  async ({ accountId, personId = null, isPersonReady }) => {

    if (!isPersonReady)
      return nullAssessmentsOutput;

    const assessmentsResponse = await api.get(
      `account/${accountId}/person/${personId}/assessments`
    );
    const assessments: TAssessment[] = prepareAssessments(maybeGetResponseData(assessmentsResponse) || []);


    if (!assessments.length || !personId)
      return nullAssessmentsOutput;

    const currentAssessment = (assessments as TAssessment[]).find(
      ({ id }) => id === personId
    );
    const [firstAssessment = { id: null }] = assessments;
    const currentAssessmentId = !!currentAssessment
      ? currentAssessment.id
      : firstAssessment.id;

    return { assessments, currentAssessmentId };
  }
);

type TCreateCadeAssessment_Output = TNullable<{ assessment: TAssessment }>;
type TCreateCadeAssessment_Input = { accountId: number; personId: number };
export const createCadeAssessment = createAsyncThunk<
  TCreateCadeAssessment_Output,
  TCreateCadeAssessment_Input
>(`${Slice.ASSESSMENTS}/create`, async ({ accountId, personId }) => {
  if (!accountId || !personId) return null;

  const assessmentResponse = await api.post(
    `account/${accountId}/person/${personId}/assessment`
  );
  const assessments = prepareAssessments([maybeGetResponseData(assessmentResponse) || null]);
  const [assessment] = assessments
  return { assessment };
});

type TGetDomainAssessmentItems_Output = {
  domainId: number;
  items: TAssessmentItem[];
};
type TGetDomainAssessmentItems_Input = {
  domainId: number;
  assessmentId: number;
};
export const getDomainAssessmentItems = createAsyncThunk<
  TGetDomainAssessmentItems_Output,
  TGetDomainAssessmentItems_Input
>(`${Slice.ASSESSMENTS}/get/items`, async ({ domainId, assessmentId }) => {
  const assessmentItemsResponse = await api.get(
    `assessment/${assessmentId}/domain/${domainId}/sorttype/display/items`
  );

  const items = (await maybeGetResponseData(assessmentItemsResponse)) || [];

  return { domainId, items };
});

type TSendRetort_Output = {
  itemDefId: number;
  domainId: number;
  numberOfItemsCompleted: number;
  responseId: number;
};
type TSendRetort_Input = {
  assessmentId: number;
  assessmentItemId: number;
  domainId: number;
  itemDefId: number;
  responseId: number;
};
export const sendRetort = createAsyncThunk<
  TSendRetort_Output,
  TSendRetort_Input
>(
  `${Slice.ASSESSMENTS}/retort/send`,
  async (
    { assessmentId, assessmentItemId, domainId, itemDefId, responseId },
    { dispatch }
  ) => {
    // This post does not have a response
    const response = await api.post(`assessment/${assessmentId}/item`, {
      id: assessmentItemId,
      itemDefId, // TODO: Swagger says optional. Seems to be Required.  Check with Ron.
      responseId,
    });

    const {
      numberOfItemsCompleted,
      numberOfTotalItems,
    }: { numberOfItemsCompleted: number; numberOfTotalItems: number } =
      maybeGetResponseData(response) || {};

    // If Questionnaire is now complete, then refresh the Feed
    const completionRatio = numberOfItemsCompleted / numberOfTotalItems;
    if (completionRatio === 1) {
      // Delay refresh of Feed to avoid race condition with Assessmsent completion
      setTimeout(() => {
        dispatch(acRefreshResults() as AnyAction);
      }, 1000);
    }
    // Don't have to worry about checking for 200.  Thunk API handles that.
    return { domainId, itemDefId, responseId, numberOfItemsCompleted };
  }
);

type TGetAssessmentResults_Output = {
  assessmentId: number;
  scores: TAssessmentScoreItem[];
};
type TGetAssessmentResults_Input = { assessmentId: number };
export const getAssessmentResults = createAsyncThunk<
  TGetAssessmentResults_Output,
  TGetAssessmentResults_Input
>(`${Slice.ASSESSMENTS}/get/scores`, async ({ assessmentId }) => {
  const assessmentScoresResponse = await api.get(
    `assessment/${assessmentId}/scores`
  );
  const scores = maybeGetResponseData(assessmentScoresResponse) || [];

  return { assessmentId, scores };
});
