import { navHeight } from '../../global/constants';
import { authenticatorStyles } from '../../theme/amplify';
import { IconType } from '../Icon/core/enum';

const buttonGutter = `34px`;
const buttonHW = '45px';
export const wrapProps = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  m: 'auto',
  minH: '100vh',
  overflow: 'hidden',
  position: 'relative' as 'relative',
  pt: `${navHeight}px`,
  sx: { ...authenticatorStyles },
};

export const backToTopProps = {
  borderColor: 'light.900',
  borderWidth: '2px',
  bottom: buttonGutter,
  colorSpace: 'green',
  h: buttonHW,
  position: 'fixed',
  right: buttonGutter,
  visibilityThreshold: 100,
  w: buttonHW,
  zIndex: 'sticky',
};

export const iconProps = {
  type: IconType.ARROW_UP,
};

export const pageAreaProps = {
  display: 'flex',
  flex: 1,
};

export const footerAreaProps = {
  flex: 0,
};
