import React, { createContext, useContext, useEffect } from 'react';
import { useSlices } from '../../hooks/useSlices';
import { nullEffect, Slice, UserStatus } from '../../global/constants';
import { hotjar } from 'react-hotjar';
import { allTrue, parseValue } from '../../global/helpers';
import { usePrevious } from '@chakra-ui/react';

export enum HotjarEvent {
  REGISTRATION = 'Registration',
  VIDEO_IN_FEED = 'Video Feed',
  VIDEO_PLAY = 'Video Play',
  NO_VIDEO_IN_FEED = 'Non-video Feed',
  REACH_PAYWALL = 'Reach Paywall',
  BEGIN_CHECKOUT = 'Begin Checkout',
  RESULTS_RETRIEVED = 'Results Retrieved',
  BEGIN_TRIAL = 'Begin Trial',
  COURSES_CLICK = 'Courses Click',
}

export type TTriggerHotjarEvent = (eventName: string) => void;
type THotjarOutput = {
  triggerHotjarEvent: TTriggerHotjarEvent;
};

const triggerHotjarEvent: TTriggerHotjarEvent = eventName => {
  try {
    hotjar.event(eventName);
    console.log(`triggering Hotjar event:`, eventName);
  } catch (e) {
    console.error('hotjar call failed', e);
  }
};

interface IHotjarContext extends THotjarOutput {}
type TUserAttributes = {
  userStatus?: string;
};

/**
 * HotjarContext
 * @description Context for Hotjar Interactions.  Allows for triggering Hotjar events.
 */
export const HotjarContext = createContext<IHotjarContext>({
  triggerHotjarEvent: (eventName: string) => {
    console.log(
      `required default function for ${eventName} -- This will be overwritten`
    );
  },
});

/**
 * HotjarProvider
 * @description Provides HotjarContext to children
 * @param children
 * @returns {JSX.Element}
 */
const HotjarProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ userStatus }, { username: userId }] = useSlices(
    Slice.SESSION,
    Slice.ACCOUNT
  );

  const previousUserStatus = usePrevious(userStatus);

  // Hotjar Init
  useEffect(() => {
    const { REACT_APP_HOTJAR_ID: hjid, REACT_APP_HOTJAR_SCRIPT_VERSION: hjsv } =
      process.env;

    // console.log('initializing Hotjar:', hjid, hjsv);
    hotjar.initialize(parseValue(hjid), parseValue(hjsv));
  }, []);

  // Hotjar Subscribe
  useEffect(() => {
    // If no user exists, abandon ship
    if (!userId) return nullEffect;

    // Determine User and Session Properties
    const userAttributes: TUserAttributes = {
      userStatus: UserStatus[userStatus],
    };
    const isRegistration = allTrue(
      userStatus === UserStatus.PERMANENT,
      previousUserStatus === UserStatus.TRIAL
    );

    // Maybe trigger `Registration` event
    if (isRegistration) {
      triggerHotjarEvent(HotjarEvent.REGISTRATION);
    }

    // Push user attributes
    console.log('Sending Hotjar User Attributes:', userAttributes);
    hotjar.identify(userId, userAttributes);
  }, [userStatus, userId]);

  const value: THotjarOutput = {
    triggerHotjarEvent,
  };

  return (
    <HotjarContext.Provider {...{ value }}>{children}</HotjarContext.Provider>
  );
};

/**
 * @function useTriggerHotjarEvent
 * @description Hook for interacting with Context and triggering events
 */
export const useTriggerHotjarEvent = () => {
  const { triggerHotjarEvent } = useContext(HotjarContext);
  return triggerHotjarEvent;
};

export default HotjarProvider;
