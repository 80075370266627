import DomainListView from './DomainListView';
import React from 'react';
import { Slice } from '../../global/constants';
import { TAssessmentDomain } from '../../store/slices';
import { createItemProps, createListProps } from './props';
import { useContainerQuery } from '../QueryContainer';
import { useSlices } from '../../hooks/useSlices';
import { useGetVisibleSurveyProps } from '../../store/slices/sliceAssessments/core/useGetVisibleSurveyProps';
import { useAssessment } from '../../store/slices/sliceAssessments/core/useAssessment';

interface IDomainList {}

/**
 * @constructor DomainList
 * @description Renders a list of Domain Cards.  Each Card provides a link to a Survey for that Domain. Shell component. Generates props and passes them to DomainListView
 * @returns {JSX.Element}
 */
const DomainList: React.FC<IDomainList> = () => {
  const { getUIStyles } = useContainerQuery();
  const getVisibleSurveyProps = useGetVisibleSurveyProps();
  const [{ domains: domainsDef }] = useSlices(Slice.DOMAINS);
  const currentAssessment = useAssessment();
  const domainsAssessment = currentAssessment?.assessmentDomains;

  const domainsRaw = !!domainsAssessment ? domainsDef : domainsDef;
  const domains = (domainsRaw as TAssessmentDomain[]).map(domain => ({
    ...domain,
    ...getVisibleSurveyProps(domain.domainDefId),
  }));

  const listProps = createListProps(getUIStyles);
  const itemProps = createItemProps(getUIStyles);

  const viewProps = {
    domains,
    listProps,
    itemProps,
  };

  return <DomainListView {...viewProps} />;
};

export default DomainList;
