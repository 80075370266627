import { Quest } from '../../quests/core/data';
import { Slice } from '../../global/constants';
import { acAccountSetEmailTemp } from '../../store';
import { useCurrentPerson } from '../../hooks/useCurrentPerson';
import { useResultsRoute } from '../../quests/useResultsRoute';
import { useSlices } from '../../hooks/useSlices';
import PageRequestEmailView, {
  IPageRequestEmailView,
} from './PageRequestEmailView';

const PageRequestEmail = () => {
  const currentPerson = useCurrentPerson();
  const [{ id: accountId }] = useSlices(Slice.ACCOUNT);

  const firstName = currentPerson?.firstName || '';
  const nextRoute = useResultsRoute(Quest.EMAIL);
  const viewProps: IPageRequestEmailView = {
    accountId,
    firstName,
    acMissingInfoUpdated: acAccountSetEmailTemp,
    nextRoute,
  };
  return <PageRequestEmailView {...viewProps} />;
};

export default PageRequestEmail;
