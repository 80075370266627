import { Slice } from '../../../global/constants';
import { createSlice } from '@reduxjs/toolkit';

export const sliceCore = createSlice({
  name: Slice.CORE,
  initialState: {},
  reducers: {
    resetState: () => {},
  },
});
