import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { acUpdatePassword } from '../../../../../store';
import { Slice, UserStatus } from '../../../../../global/constants';
import { allTrue } from '../../../../../global/helpers';
import { useSlices } from '../../../../../hooks/useSlices';

type HookSig = (isAccountInit: boolean) => void;
/**
 * useSubscribePasswordUpdate
 * @description Subscription for refreshing Account password data
 * @param isAccountInit
 * @returns void
 */
export const useSubscribePasswordUpdate: HookSig = isAccountInit => {
  const dispatch = useDispatch();

  const [
    { userStatus },
    {
      tempPassword: oldPassword,
      tempData: { newPassword },
    },
  ] = useSlices(Slice.SESSION, Slice.ACCOUNT);

  useEffect(() => {
    // Boolean conditions for triggering an Account update
    const effectConditions = [
      isAccountInit,
      userStatus === UserStatus.TRIAL,
      !!oldPassword,
      !!newPassword,
    ];

    if (allTrue(...effectConditions)) {
      dispatch(acUpdatePassword(oldPassword, newPassword));
    }
  }, [isAccountInit, userStatus, oldPassword, newPassword]);
};
