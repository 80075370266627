import React from 'react';
import { MenuMainView, IMenuMainView } from './MenuMainView';
import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import { useMenuState } from '../../contexts/MenuMainContext';

export interface ISymptomDatum {}

/**
 * @constructor MenuMain
 * @description Renders the main menu.  Shell component. Generates props and passes them to MenuMainView.
 * @param rest
 * @constructor
 */
export const MenuMain: React.FC<ISymptomDatum> = ({ ...rest }) => {
  const [{ isUserAuthenticated }] = useSlices(Slice.SESSION);
  const { setIsMenuOpen } = useMenuState();
  const onMenuItemClick = () => {
    setIsMenuOpen(false);
  };
  const viewProps: IMenuMainView = {
    isLoggedIn: isUserAuthenticated,
    onMenuItemClick,
    ...rest,
  };

  return <MenuMainView {...viewProps} />;
};

export default MenuMain;
