// import '../../wdyr';
import AppView from './AppView';
import ButtonScrollToTop from '../ButtonScrollTo';
import Icon from '../Icon';
import StatusTimeout from './_/StatusTimeout';
import useAppInit from './hooks/useAppInit';
import { Slice } from '../../global/constants';
import { acSetIsAuthZone } from '../../store';
import { backToTopProps, iconProps } from './props';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSlices } from '../../hooks/useSlices';

/**
 * @constructor App
 * @description Application root shell component.  Serves as router for non-authenticated routes.
 * @returns {JSX.Element}
 */
export const App = () => {
  const dispatch = useDispatch();
  useAppInit();

  const [{ isTimeout: isSessionTimeout }, { isEditMode }] = useSlices(
    Slice.SESSION,
    Slice.QUESTIONNAIRE
  );

  useEffect(() => {
    dispatch(acSetIsAuthZone(false));
  }, []);

  if (isSessionTimeout) return <StatusTimeout />;

  return (
    <>
      <AppView />
      {!isEditMode && (
        <ButtonScrollToTop {...backToTopProps}>
          <Icon {...iconProps} />
        </ButtonScrollToTop>
      )}
    </>
  );
};

export default App;


