import { uiCell, uiNavMain } from './ui';

export const createContainerProps = rest => ({
  ...uiNavMain,
  ...rest,
});

export const cellPropsA = {
  ...uiCell,
  justifyContent: 'flex-start',
};
export const cellPropsB = {
  ...uiCell,
  flex: '0 0 70px',
  justifyContent: 'center',
};
export const cellPropsC = {
  ...uiCell,
  justifyContent: 'flex-end',
};
export const createLogoLinkProps = homePath => ({
  to: homePath,
  display: 'block',
  width: '100%',
});

const createVisibilityProps = isVisible => ({
  transition: 'opacity 500ms',
  display: isVisible ? 'block' : 'none',
  opacity: isVisible ? 1 : 0,
});
const createCommonButtonProps = isNavHardwareVisible => ({
  variant: 'unstyled',
  ...createVisibilityProps(isNavHardwareVisible),
});

export const createButtonLogoutProps = isNavHardwareVisible => ({
  ...createCommonButtonProps(isNavHardwareVisible),
  w: 'auto',
  variant: 'outline',
  children: 'Logout',
  colorSpace: 'green',
});

export const createButtonMyPlanProps = (isNavHardwareVisible, onAction) => ({
  ...createCommonButtonProps(isNavHardwareVisible),
  onClick: onAction,
  display: 'flex',
  flex: '0 0 100px',
  justifyContent: 'flex-end',
});
export const createButtonMenuProps = (isNavHardwareVisible, onAction) => ({
  ...createCommonButtonProps(isNavHardwareVisible),
  onClick: onAction,
  width: '32px',
});
