import axios from 'axios';
import { Auth } from 'aws-amplify';
import { retrieveAuthTokens } from './core/retrieveAuthTokens';

// accessToken will always be in `localStorage`.
// Session initialization adds tokens to `localStorage` if they do not exist
// API can not be reached until Session is initialized

const AuthType = {
  TOKEN: 0,
  API_KEY: 1,
};

const buildAPI = (authType = AuthType.TOKEN) => {
  const { idToken } = retrieveAuthTokens(Auth);

  const headers = {
    'Access-Control-Allow-Origin': process.env.REACT_APP_API_ACCESS_CONTROL,
    'Content-Type': 'application/json',
    ...(authType === AuthType.TOKEN
      ? { Authorization: `Bearer ${idToken}` }
      : {}),
  };

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL_CADE,
    timeout: 8000,
    headers,
  });
};

// adjust base params based on Authentication Type
const treatParams = (params: any, authType = AuthType.TOKEN) => {
  // Badges
  const { idToken } = retrieveAuthTokens(Auth);
  const { REACT_APP_API_KEY_CADEY: apiKey } = process.env;

  // Map Auth Types to Auth Badges
  const Badge = {
    [AuthType.TOKEN]: { idToken },
    [AuthType.API_KEY]: { apiKey },
  };

  return { ...params, ...Badge[authType] };
};

const api = {
  get: (url: string, config = {}) => buildAPI().get(url, treatParams(config)),
  post: (url: string, data = {}) => buildAPI().post(url, treatParams(data)),
  delete: (url: string, data = {}) => buildAPI().delete(url, treatParams(data)),
};
export const keyApi = {
  get: (url: string, config = {}) =>
    buildAPI(AuthType.API_KEY).get(url, treatParams(config, AuthType.API_KEY)),
  post: (url: string, data = {}) =>
    buildAPI(AuthType.API_KEY).post(url, treatParams(data, AuthType.API_KEY)),
};

export default api;
