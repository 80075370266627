import { configureStore } from '@reduxjs/toolkit';
import { TGenericObject } from '../global/types';
import {
  authenticateCadeUser,
  createCadeAssessment,
  createCadePerson,
  getCadeAccount,
  getCadeDomains,
  getQuestionnaire,
  getResults,
  initCadeAssessments,
  initCadePersons,
  logUserActivity,
  performTemporaryLogin,
  postAdditionalData,
  postCadeAccountInfo,
  sendRetort,
  setWaitlistOptInStatus,
  sliceAccount,
  sliceAssessments,
  sliceCore,
  sliceDomains,
  slicePersons,
  sliceQuestionnaire,
  sliceResults,
  sliceSession,
  sliceSymptoms,
  TAdditionalData,
  TAssessment,
  updatePassword,
} from './slices';

export type TGenericActionCreator = (...args: any[]) => TGenericObject;
export type TAction = TGenericObject;

//region - Core
/********************
 ******* CORE *******
 ********************/

// Reducers
export const acResetState: TGenericActionCreator = () =>
  sliceCore.actions.resetState();
//endregion

//region - Account
/********************
 * Account          *
 ********************/

export const acAccountTempNameUpdated: (firstName: string) => TAction =
  firstName => {
    // @ts-ignore
    return sliceAccount.actions.tempNameUpdated({ firstName });
  };

// Reducers
export const acAccountSetEmailTemp: (
  email: string,
  isEmailOptin?: boolean
) => TAction = (email, isEmailOptin) => {
  // @ts-ignore
  return sliceAccount.actions.tempEmailUpdated({ email, isEmailOptin });
};

export const acAccountSetNewPassword: (newPassword: string) => TAction =
  newPassword => {
    // @ts-ignore
    return sliceAccount.actions.tempNewPasswordUpdated({ newPassword });
  };
type TPersonInteractions = {
  acInitAssessments: TGenericActionCreator;
  acCreateAssessment: TGenericActionCreator;
};
type TAccountAPIInteractions = {
  acGetAccount: TGenericActionCreator;
  acAccountConvert: (
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    personalizedEmailOptIn: boolean
  ) => TAction;
  acInitPersons: TGenericActionCreator;
  acCreatePerson: (firstName: string, age: number) => TAction;
  acLogUserActivity: TGenericActionCreator;
  acPostAdditionalData: (additionalData: TAdditionalData) => TAction;
  acSetWaitlistOptInStatus: (isOptedIn: boolean) => TAction;
  createPersonInteractions: (personId: number) => TPersonInteractions;
};
type TCreateAccountAPIInteractions = (
  accountId: number
) => TAccountAPIInteractions;

export const createAccountAPIInteractions: TCreateAccountAPIInteractions =
  accountId => ({
    acGetAccount: () => getCadeAccount({ accountId }),
    acAccountConvert: (
      username,
      firstName,
      lastName,
      email,
      personalizedEmailOptIn
    ) =>
      postCadeAccountInfo({
        id: accountId,
        username,
        firstName,
        lastName,
        email,
        personalizedEmailOptIn,
      }),
    acInitPersons: () => {
      // @ts-expect-error Expected 1 arguments, but got 0.ts(2554)
      return initCadePersons({ accountId });
    },
    acLogUserActivity: (activity: string) =>
      logUserActivity({ accountId, activity }),
    acPostAdditionalData: additionalData =>
      postAdditionalData({ accountId, additionalData }),
    acCreatePerson: (firstName, age) =>
      createCadePerson({ accountId, firstName, age }),

    acSetWaitlistOptInStatus: isOptedIn =>
      setWaitlistOptInStatus({ accountId, isOptedIn }),

    createPersonInteractions: personId => ({
      acInitAssessments: isPersonReady =>
        initCadeAssessments({ accountId, personId, isPersonReady }),
      acCreateAssessment: () => createCadeAssessment({ accountId, personId }),
    }),
  });

//endregion

//region - Assessments
/********************
 * Assessments      *
 ********************/

// Reducers
export const acSetAssessment: (assessment: TAssessment) => TAction =
  assessment => sliceAssessments.actions.assessmentSet({ assessment });

export const acSetAssessmentDomainId: (currentDomainId: number) => TAction =
  currentDomainId => sliceAssessments.actions.domainIdSet({ currentDomainId });

export const acRefreshResults: TGenericActionCreator = () =>
  sliceResults.actions.setResultsFetchTrigger();

export const acGetResults: (assessmentId: number) => TAction = assessmentId =>
  getResults({ assessmentId });

// Thunks
//endregion

//region - Coaching
/********************
 * Feed             *
 ********************/

// Reducers

// Thunks

//endregion

//region - Domains
/********************
 * Domains          *
 ********************/

// Thunks
//endregion

//region - Persons
/********************
 * Persons          *
 ********************/

// Reducers
export const acPersonTempNameUpdated: (firstName: string) => TAction =
  firstName => {
    // @ts-ignore
    return slicePersons.actions.tempNameUpdated({ firstName });
  };
export const acPersonTempAgeUpdated: (age: number) => TAction = age => {
  // @ts-ignore
  return slicePersons.actions.tempAgeUpdated({ age });
};

// Thunks
//endregion

//region - Plan
/********************
 * Plan             *
 ********************/

// Reducers
export const acSetSavedTimestamp: TGenericActionCreator = () => {
  // @ts-ignore
  return slicePlan.actions.timestampSet();
};

export const acRemoveCommentItem: TGenericActionCreator = () => {
  // @ts-ignore
  return slicePlan.actions.commentItemRemoved({});
};

//endregion

//region - Questionnaire
/********************
 * Questionnaire    *
 ********************/

// Reducers
export const acIncrementQuestionIdx: TGenericActionCreator = () => {
  // @ts-ignore
  return sliceQuestionnaire.actions.questionIdxIncremented({});
};
export const acSetIsEditMode: (isEditMode: boolean) => TAction = isEditMode => {
  // @ts-ignore
  return sliceQuestionnaire.actions.setIsEditMode({ isEditMode });
};
export const acSetCurrentQuestionIdx: (questionIdx: number) => TAction =
  questionIdx => {
    // @ts-ignore
    return sliceQuestionnaire.actions.currentQuestionIdxSet({ questionIdx });
  };

// Factories
type TAssessmentItemInteractions = {
  acSendRetort: (responseId: number) => TAction;
};

type TAssessmentDomainInteractions = {
  acGetQuestionnaire: TGenericActionCreator;
  createAssessmentItemInteractions: (
    assessmentItemId: number,
    itemDefId: number
  ) => TAssessmentItemInteractions;
};

type TAssessmentAPIInteractions = {
  acGetAssessmentDomains: TGenericActionCreator;
  createAssessmentDomainInteractions: (
    domainId: number
  ) => TAssessmentDomainInteractions;
};

type TCreateAssessmentAPIInteractions = (
  assessmentId: number
) => TAssessmentAPIInteractions;

export const createAssessmentAPIInteractions: TCreateAssessmentAPIInteractions =
  assessmentId => ({
    acGetAssessmentDomains: () => getCadeDomains({ assessmentId }),
    createAssessmentDomainInteractions: domainId => ({
      acGetQuestionnaire: () => getQuestionnaire({ assessmentId, domainId }),
      createAssessmentItemInteractions: (assessmentItemId, itemDefId) => ({
        acSendRetort: responseId =>
          sendRetort({
            assessmentId,
            domainId,
            assessmentItemId,
            itemDefId,
            responseId,
          }),
      }),
    }),
  });
//endregion

//region - Results
/********************
 * Results          *
 ********************/

// Reducers
//endregion

//region - Session
/********************
 * Session          *
 ********************/

// Reducers
export const acSetSessionTimeoutStatus: (isTimeout: boolean) => TAction =
  isTimeout => sliceSession.actions.timeoutIsSet({ isTimeout });

export const acSetIsAuthZone: (isAuthZone: boolean) => TAction = isAuthZone => {
  // @ts-ignore
  return sliceSession.actions.isAuthZoneSet({ isAuthZone });
};
export const acInitIsComplete: TGenericActionCreator = () => {
  // @ts-ignore
  return sliceSession.actions.initIsComplete({});
};
export const acSetPaywallStatus: (hasPaywall: boolean) => TAction =
  hasPaywall => sliceSession.actions.paywallStatusSet({ hasPaywall });
export const acSetDownloadAppStatus: (
  isDownloadAppEnabled: boolean
) => TAction = isDownloadAppEnabled =>
  sliceSession.actions.downloadAppStatusSet({ isDownloadAppEnabled });
export const acSetGaClientId: (gaClientId: string) => TAction = gaClientId =>
  sliceSession.actions.gaClientIdSet({ gaClientId });

// Thunks
export const acUpdatePassword: (
  oldPassword: string,
  newPassword: string
) => TAction = (oldPassword, newPassword) =>
  updatePassword({ oldPassword, newPassword });

export const acAuthenticateCadeUser: (args?: TGenericObject) => TAction = (
  args = {}
) => authenticateCadeUser(args);

export const acPerformTemporaryLogin: () => TAction = () =>
  performTemporaryLogin({});

//endregion

//region Bundle Slices into Main Reducer
const slices = [
  sliceAccount,
  sliceAssessments,
  sliceDomains,
  slicePersons,
  sliceQuestionnaire,
  sliceResults,
  sliceSession,
  sliceSymptoms,
];

const store = configureStore({
  reducer: slices.reduce(
    (snowball, { name, reducer }) => ({ ...snowball, [name]: reducer }),
    {}
  ),
});

export type TRootState = ReturnType<typeof store.getState>;
export type TDispatch = typeof store.dispatch;

export default store;
//endregion
