import DefDomainsView, { IPageDefDomainsView } from './Views/DefDomainsView';
import AssessDomainsView, {
  IPageAssessDomainsView,
} from './Views/AssessDomainsView';
import { Slice } from '../../global/constants';
import { useSlices } from '../../hooks/useSlices';
import { useCurrentPerson } from '../../hooks/useCurrentPerson';

/**
 * @constructor PageDomains
 * @description Page component for domains page. Generates props and passes them to DefDomainsView or AssessDomainsView.
 * @constructor
 */
const PageDomains = () => {
  const [{ isTempLogging }, { isInit: isDomainsInit }] = useSlices(
    Slice.SESSION,
    Slice.DOMAINS
  );
  const currentPerson = useCurrentPerson();

  if (!!currentPerson) {
    const { firstName } = currentPerson;
    const assessViewProps: IPageAssessDomainsView = {
      firstName,
      loadedConditions: [isDomainsInit, !isTempLogging, !!firstName],
    };
    return <AssessDomainsView {...assessViewProps} />;
  }

  const defViewProps: IPageDefDomainsView = {
    loadedConditions: [isDomainsInit, !isTempLogging],
  };
  return <DefDomainsView {...defViewProps} />;
};
export default PageDomains;
