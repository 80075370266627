import { Slice } from '../../../global/constants';
import { buildSlice } from '../core/helpers';
import {
  createCadeAssessment,
  initCadeAssessments,
  TAssessment,
  TCourse,
} from '../sliceAssessments';
import { PayloadAction } from '@reduxjs/toolkit';
import { TNullable } from '../../../global/types';
import { extractSymptoms } from './core/helpers';

export type TSymptomMeta = {
  id: number;
  slug: string;
  name: string;
  imageUrl: TNullable<string>;
  description: string;
  courses: TCourse[];
};
type TSymptomState = {
  symptoms: TSymptomMeta[];
};

interface ISymptomsState extends TSymptomState {
  isInit: boolean;
}

const initialState: ISymptomsState = {
  symptoms: [],
  isInit: false,
};

export const sliceSymptoms = buildSlice({
  name: Slice.SYMPTOMS,
  initialState,
  reducers: {},
  extraReducers: {
    [initCadeAssessments.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        assessments: TAssessment[];
        currentAssessmentId: TNullable<number>;
      }>
    ) => {
      if (!state.isInit) {
        const { assessments, currentAssessmentId } = payload;
        const currentAssessment = assessments.find(
          ({ id }) => id === currentAssessmentId
        );

        if (!!currentAssessment) {
          state.symptoms = extractSymptoms(currentAssessment!);
          state.isInit = true;
        }
      }
    },
    [createCadeAssessment.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ assessment: TAssessment }>
    ) => {
      if (!state.isInit) {
        const { assessment } = payload;
        state.symptoms = extractSymptoms(assessment);

        state.isInit = true;
      }
    },
  },
});
