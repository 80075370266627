import { acPersonTempAgeUpdated } from '../../store';
import { Slice } from '../../global/constants';
import { useQuestionnaireRoute } from '../../quests/useQuestionnaireRoute';
import { useSlices } from '../../hooks/useSlices';
import { Quest } from '../../quests/core/data';
import PageRequestAgeView, { IPageRequestAgeView } from './PageRequestAgeView';
import { TPerson } from '../../store/slices';

const PageRequestAge = () => {
  const [
    { tempData: tempPersonData, currentPersonId, persons },
    { currentDomainId: currentAssessmentDomainId },
  ] = useSlices(Slice.PERSONS, Slice.ASSESSMENTS);

  const person = currentPersonId
    ? (persons as TPerson[]).find(({ id }) => id === currentPersonId)
    : { age: 0, firstName: '' };

  const firstName = person?.firstName || tempPersonData?.firstName || '';

  const nextRoute = useQuestionnaireRoute(currentAssessmentDomainId, Quest.AGE);

  const viewProps: IPageRequestAgeView = {
    nextRoute,
    firstName,
    acMissingInfoUpdated: acPersonTempAgeUpdated,
  };
  return <PageRequestAgeView {...viewProps} />;
};

export default PageRequestAge;
