import React from 'react';
import { Box } from '@chakra-ui/react';
import { createComponentProps } from '../../global/helpers';
import { pageGutter } from '../../pages/Page/props';

interface IEssayInterior {
  children?: React.ReactNode;
  p?: string;
  position?: string;
  dataTestId?: string;
}

export const testId = 'essayInterior';
/**
 * @constructor EssayInterior
 * @description An EssayInterior is a wrapper for the content of an Essay.  It provides layout styles and padding.
 * @param children - content of the EssayInterior
 * @param p - css padding
 * @param position - css position
 * @param dataTestId - data-testid attribute for Jest
 * @param rest - additional props passed to the wrapper
 * @returns {JSX.Element}
 */
export const EssayInterior: React.FC<IEssayInterior> = ({
  children,
  p = `${pageGutter}px`,
  position = 'relative',
  dataTestId = testId,
  ...rest
}) => {
  const interiorProps = createComponentProps(dataTestId, {
    position,
    p,
    ...rest,
    w: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  });
  return <Box {...interiorProps}>{children}</Box>;
};
export default EssayInterior;
