import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { getTypography } from '../../theme/_';
import { IContainer, ICore } from '../../global/interfaces';
import { TGenericObject } from '../../global/types';

type TCreateProps = (
  props: TGenericObject,
  el: string,
  extra?: TGenericObject
) => TGenericObject;

/**
 * @function createProps
 * @description Creates UI props for a given element
 * @param props - Props to be passed to the element
 * @param el - Element to be rendered
 * @param extra - Extra props to be passed to the element
 */
const createProps: TCreateProps = (props, el, extra = {}) => {
  const [elType, elKind = elType] = el.split(',');
  return {
    ...extra,
    ...getTypography(elType),
    ...props,
    as: elKind,
  };
};
const extraHeadingProps = { color: 'blue.800' };
const extraCopyProps = {
  sx: {
    a: {
      textDecoration: 'underline',
    },
  },
};

interface IProseElement extends ICore, IContainer {}

/**
 * @constructor H1
 * @description Renders a standardized H1 element
 * @param children - H1 content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const H1: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Heading {...createProps(rest, 'h1', extraHeadingProps)}>{children}</Heading>
);

/**
 * @constructor H2
 * @description Renders a standardized H2 element
 * @param children - H2 content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const H2: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Heading {...createProps(rest, 'h2', extraHeadingProps)}>{children}</Heading>
);

/**
 * @constructor H3
 * @description Renders a standardized H3 element
 * @param children - H3 content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const H3: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Heading {...createProps(rest, 'h3', extraHeadingProps)}>{children}</Heading>
);

/**
 * @constructor H4
 * @description Renders a standardized H4 element
 * @param children - H4 content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const H4: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Heading {...createProps(rest, 'h4', extraHeadingProps)}>{children}</Heading>
);

/**
 * @constructor H5
 * @description Renders a standardized H5 element
 * @param children - H5 content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const H5: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Heading {...createProps(rest, 'h5', extraHeadingProps)}>{children}</Heading>
);

/**
 * @constructor H6
 * @description Renders a standardized H6 element
 * @param children - H6 content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const H6: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Heading {...createProps(rest, 'h6', extraHeadingProps)}>{children}</Heading>
);

/**
 * @constructor Copy
 * @description Renders regular copy in a standardized way
 * @param children - Copy content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const Copy: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Text {...createProps(rest, 'copy', extraCopyProps)}>{children}</Text>
);

/**
 * @constructor Caption
 * @description Renders a standardized Caption element
 * @param children - Caption content
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const Caption: React.FC<IProseElement> = ({ children, ...rest }) => (
  <Text {...createProps(rest, 'caption')}>{children}</Text>
);
