import React from 'react';
import { Icon } from '@chakra-ui/react';
import { getThemeColor } from '../../theme/_';
import { ICore } from '../../global/interfaces';
import { createComponentProps } from '../../global/helpers';

/**
 * @constructor GobStopper
 * @description Stylized graphic used throughout the App's UI.  Named after the candy, for its oblong shape.
 */
export interface IGobstopper extends ICore {
  bgColor?: string;
  bgValue?: number;
  w?: number | string;
}
export const testId = 'gobstopper';
const GobStopper: React.FC<IGobstopper> = ({
  bgColor = 'blue',
  bgValue = 800,
  w = '100%',
  dataTestId = testId,
  ...rest
}) => {
  const fill = getThemeColor(bgColor, bgValue);
  const gobStopperProps = createComponentProps(dataTestId, {
    viewBox: '0 0 47 43',
    w,
    height: 'auto',
    ...rest,
  });
  const pathProps = {
    d: `M0 21.5C0 33.374 9.652 43 21.558 43s24.687-14.134 24.687-26.008C46.245 5.118 33.464 0 21.558 0S0 9.626 0 21.5z`,
    fill,
  };

  return (
    <Icon {...gobStopperProps}>
      <path {...pathProps} />
    </Icon>
  );
};

export default GobStopper;
