import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import { useAssessmentCompletion } from '../../store/slices/sliceAssessments/core/useAssessmentCompletion';
import { allTrue } from '../../global/helpers';

const useEmbarkConditions_Inquiry = () => {
  const [{ additionalData }] = useSlices(Slice.ACCOUNT);
  const { numSurveysComplete } = useAssessmentCompletion();

  // if `additionalData` is populated, the user has already completed the Inquiry
  const isQuestionnaireInquiryComplete = !!additionalData;

  const shouldEmbarkInquiry = allTrue(
    !isQuestionnaireInquiryComplete,
    numSurveysComplete > 0
  );
  return {
    shouldEmbarkInquiry,
  };
};

export default useEmbarkConditions_Inquiry;
