import { Box } from '@chakra-ui/react';
import { pageGutter } from '../../pages/Page/props';


// TODO: JsDoc
export const PageSection = ({
  py = `${pageGutter}px`,
  width = '100%',
  ...rest
}) => {
  // Comments fine here
  const pageSectionProps = {
    py,
    width,
    ...rest,
  };

  return <Box {...pageSectionProps} />;
};
export default PageSection;
