import { PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../../global/constants';
import { buildSlice } from '../core/helpers';
import { getResults } from './core/thunks';
import { TCourse } from '../sliceAssessments';

export type TScore = {
  concernLevel: number;
  domainConcernText: string;
  domainDefId: number;
  domainName: string;
  lastModified: string;
  maxScore: number;
  numPersonsCompletingDomain: number;
  pctPersonsLikeMe: number;
  pctPersonsLikeMeAsText: string;
  score: number;
};
export type TResultsState = {
  scores: TScore[];
  courses: TCourse[];
};
interface IResultsState extends TResultsState {
  isInit: boolean;
  isReady: boolean;
  triggerFetchResults: number;
}
const initialState: IResultsState = {
  isInit: false,
  isReady: false,
  scores: [],
  courses: [],
  triggerFetchResults: 0,
};

export const sliceResults = buildSlice({
  name: Slice.RESULTS,
  initialState,
  reducers: {
    setResultsFetchTrigger: {
      reducer: (
        state,
        { payload }: PayloadAction<{ triggerFetchResults: number }>
      ) => {
        state.triggerFetchResults = payload.triggerFetchResults;
      },
      prepare: () => {
        return { payload: { triggerFetchResults: Date.now() } };
      },
    },
  },
  extraReducers: {
    [getResults.pending.type]: state => {
      state.isReady = false;
    },
    [getResults.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        scores: TScore[];
        courses: TCourse[];
      }>
    ) => {
      const { scores, courses } = payload;

      state.scores = scores;
      state.courses = courses;
      state.isInit = true;
      state.isReady = true;
    },
  },
});
