import React from 'react';
import { ButtonBackView, IButtonBackView } from './ButtonBackView';
import { useHistory } from 'react-router-dom';
import { getDomainsPath } from '../../global/helpers';
import { useRedirect } from '../../hooks/useRedirect';

export interface ISymptomDatum {}

/**
 * @constructor ButtonBack
 * @description Button to go back to previous page or to domains page if there is no previous page
 * @param rest
 * @returns {JSX.Element}
 */
export const ButtonBack: React.FC<ISymptomDatum> = ({ ...rest }) => {
  const redirect = useRedirect();
  const history = useHistory();
  const action = () =>
    history.length > 2 ? history.goBack() : redirect(getDomainsPath());

  const viewProps: IButtonBackView = {
    clickAction: action,
    ...rest,
  };

  return <ButtonBackView {...viewProps} />;
};

export default ButtonBack;
