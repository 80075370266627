import Button from '../../../Button';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Copy } from '../../../Prose';
import { TGenericFunction } from '../../../../global/types';
import { getFormattedDuration } from '../../core/helpers';
import { uiHeading } from '../../core/ui';

interface INotCompleteView {
  approximateTimeToComplete: number;
  buttonClickHandler: TGenericFunction;
  numberOfItems: number;
}
export const NotCompleteView: React.FC<INotCompleteView> = ({
  buttonClickHandler,
  numberOfItems,
}) => {
  const questionnaireButtonProps = {
    variant: 'primary',
    onClick: buttonClickHandler,
    children: 'Get Started',
  };
  return (
    <>
      <Box {...uiHeading.cell.button}>
        <Button {...questionnaireButtonProps} />
      </Box>
      <Box {...uiHeading.cell.aux}>
        <Copy>
          {numberOfItems} Questions &bull; {getFormattedDuration(numberOfItems)}
        </Copy>
      </Box>
    </>
  );
};
export default NotCompleteView;
