import React from 'react';
import { Box, Image as CImage } from '@chakra-ui/react';
import { ICore } from '../../global/interfaces';
import { allTrue, createComponentProps } from '../../global/helpers';

interface IImage extends ICore {
  w?: number;
  h?: number;
  isHidden?: boolean;
  src?: string;
  backgroundSize?: string;
}
export const testId = 'image';
export const rawImageId = 'rawImage';

/**
 * @constructor Image
 * @description A wrapper for the Chakra Image component.  This component is used to render images with consistent styling.
 * @param w - The width of the image.
 * @param h - The height of the image.
 * @param isHidden - Whether or not the image should be hidden.
 * @param src - The source of the image.
 * @param backgroundSize - The background size of the image.
 * @param dataTestId - The data-test-id of the component. For Jest testing.
 * @param rest - Any other props to pass to the component.
 * @returns {JSX.Element}
 */
export const Image: React.FC<IImage> = ({
  w,
  h,
  isHidden = false,
  src,
  backgroundSize = 'cover',
  dataTestId = testId,
  ...rest
}) => {
  const aspect = allTrue(!!w, !!h) ? (100 * h!) / w! : 1;
  const wrapProps = createComponentProps(dataTestId, {
    backgroundSize,
    overflow: 'hidden',
    ...rest,
    ...(!!src && { backgroundImage: `url(${src})` }),
    ...(isHidden && {
      position: 'absolute',
      h: 0,
      w: 0,
      left: '-1000px',
      top: '-1000px',
    }),
    sx: {
      ':before': {
        content: '""',
        display: 'block',
        paddingTop: `${aspect}%`,
      },
      img: {
        display: 'none',
      },
    },
  });

  const imageProps = createComponentProps(rawImageId, { src });
  return (
    <Box {...wrapProps}>
      <CImage {...imageProps} />
    </Box>
  );
};
export default Image;
