import ButtonSignOut from '../../../ButtonSignOut';
import PageBody from '../../../PageBody';
import { Box, Link } from '@chakra-ui/react';
import { Copy, H3 } from '../../../Prose';
import {
  uiButton,
  uiButtonWrap,
  uiContactLink,
  uiCopy,
  uiHeading,
  uiWrap,
} from './core/ui';

const StatusTimeout = () => (
  <PageBody {...uiWrap}>
    <H3 {...uiHeading}>Request Timeout</H3>
    <Copy {...uiCopy}>
      Looks like the server is taking too long to respond. Please sign out and
      try again. If you continue to have this problem, please{' '}
      <Link {...uiContactLink}>contact us</Link>.
    </Copy>
    <Box {...uiButtonWrap}>
      <ButtonSignOut {...uiButton} />
    </Box>
  </PageBody>
);

export default StatusTimeout;
