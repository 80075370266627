import { acAccountTempNameUpdated, acPersonTempNameUpdated } from '../../store';
import { useQuestionnaireRoute } from '../../quests/useQuestionnaireRoute';
import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import { Quest } from '../../quests/core/data';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useRedirect } from '../../hooks/useRedirect';

import Page from '../Page';
import PageBody from '../../components/PageBody';
import Button from '../../components/Button';
import { H3 } from '../../components/Prose';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import {
  errorMsgProps,
  getInputProps,
  getLabelProps,
  getSubmitProps,
  headingProps,
} from './core/props';
import { maxPageInteriorWidth } from '../../global/constants';
import { getTypography } from '../../theme/_';
import { useInputAutofocus } from './core/useInputAutofocus';

const PageRequestName = ({ ...rest }) => {
  const [{ currentDomainId }] = useSlices(Slice.ASSESSMENTS);

  const headline = `First, let's get to know who we're assessing.`;

  const childInfoDetails = {
    desc: ``,
    label: `What is your child's first name?`,
    type: 'text',
    placeholder: 'Child Name',
    requiredText: `You must enter a child's first name`,
    pattern: {},
  };

  const parentInfoDetails = {
    desc: ``,
    label: `What is your first name?`,
    type: 'text',
    placeholder: 'Your Name',
    requiredText: `You must enter your first name`,
    pattern: {},
  };

  const nextRoute = useQuestionnaireRoute(currentDomainId, Quest.NAME);

  // Hooks
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const refAutoFocus = useRef(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  useInputAutofocus(refAutoFocus);

  // Variables
  const missingChildInfoInputName = 'missingChildInfo';
  const missingParentInfoInputName = 'missingParentInfo';
  const {
    [missingChildInfoInputName]: childError,
    [missingParentInfoInputName]: parentError,
  } = errors; // Hook Form Errors
  const submitText = 'Next Step';
  const { hfProps: childInputHookFormProps = {} } = childInfoDetails;
  const { hfProps: parentInputHookFormProps = {} } = parentInfoDetails;

  // Handlers
  const onSubmit = valuesRaw => {
    return new Promise(resolve => {
      setTimeout(() => {
        dispatch(acPersonTempNameUpdated(valuesRaw[missingChildInfoInputName]));
        dispatch(
          acAccountTempNameUpdated(valuesRaw[missingParentInfoInputName])
        );
        redirect(nextRoute);
        resolve();
      }, 1000);
    });
  };

  // Props

  // Build MissingInfo HF Registration separately, for clarity
  const hfReg_MissingChildInfoProps = register(missingChildInfoInputName, {
    required: childInfoDetails.requiredText,
    ...childInputHookFormProps,
  });

  // Build MissingInfo Input props and register with Hook Form
  const childInputProps = {
    ...getInputProps(missingChildInfoInputName, childInfoDetails),
    ...hfReg_MissingChildInfoProps,
    ...getTypography('h3'),
  };

  // Build MissingInfo HF Registration separately, for clarity
  const hfReg_MissingParentInfoProps = register(missingParentInfoInputName, {
    required: parentInfoDetails.requiredText,
    ...parentInputHookFormProps,
  });

  // Build MissingInfo Input props and register with Hook Form
  const parentInputProps = {
    ...getInputProps(missingParentInfoInputName, parentInfoDetails),
    ...hfReg_MissingParentInfoProps,
    ...getTypography('h3'),
  };

  const pageProps = {
    pageTitle: `What are your first names?`,
    ...rest,
  };
  return (
    <Page {...pageProps}>
      <PageBody>
        <Box mb={'80px'}>
          <H3 {...headingProps}>{headline}</H3>
        </Box>
        <Box maxW={`${maxPageInteriorWidth / 2}px`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl mb={'40px'} isInvalid={childError}>
              <FormLabel {...getLabelProps(missingChildInfoInputName)}>
                {childInfoDetails.label}
              </FormLabel>
              <Input {...childInputProps} ref={refAutoFocus} />
              {!!childError && (
                <FormErrorMessage {...errorMsgProps}>
                  {childError.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={parentError}>
              <FormLabel {...getLabelProps(missingChildInfoInputName)}>
                {parentInfoDetails.label}
              </FormLabel>
              <Input {...parentInputProps} />
              {!!parentError && (
                <FormErrorMessage {...errorMsgProps}>
                  {parentError.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button {...getSubmitProps(isSubmitting)}>{submitText}</Button>
          </form>
        </Box>
      </PageBody>
    </Page>
  );
};

export default PageRequestName;
