import { TAssessment, TAssessmentDomain } from '../../sliceAssessments';
import { TSymptomMeta } from '../sliceSymptoms';
import { getCadeySlug } from '../../../../global/helpers';

const reduceDomainsToSymptoms = (
  acc: TSymptomMeta[],
  domain: TAssessmentDomain
): TSymptomMeta[] => [
  ...acc,
  ...domain.assessmentItems.map(
    ({ itemDefId: id, displayName: name, description, courses, imageUrl }) => ({
      id,
      imageUrl,
      name,
      slug: getCadeySlug(name),
      description,
      courses,
    })
  ),
];

export const extractSymptoms = (assessment: TAssessment): TSymptomMeta[] =>
  // Return all Symptoms on all AssessmentDomains.  Contains Duplicates
  assessment.assessmentDomains
    .reduce(reduceDomainsToSymptoms, [])
    // Remove Duplicates
    .filter((symptom, i, arr) => arr.findIndex(s => s.id === symptom.id) === i)
    // Sort by Id
    .sort((a, b) => a.id - b.id);
