export enum IconType {
  ARROW_UP = 'arrowUp',
  ARROW_LEFT = 'arrowLeft',
  BOOKMARK = 'bookmark',
  BROKEN_RULER = 'brokenRuler',
  BULLHORN = 'bullhorn',
  BULLSEYE = 'bullseye',
  CHART = 'chart',
  CHAT_BUBBLES = 'chatBubbles',
  CHECK_CIRCLE = 'checkCircle',
  CHECKMARK = 'checkmark',
  CLOSE = 'close',
  EKG = 'ekg',
  GRADUATION_CAP = 'graduationCap',
  HAMBURGER = 'hamburger',
  HEART = 'heart',
  LIGHT_BULB = 'lightBulb',
  MY_PLAN = 'myPlan',
  MY_PLAN_LARGE = 'myPlanLarge',
  PAPERCLIP = 'paperclip',
  RAIN_CLOUD = 'rainCloud',
  RIBBON = 'ribbon',
  RIBBON_STRIKE = 'ribbonStrike',
  STRESSED_FACE = 'stressedFace',
  SWIRLY_ARROWS = 'swirlyArrows',
  THUMBS_DOWN = 'thumbsDown',
  THUMBS_UP = 'thumbsUp',
  TOOTHBRUSH = 'toothbrush',
  TRASH = 'trash',
  WARNING = 'warning',
}
