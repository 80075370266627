import React, { useState } from 'react';
import VimeoPlayer from 'react-player/vimeo';
import { TGenericObject } from '../../global/types';
import ReactPlayer from 'react-player';
import { Box } from '@chakra-ui/react';

const createPlayerPaneProps = (
  isPlayerReady: boolean,
  rest: TGenericObject
) => ({
  transition: 'opacity 250ms',
  opacity: isPlayerReady ? 1 : 0,
  ...rest,
});

interface IVideoPlayer {
  url?: string;
  configOptions?: TGenericObject;
}

export const testId = 'videoPlayer';

/**
 * @constructor VideoPlayer
 * @description Wrapper for `React Player` library component.  Assumes Vimeo.  Renders a video player with the given url.
 * @param configOptions - `React Player` config options for the player
 * @param url - url of the video
 * @param rest - any other props
 * @returns {JSX.Element}
 */
export const VideoPlayer: React.FC<IVideoPlayer> = ({
  configOptions = {},
  url,
  ...rest
}) => {
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const onReady = (player: ReactPlayer) => {
    setIsPlayerReady(true);
    console.log('Video ready to play');
    console.log(player);
  };

  const playerProps = {
    url,
    playsinline: false,
    width: 'auto',
    onReady,
    config: {
      playerOptions: {
        controls: true,
        playsinline: false,
        //autoplay: true,
      },
      ...configOptions,
    },
  };

  return (
    <Box {...createPlayerPaneProps(isPlayerReady, rest)}>
      <VimeoPlayer {...playerProps} />
    </Box>
  );
};
export default VideoPlayer;
