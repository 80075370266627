import Page from '../../Page';
import { Box } from '@chakra-ui/react';
import { Copy, H3 } from '../../../components/Prose';
import { IPageView } from '../../Page/PageView';
import {
  headerImageProps,
  headingProps,
  pageBodyProps,
  pageHeadingText,
} from '../props';

import DomainList from '../../../components/DomainList';
import Image from '../../../components/Image';
import PageBody from '../../../components/PageBody';

export interface IPageDefDomainsView extends IPageView {}
const DefDomainsView: React.FC<IPageDefDomainsView> = ({
  loadedConditions,
}) => {
  const pageProps = {
    loadedConditions,
    pageTitle: 'Free Child Psychology Tests - Cadey',
  };

  return (
    <Page {...pageProps}>
      <Box {...headingProps}>
        <Image {...headerImageProps} />
        <H3>{pageHeadingText}</H3>
        <Copy>You can take additional assessments later.</Copy>
      </Box>
      <PageBody {...pageBodyProps}>
        <DomainList />
      </PageBody>
    </Page>
  );
};
export default DefDomainsView;
