import { Quest } from '../core/data';
import { getCurrentQuestPath } from '../core/helpers';
import { getResultPath } from '../../global/helpers';
import { nullChar, Slice, UserStatus } from '../../global/constants';
import { useSlices } from '../../hooks/useSlices';
import { useAssessment } from '../../store/slices/sliceAssessments/core/useAssessment';

export const useResultsRoute = (completedQuest?: string): string => {
  const [{ userStatus }, { email }, { currentDomainId }] = useSlices(
    Slice.SESSION,
    Slice.ACCOUNT,
    Slice.ASSESSMENTS
  );

  const currentAssessment = useAssessment();
  const domains = currentAssessment?.assessmentDomains || [];
  const domain = domains.find(
    ({ domainDefId }) => domainDefId === currentDomainId
  );
  const domainSlug = domain?.slug || 'invalid';

  return getCurrentQuestPath(
    [
      { quest: Quest.EMAIL, shouldEmbark: email === nullChar },
      {
        quest: Quest.PASSWORD,
        shouldEmbark: userStatus === UserStatus.TRIAL,
      },
    ],
    completedQuest, // `completedQuest` is the name of a quest in the sequence we know we've completed.  It lets us skip ahead in line
    getResultPath(domainSlug)
  );
};
