export const pageBodyProps = {
  mb: { base: '50px' },
};
export const headerSectionProps = {
  textAlign: 'center',
  maxW: '530px',
  margin: 'auto',
};
export const pageHeaderProps = {
  mb: '0.25em',
};
