const contentGutter = '30px';
export const uiWrap = {
  minH: '100vh',
  m: 'auto',
};
export const uiHeading = {
  mb: contentGutter,
};
export const uiCopy = {
  mb: contentGutter,
};
export const uiButtonWrap = {
  maxW: '300px',
  margin: 'auto',
};
export const uiContactLink = {
  href: `https://cadey.co/contact`,
  target: '_blank',
  rel: 'noopener noreferrer',
  whiteSpace: 'nowrap',
} as const;
export const uiButton = {
  variant: 'primary',
};
