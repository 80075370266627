import Loader from '../../../components/Loader';
import { acSetSessionTimeoutStatus } from '../../../store';
import { allTrue } from '../../../global/helpers';
import { useDispatch } from 'react-redux';
import { useTimeout } from '@chakra-ui/react';

/**
 * @function withLoader
 * @description HOC to wrap Page component.  Renders Loader component if not all conditions are met.  Sets session timeout status to true if timeout is reached.
 * @param PageComponent
 */
export const withLoader =
  (PageComponent: any) =>
  ({ loadedConditions = [], ...rest }: { loadedConditions: boolean[] }) => {
    const dispatch = useDispatch();
    const isEverythingReady = allTrue(...loadedConditions);

    const timeoutLength: number =
      Number(process.env.REACT_APP_SESSION_TIMEOUT) || 5000;

    const timeoutDelay = !isEverythingReady ? timeoutLength : null;

    useTimeout(() => {
      dispatch(acSetSessionTimeoutStatus(true));
    }, timeoutDelay);

    const loaderProps = { isActive: !isEverythingReady, isSpinner: true };
    return (
      <>
        <Loader {...loaderProps} />
        {isEverythingReady && <PageComponent {...rest} />}
      </>
    );
  };

export default withLoader;
