import { createAsyncThunk } from '@reduxjs/toolkit';
import { nullChar, Slice } from '../../../../global/constants';
import api from '../../../../services/api/api';
import { immerLog } from '../../../../global/helpers';
import { Auth } from 'aws-amplify';
import produce from 'immer';
import { maybeGetResponseData } from '../../../../services/api/core/maybeGetResponseData';
import { TGenericObject, TNullable } from '../../../../global/types';
import { TAccount, TAdditionalData } from './types';

const bogusDomain = 'mailinator.com';

export const getCadeAccount = createAsyncThunk<
  TGenericObject,
  { accountId: number }
>(`${Slice.ACCOUNT}/get`, async ({ accountId }) => {
  const accountResponse = await api.get(`account/${accountId}`);
  const accountData = maybeGetResponseData(accountResponse);

  return produce(accountData, (newAccountData: TAccount) => {
    const { email = nullChar } = newAccountData;
    newAccountData.email = email.includes(bogusDomain) ? nullChar : email;
    immerLog(newAccountData);
  });
});

/********
 *
 *
 * Create a temporary Cade Account, convert a temporary account to permanent or create a net-new permanent Cade account.
 *
 * TEMP ACCOUNT: Specify "CADE_TEMP" in the username property and a comma-separated list of domain IDs if domain restriction requested. All other properties are unused.
 *
 * TEMP To PERM: If converting from a temp account to permanent, provide the temporary user's Cade ID, new username (if omitted, email will be used), first and last name and email. All other properties are optional. The new permanent account will have unrestricted access to domains.
 *
 * NET NEW PERM: If creating a net-new account, leave the Cade ID as 0 and provide a username (if omitted, email will be used), first and last name, email and any domain restrictions. All other properties are optional.
 */

/**
 * @async Thunk
 * @function postCadeAccountInfo
 * @param {object} accountProps - The Account Data to post to the CADE API
 * @returns {object} Structured Account Data
 * @description Create a temporary Cade Account, convert a temporary account to permanent or create a net-new permanent Cade account.
 * @author David Lawson <david@clearchildpsychology.com>
 */

export const postCadeAccountInfo = createAsyncThunk<
  TNullable<TAccount>,
  TAccount
>(`${Slice.ACCOUNT}/postAccountInfo`, async accountProps => {
  const {
    // @ts-ignore
    accessToken: { jwtToken: accessToken },
  } = await Auth.currentSession();

  const fullProps = {
    ...accountProps,
    accessToken,
  };
  const accountResponse = await api.post(`account`, {
    ...fullProps,
  });
  return maybeGetResponseData(accountResponse) || null;
});

export const authenticateCadeUser = createAsyncThunk<TGenericObject, {}>(
  `${Slice.ACCOUNT}/authenticateCadeUser`,
  async () => {
    // Pull JWT version of accessToken from the Session
    const {
      // @ts-ignore
      accessToken: { jwtToken: accessToken },
    } = await Auth.currentSession();

    const authenticationResponse = await api.post('account/cadeauthenticate', {
      accessToken,
    });
    const authenticationData = maybeGetResponseData(authenticationResponse);

    // Prune the account data.  Pull out everything not specific to the Account record
    const {
      sendWelcomeEmail,
      accessToken: accessTokenThrowaway,
      ...accountRecordRaw
    } = authenticationData;

    return produce(accountRecordRaw, (newRecord: TAccount) => {
      const { email = nullChar } = newRecord;
      newRecord.email = email.includes(bogusDomain) ? nullChar : email;
    });
  }
);

export const setWaitlistOptInStatus = createAsyncThunk<
  {
    waitlistResponse: any;
    isWaitlisted: boolean;
  },
  { accountId: number; isOptedIn: boolean }
>(`${Slice.ACCOUNT}/setWaitlistStatus`, async ({ accountId, isOptedIn }) => {
  const waitlistRes = await api.post(
    `account/${accountId}/waitlist/${isOptedIn}`
  );

  const { waitlistResponse } = maybeGetResponseData(waitlistRes);
  return { waitlistResponse, isWaitlisted: isOptedIn };
});

export const postAdditionalData = createAsyncThunk<
  { additionalData: TAdditionalData },
  { accountId: number; additionalData: TAdditionalData }
>(
  `${Slice.ACCOUNT}/postAdditionalData`,
  async ({ accountId, additionalData }) => {
    await api.post(`/account/${accountId}/additionaldata`, { additionalData });

    return { additionalData };
  }
);
