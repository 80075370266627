import React, { useState } from 'react';
import {
  buttonGutter,
  ButtonSymptomsView,
  IButtonSymptomsView,
} from './ButtonSymptomsView';
import { ISymptomDatum } from '../ButtonSymptom/ButtonSymptom';
import { Collapse } from '@chakra-ui/react';
import { Button } from '../Button';

interface IButtonSymptoms {
  data?: ISymptomDatum[];
}

const visibleThreshold = 5;
const getCollapsedHeight = (itemCount: number) =>
  buttonGutter + itemCount * 40 + (itemCount - 1) * buttonGutter;

/**
 * @constructor ButtonSymptoms
 * @description Creates list of ButtonSymptom components.  If data.length > 5, renders a Collapse component. Shell component. Generates props and passes to View
 * @param data - Array of Symptom Data
 * @param rest - All other props
 * @returns {JSX.Element}
 */
export const ButtonSymptoms: React.FC<IButtonSymptoms> = ({
  data = [],
  ...rest
}) => {
  const viewProps: IButtonSymptomsView = { data, ...rest };
  const buttonCount = data.length;
  const [isOpen, setIsOpen] = useState(false);
  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };
  const transitionProps = {
    startingHeight: `${getCollapsedHeight(5)}px`,
    endingHeight: 'auto',
    in: isOpen,
  };

  const toggleButtonProps = {
    onClick: onToggleClick,
    children: `View ${isOpen ? 'Less' : 'More'}`,
    variant: 'quaternary',
  };

  const RenderedView = <ButtonSymptomsView {...viewProps} />;
  return buttonCount > visibleThreshold ? (
    <>
      <Collapse {...transitionProps}>{RenderedView}</Collapse>
      <Button {...toggleButtonProps} />
    </>
  ) : (
    RenderedView
  );
};

export default ButtonSymptoms;
