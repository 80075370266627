import Complete from './_/Complete';
import Content from '../Content';
import DomainAvatar from '../DomainAvatar';
import Essay from '../Essay';
import EssayInterior from '../EssayInterior';
import NotComplete from './_/NotComplete';
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { H3 } from '../Prose';
import { Icon } from '../Icon';
import { TAssessmentDomain } from '../../store/slices';
import { uiHeading, wrapProps } from './core/ui';
import {
  cardProps,
  createCompleteProps,
  createDomainAvatarProps,
} from './props';
import { toTitleCase } from '../../global/helpers';
import { useGetVisibleSurveyProps } from '../../store/slices/sliceAssessments/core/useGetVisibleSurveyProps';
import { IconType } from '../Icon/core/enum';

interface ICardDomain {
  domain: TAssessmentDomain;
}

/**
 * @constructor CardDomain
 * @description Renders a card describing each Cadey Domain.  The card is a link to the Domain's Survey
 * @param domain
 * @param rest
 * @returns {JSX.Element}
 */
export const CardDomain: React.FC<ICardDomain> = ({ domain, ...rest }) => {
  const getVisibleSurveyProps = useGetVisibleSurveyProps();

  const { displayName, domainDefId: domainId, abbreviatedDescription } = domain;

  const { numberOfItems, numberOfItemsCompleted } =
    getVisibleSurveyProps(domainId);

  const completeness = numberOfItemsCompleted / numberOfItems;

  const cardInteriorProps = {
    domainId,
    numberOfItems,
    children: null,
    ...rest,
  };
  const CardInterior = completeness === 1 ? Complete : NotComplete;

  return (
    <Essay {...cardProps}>
      <EssayInterior {...cardInteriorProps}>
        <Box {...wrapProps}>
          <Flex {...uiHeading.cell.icon}>
            <DomainAvatar {...createDomainAvatarProps(domainId)} />
          </Flex>
          <Box {...uiHeading.cell.header}>
            <H3>{toTitleCase(displayName)}</H3>
          </Box>
          <Box {...uiHeading.cell.content}>
            <Content>{abbreviatedDescription}</Content>
          </Box>
          <CardInterior {...cardInteriorProps} />
        </Box>
      </EssayInterior>
      {completeness === 1 && (
        <Box {...createCompleteProps(domainId)}>
          <Icon type={IconType.CHECKMARK} />
          Complete
        </Box>
      )}
    </Essay>
  );
};
export default CardDomain;
