import React from 'react';
import { Box } from '@chakra-ui/react';
import { ButtonRoute } from '../ButtonRoute';
import { H3, H6 } from '../Prose';
import { getDomainsPath, maybePlural } from '../../global/helpers';
import { pageGutter } from '../../pages/Page/props';
import FooterCap from './_/FooterCap';

export interface IResultsSubfooterView {
  auxHeadingText: string;
  mainHeadingText: string;
  buttonText: string;
}

const bgColor = 'blue.700';
const textColor = 'white';
const wrapProps = {
  pb: '87px',
  px: `${pageGutter}px`,
  pt: '32px',
  bgColor,
  color: textColor,
  textAlign: 'center' as any,
};
const footerCapProps = {
  bgColor,
  mb: '-0.5px',
};

const auxHeadingProps = {
  mb: '9px',
  textTransform: 'uppercase',
  color: textColor,
};
const mainHeadingProps = {
  mb: '32px',
  color: textColor,
};
const linkProps = {
  variant: 'tertiary',
  to: getDomainsPath(),
  maxW: '320px',
};

/**
 * @constructor ResultsSubfooterView
 * @description Renders the subfooter for the Results page.  View component.  Renders View with props passed in from ResultsSubFooter shell.
 * @param auxHeadingText - Text for the auxiliary heading
 * @param mainHeadingText - Text for the main heading
 * @param buttonText - Text for the button
 * @returns {JSX.Element}
 */
export const ResultsSubfooterView: React.FC<IResultsSubfooterView> = ({
  auxHeadingText,
  mainHeadingText,
  buttonText,
}) => {
  return (
    <>
      <FooterCap {...footerCapProps} />
      <Box {...wrapProps}>
        <H6 {...auxHeadingProps}>{auxHeadingText}</H6>
        <H3 {...mainHeadingProps}>{mainHeadingText}</H3>
        <Box {...{ px: `${pageGutter}px` }}>
          <ButtonRoute {...linkProps}>{buttonText}</ButtonRoute>
        </Box>
      </Box>
    </>
  );
};
export default ResultsSubfooterView;
