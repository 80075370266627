import Icon from '../../Icon';

type TButtonContent = {
  [key: string]: (text: string) => JSX.Element | string;
};
export const ButtonContent: TButtonContent = {
  forward: (text: string) => (
    <>
      {text}&nbsp;
      <Icon type={'arrowRight'} />
    </>
  ),
  backward: (text: string) => (
    <>
      <Icon type={'arrowLeft'} />
      &nbsp;
      {text}
    </>
  ),
  default: (text: string) => text,
};
