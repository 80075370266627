import PageSymptomDetailView, {
  IPageSymptomDetailView,
} from './PageSymptomDetailView';
import { useParams } from 'react-router-dom';
import { useSymptomMeta } from '../../store/slices/sliceSymptoms/core/useSymptomMap';
import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';

const PageSymptomDetail = () => {
  // const [{ hasPaywall }] = useSlices(Slice.SESSION);
  const [{ isInit: isSymptomsInit }] = useSlices(Slice.SYMPTOMS);
  const { symptomSlug, concernLevel } =
    useParams<{ symptomSlug: string; concernLevel?: string }>();

  const { name, description, courses, imageUrl } = useSymptomMeta(symptomSlug);

  const loadedConditions = [isSymptomsInit];
  const viewProps: IPageSymptomDetailView = {
    loadedConditions,
    name,
    description,
    courses,
    concernLevel,
    imageUrl,
  };
  return <PageSymptomDetailView {...viewProps} />;
};

export default PageSymptomDetail;
