import ButtonRetort from '../../../ButtonRetort';
import PageSection from '../../../PageSection';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Copy, H3 } from '../../../Prose';
import { TClickEvent } from '../../../../global/types';
import { THelpText } from '../../../../store/slices';
import { concernTitles } from './data';
import { stringToJSX, toTitleCase } from '../../../../global/helpers';
import { tactileAction } from './helpers';
import {
  auxHeaderProps,
  introCopyProps,
  containerProps,
  headingAreaProps,
} from './props';

const createAnswerItemProps = (answerIndex: number) => ({
  key: `help-text-${answerIndex}`,
  py: '10px',
});

export interface IQuestionView {
  createConcernClickHandler: (concernLevel: number) => TClickEvent;
  domainName: string;
  helpTexts: THelpText[];
  questionCount: number;
  questionNumber: number;
  responseId: number;
  text: string;
}
export const QuestionView: React.FC<IQuestionView> = ({
  createConcernClickHandler,
  domainName,
  helpTexts,
  questionCount,
  questionNumber,
  responseId,
  text,
}) => {
  return (
    <Box {...containerProps}>
      <Box {...headingAreaProps}>
        <Copy {...auxHeaderProps}>
          {domainName}: Question {questionNumber}/{questionCount}
        </Copy>
        <H3>{text}</H3>
      </Box>
      <Copy {...introCopyProps}>
        {toTitleCase(tactileAction)} an Option Below
      </Copy>

      {helpTexts.map(({ concernLevel, helpText }, i) => {
        const buttonProps = {
          title: concernTitles[i],
          helpText,
          isActive: responseId === concernLevel,
          onClick: createConcernClickHandler(concernLevel),
        };
        return (
          <PageSection {...createAnswerItemProps(i)}>
            <ButtonRetort {...buttonProps} />
          </PageSection>
        );
      })}
    </Box>
  );
};
export default QuestionView;
