import { buildSlice } from '../core/helpers';
import { Slice } from '../../../global/constants';
import { getQuestionnaire } from './core/thunks';
import { sendRetort } from '../sliceAssessments';
import { decrementQuestionIdx, incrementQuestionIdx } from './core/helpers';
import { PayloadAction } from '@reduxjs/toolkit';
import { TAssessmentItem } from '../sliceAssessments';
import { TNullable } from '../../../global/types';

interface IQuestionnaireState {
  isReady: boolean;
  isBusy: boolean;
  isComplete: boolean;
  isEditMode: boolean;
  currentQuestionIdx: number;
  questionCount: TNullable<number>;
}
const initialState: IQuestionnaireState = {
  isReady: true,
  isBusy: false,
  isComplete: false,
  isEditMode: false,
  currentQuestionIdx: 0,
  questionCount: null,
};

export const sliceQuestionnaire = buildSlice({
  name: Slice.QUESTIONNAIRE,
  // TODO: port
  initialState,
  reducers: {
    currentQuestionIdxSet: (
      state,
      { payload }: PayloadAction<{ questionIdx: number }>
    ) => {
      state.currentQuestionIdx = payload.questionIdx;
    },
    questionIdxIncremented: state => {
      incrementQuestionIdx(state);
    },
    questionIdxDecremented: state => {
      decrementQuestionIdx(state);
    },
    busyStatusSet: (state, { payload }: PayloadAction<{ isBusy: boolean }>) => {
      state.isBusy = payload.isBusy;
    },
    setIsEditMode: (
      state,
      { payload }: PayloadAction<{ isEditMode: boolean }>
    ) => {
      state.isEditMode = payload.isEditMode;
    },
  },
  extraReducers: {
    /****************************
     * THUNK - getQuestionnaire
     */

    [getQuestionnaire.pending.type]: state => {
      state.isReady = false;
    },
    [getQuestionnaire.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ items: TAssessmentItem[] }>
    ) => {
      state.questionCount = payload.items.length;
      state.isReady = true;
    },

    [sendRetort.pending.type]: state => {
      state.isBusy = true;
    },
    [sendRetort.fulfilled.type]: state => {
      state.isBusy = false;

      // If we are not editing, move to the next Question
      if (!state.isEditMode) {
        incrementQuestionIdx(state);
      }
    },
  },
});
