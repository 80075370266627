import { getThemeColor } from '../../theme/_';
import { Box } from '@chakra-ui/react';

/**
 * @constructor Logo
 * @description Renders the Cadey logo.
 * @param colorFamily - The color family to use for the logo.
 * @param rest - Additional props passed to the wrapper.
 * @returns {JSX.Element}
 */
const Logo = ({ colorFamily = 'blue', ...rest }) => {
  const colr = getThemeColor(colorFamily);
  return (
    <Box {...rest}>
      <svg
        viewBox={`0 0 70 26`}
        width={'100%'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.86 20.526c3.457 0 5.265-2.173 5.425-4.373.053-.59-.612-.698-.93-.269-.56.966-1.57 1.879-3.192 1.879-2.712 0-4.52-2.335-4.52-5.903 0-1.986.957-3.569 2.473-3.569 2.313-.027 2.154 3.783 4.44 3.917 1.224.054 1.649-.643 1.649-1.341 0-2.093-2.446-3.783-5.504-3.757C3.11 7.137 0 9.66 0 13.791c0 3.623 2.845 6.735 6.86 6.735zM25.736 17.575l-.585-4.964c-.373-3.38-2.712-5.5-6.17-5.5-2.765 0-5.105 1.126-5.105 3.058 0 .912.639 1.503 1.596 1.503 1.994 0 1.383-3.435 3.51-3.435 1.622 0 2.047 1.503 2.366 4.642l.027.322c-.692-.483-1.675-.805-2.898-.805-2.606 0-4.84 1.395-4.813 4.079 0 2.683 2.127 4.051 4.999 4.051 1.834 0 2.951-.67 3.536-1.556.16 1.154.851 1.449 2.367 1.449 1.276 0 2.552-.161 2.552-.778 0-.725-1.17-.215-1.382-2.066zm-5.903 1.77c-1.356 0-2.234-1.636-2.234-3.487 0-1.369.824-2.254 1.941-2.254 1.09 0 1.941.805 2.1 2.388l.08.939c.08 1.207-.664 2.415-1.887 2.415zM40.82 17.682V.644c0-.617-.32-.778-1.037-.537l-3.484 1.074c-.345.107-.558.348-.558.617v.08c0 .671 1.33.751 1.33 1.986v4.293c-.798-.671-1.809-1.047-3.112-1.047-3.403 0-6.275 2.71-6.275 6.681 0 4.32 3.005 6.735 6.249 6.735 1.356 0 2.659-.51 3.456-1.449l.054.59c0 .35.239.591.611.591h3.297c.32 0 .532-.161.532-.456v-.08c0-.645-1.063-.913-1.063-2.04zm-6.01 1.69c-2.207-.08-3.43-4.212-3.324-7.164.054-2.495 1.064-3.944 2.686-3.944 1.569 0 2.898 1.798 2.898 3.515v4.723c0 1.905-1.17 2.87-2.26 2.87zM54.115 16.18c-.505.831-1.622 1.556-3.031 1.556-1.596 0-2.819-.859-3.51-2.335l5.903-2.71c.718-.322 1.17-.858 1.17-1.77 0-1.771-1.542-3.81-5.026-3.81-3.483 0-6.435 2.71-6.435 6.6 0 4.132 2.74 6.815 6.435 6.815 3.43 0 5.238-2.495 5.398-4.051.027-.564-.585-.698-.904-.296zM49.143 8.29c1.435 0 2.127 1.905 2.127 2.871 0 .563-.213 1.02-.692 1.261l-3.43 1.744c-.186-.697-.266-1.476-.266-2.307 0-2.04.851-3.569 2.26-3.569z"
          fill={colr}
        />
        <path
          d="M69.468 7.379h-3.244c-.346 0-.558.188-.558.456v.107c0 .671 1.861.832 1.17 2.522l-2.872 6.896-3.111-7.271c-.585-1.396.399-1.476.399-2.147v-.107c0-.268-.213-.456-.505-.456h-4.973c-.319 0-.558.16-.558.429v.134c0 .671.85.886 1.436 2.093l5.291 10.947c-.744 1.852-1.808 3.757-2.739 3.757-1.382 0-.691-3.22-2.871-3.22-1.01 0-1.596.67-1.596 1.61 0 1.395 1.516 2.871 3.909 2.871 2.739 0 4.121-3.032 4.972-4.937l5.026-11.028C69.176 8.855 70 8.613 70 7.942v-.134c0-.268-.213-.43-.532-.43z"
          fill={colr}
        />
      </svg>
    </Box>
  );
};

export default Logo;
