import Button from '../../Button';
import LinkRoute from '../../LinkRoute';
import Logo from '../../Logo';
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { TGenericFunction } from '../../../global/types';
import { Icon } from '../../Icon';
import {
  cellPropsA,
  cellPropsB,
  cellPropsC,
  createContainerProps,
  createLogoLinkProps,
} from '../core/props';
import MenuMain from '../../MenuMain';

interface IMenuButton {
  onClick: TGenericFunction;
  iconType: string;
}

/**
 * @constructor MenuButton
 * @description MenuButton component. Renders the MenuButton.
 * @param onClick
 * @param iconType
 * @param rest
 * @returns {JSX.Element}
 */
const MenuButton: React.FC<IMenuButton> = ({ onClick, iconType, ...rest }) => {
  const menuButtonProps = {
    variant: 'unstyled',
    onClick,
    ...rest,
  };
  const iconProps = {
    type: iconType,
  };
  return (
    <Button {...menuButtonProps}>
      <Icon {...iconProps} />
    </Button>
  );
};

const buttonGutter = '10px';
const menuZIndex = 10000;
const menuButtonZIndex = menuZIndex + 1;
const createMenuButtonVisibilityProps = (isUserAuthenticated: boolean) => ({
  transition: `opacity 0.25s`,
  opacity: isUserAuthenticated ? 1 : 0,
  pointerEvents: isUserAuthenticated ? 'all' : 'none',
});

export interface IAppHeaderView {
  homePath: string;
  isMenuOpen: boolean;
  isUserAuthenticated: boolean;
  menuButtonProps: IMenuButton;
}

/**
 * @constructor AppHeaderView
 * @description AppHeader view component. Renders the AppHeader.
 * @param homePath
 * @param incomingButtonProps
 * @param isMenuOpen
 * @param isUserAuthenticated
 * @param rest
 * @returns {JSX.Element}
 */
const AppHeaderView: React.FC<IAppHeaderView> = ({
  homePath,
  menuButtonProps: incomingButtonProps,
  isMenuOpen,
  isUserAuthenticated,
  ...rest
}) => {
  const menuButtonProps = {
    ...incomingButtonProps,
    ...createMenuButtonVisibilityProps(isUserAuthenticated),
    display: 'block',
    width: 'auto',
    position: 'fixed' as 'fixed',
    zIndex: menuButtonZIndex,
    top: buttonGutter,
    right: buttonGutter,
  };
  const menuProps = {
    // pointerEvents: 'none',
    boxShadow: 'md',
    transition: 'transform 0.25s ease',
    transform: `translateX(${isMenuOpen ? '0' : '101%'})`,
    position: 'fixed' as 'fixed',
    bgColor: 'white',
    zIndex: menuZIndex,
    maxW: '375px',
    w: '100%',
    h: '100%',
    top: 0,
    right: 0,
  };

  return (
    <>
      <Flex {...createContainerProps(rest)}>
        <Box {...cellPropsA}></Box>
        <Box {...cellPropsB}>
          <LinkRoute {...createLogoLinkProps(homePath)}>
            <Logo />
          </LinkRoute>
        </Box>
        {/** `Cell C` used to contain a nav element  The Cell is still here to preserve the position of the Center element **/}
        <Box {...cellPropsC}></Box>
      </Flex>
      <MenuButton {...menuButtonProps} />
      <MenuMain {...menuProps} />
    </>
  );
};

export default AppHeaderView;
