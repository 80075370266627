import Button from '../../../Button';
import { Box } from '@chakra-ui/react';
import { uiHeading } from '../../core/ui';
import { useEditDomain } from '../../../../hooks/useEditDomain';

interface IComplete {
  domainId: number;
}
export const Complete: React.FC<IComplete> = ({ domainId }) => {
  const editDomain = useEditDomain();

  const ctaResultsProps = {
    variant: 'primary',
    onClick: () => {
      editDomain(domainId);
    },
  };

  return (
    <Box {...uiHeading.cell.button}>
      <Button {...ctaResultsProps}>Re-assess</Button>
    </Box>
  );
};
export default Complete;
