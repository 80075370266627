import React from 'react';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { createPageProps } from './props';

export interface IPageView {
  loadedConditions: boolean[];
}
export interface IPage {
  children: React.ReactNode;
  pageTitle: string;
  renderDeps: any[];
}

/**
 * @constructor PageView
 * @description Render base Page component.  View component. Accepts props and renders base Page
 * @param children
 * @param pageTitle
 * @param renderDeps
 * @param rest
 * @constructor
 */
export const PageView: React.FC<IPage> = ({
  children,
  pageTitle = '',
  renderDeps = [],
  ...rest
}) => {
  return (
    <Box {...createPageProps(rest)}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </Box>
  );
};
export default PageView;
