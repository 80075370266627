type TValidScenario = {
  name: string;
  test: (thisPw: string) => boolean;
  msg: string;
};
type TValidator = {
  [key: number]: (thisPw: string) => boolean | string;
};

const minPasswordLength = 8;
export const validationScenarios: TValidScenario[] = [
  {
    name: 'isLengthValid',
    test: (thisPw = '') => thisPw.length >= minPasswordLength,
    msg: `${minPasswordLength} characters or longer`,
  },
  {
    name: 'canHazUppercase',
    test: thisPw => /[A-Z]/.test(thisPw),
    msg: 'At least 1 uppercase letter',
  },
  {
    name: 'canHazLowercase',
    test: thisPw => /[a-z]/.test(thisPw),
    msg: 'At least 1 lowercase letter',
  },
  {
    name: 'canHazNumeric',
    test: thisPw => /\d/.test(thisPw),
    msg: 'At least 1 number',
  },
  {
    name: 'canHazSpecialChar',
    test: thisPw => /[*!@#$%^&(){}[\];<>.?~_+\-=|]/.test(thisPw),
    msg: 'At least 1 special character',
  },
];

export const validate: TValidator = validationScenarios.reduce(
  (acc, { name, test, msg }) => ({
    ...acc,
    [name]: (thisPw: string) => test(thisPw) || msg,
  }),
  {}
);
