import { buildSlice } from '../core/helpers';
import { CadeDomainId, Slice } from '../../../global/constants';
import { getCadeDomains, getPersonalizedDomains } from './core/thunks';
import { sendRetort } from '../sliceAssessments';
import { PayloadAction } from '@reduxjs/toolkit';

export type TDomain = {
  id: number;
  internalName?: string;
  displayName?: string;
  nonClinicalDescription?: string;
  clinicalDescription?: string;
  abbreviatedDescription?: string;
  displayOrder?: number;
  approximateTimeToComplete?: number;
  numberOfItems?: number;
  numberOfItemsCompleted?: number;
  slug?: string;
};
interface IDomainsState {
  isInit: boolean;
  isReady: boolean;
  domains: TDomain[];
  queriedDomains: TDomain[];
}

const initDomains: TDomain[] = (
  Object.values(CadeDomainId).filter(
    value => typeof value === 'number'
  ) as number[]
).map(id => ({ id }));

const initialState: IDomainsState = {
  isInit: false,
  isReady: false,
  domains: initDomains,
  queriedDomains: [],
};
export const sliceDomains = buildSlice({
  name: Slice.DOMAINS,
  initialState,
  reducers: {
    // domainIdSelected: (
    //   state,
    //   { payload }: PayloadAction<{ domainId: number }>
    // ) => {
    //   const { domains } = state;
    //   const { domainId } = payload;
    //
    //   const selectedDomain = domains.find(({ id }) => id === domainId) || {
    //     id: 0,
    //   };
    //
    //   state.selectedDomainId = selectedDomain.id;
    //   state.currentQuestionIdx = 0;
    // },
  },
  extraReducers: {
    [getCadeDomains.pending.type]: state => {
      state.isReady = false;
    },
    [getCadeDomains.fulfilled.type]: (state, { payload }) => {
      state.domains = payload.domains;
      state.isInit = true;
      state.isReady = true;
    },
    [getPersonalizedDomains.pending.type]: state => {
      state.isReady = false;
    },
    [getPersonalizedDomains.fulfilled.type]: state => {
      state.queriedDomains = initDomains;
      state.isInit = true;
      state.isReady = true;
    },

    [sendRetort.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{ domainId: number; numberOfItemsCompleted: number }>
    ) => {
      const { domainId, numberOfItemsCompleted } = payload;

      if (numberOfItemsCompleted) {
        const domain = state.domains.find(domain => domain.id === domainId) || {
          numberOfItemsCompleted: -99,
        };
        domain.numberOfItemsCompleted = numberOfItemsCompleted;
      }
    },
  },
});
