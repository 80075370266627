// AutoFocus input element
import { useEffect } from 'react';

// TODO: Add jsDoc block
export const useInputAutofocus = refInput => {
  useEffect(() => {
    if (refInput?.current) {
      refInput.current.focus();
    }
  }, [refInput]);
};
