import QueryContainer from '../QueryContainer';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import store from '../../store';
import theme from '../../theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { Provider as ReduxProvider } from 'react-redux';

export const Providers = ({ children }) => (
  <ReduxProvider {...{ store }}>
    <ChakraProvider {...{ theme }}>
      <CSSReset />
      <Router>
        <ScrollToTop />
        <QueryContainer>{children}</QueryContainer>
      </Router>
    </ChakraProvider>
  </ReduxProvider>
);

export default Providers;
