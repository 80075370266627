import React from 'react';
import { TCourse } from '../../../../store/slices';
import CourseView, { ICourseView } from './CourseView';
import {
  HotjarEvent,
  useTriggerHotjarEvent,
} from '../../../../contexts/HotjarContext';

export interface ICourse extends TCourse {}

/**
 * @constructor Course
 * @description Renders a single Cadey course with props passed from Courses component. Shell component. Generates props and passes them to CourseView
 * @param course
 * @returns {JSX.Element}
 */
const Course: React.FC<ICourse> = course => {
  const triggerHotjarEvent = useTriggerHotjarEvent();

  const onCourseClick = () => {
    triggerHotjarEvent(HotjarEvent.COURSES_CLICK);
  };

  const viewProps: ICourseView = {
    ...course,
    onCourseClick,
  };
  return <CourseView {...viewProps} />;
};

export default Course;
