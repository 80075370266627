import { useHistory, useLocation } from 'react-router-dom';

/**
 * @function useRedirect
 * @description Hook which returns a function that redirects to a given path if it does not match the current location.
 * @returns {function(*): *}
 */
export const useRedirect = () => {
  const history = useHistory();
  const location = useLocation();

  // Only route if destination does not match current location
  return to => to !== location.pathname && history.push(to);
};
