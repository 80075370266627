import { getTypography } from './typography';
import { getThemeColor } from './colors';
import { darken } from 'polished';

const darkenFactor = 0.1;
const createSolidButtonProps = (bgColor, textColor) => ({
  ...getTypography('button'),
  bg: bgColor,
  color: textColor,
  _hover: {
    bgColor: darken(darkenFactor, bgColor),
    textDecoration: 'none',
    _disabled: {
      bgColor,
    },
  },
});
const createOutlineButtonProps = (borderColor, textColor) => ({
  ...getTypography('button'),

  bg: 'white',
  color: textColor,
  border: '1px',
  borderColor,
  _hover: {
    borderColor: darken(darkenFactor, borderColor),
    textDecoration: 'none',
    color: darken(darkenFactor, textColor),
    _disabled: {
      borderColor,
      color: textColor,
    },
  },
});
const createLinkButtonProps = textColor => ({
  ...getTypography('button'),

  ...(!!textColor && { color: textColor }),
  textDecoration: 'underline',
  rounded: 0,
  p: 0,
  _hover: {
    ...(!!textColor && { color: darken(darkenFactor, textColor) }),
    textDecoration: 'none',
    _disabled: {
      ...(!!textColor && { color: textColor }),
    },
  },
});

export const components = {
  Button: {
    variants: {
      primary: {
        ...createSolidButtonProps(
          getThemeColor('blue.800'),
          getThemeColor('white')
        ),
      },
      secondary: {
        ...createSolidButtonProps(
          getThemeColor('blue.100'),
          getThemeColor('blue.800')
        ),
      },
      tertiary: {
        ...createOutlineButtonProps(
          getThemeColor('blue.200'),
          getThemeColor('blue.800')
        ),
      },
      quaternary: {
        ...createLinkButtonProps(getThemeColor('blue.800')),
        _focus: {
          boxShadow: 'none',
        },
      },
      solid: {
        color: 'white',
        bgColor: 'red.800',
        h: '40px',
        _hover: {
          bgColor: 'red.900',
        },
        _active: {
          bgColor: 'red.800',
        },
      },
      link: {
        textDecoration: 'underline',
        _focus: {
          boxShadow: 'none',
        },
      },
      outline: {},
      navLink: {
        bgColor: 'transparent',
        color: 'green.800',
        display: 'flex',
        p: 0,
        w: 'auto',
        _hover: {
          color: 'green.900',
        },
        _active: {
          color: 'green.800',
        },
      },
      unstyled: {
        borderLeftRadius: 0,
        borderRightRadius: 0,
        display: 'block',
        fontWeight: 'inherit',
        height: 'auto',
        minWidth: 0,
        textAlign: 'left',
        whiteSpace: 'normal',
        width: '100%',
      },
    },
  },
};
