import { useSlices } from '../../../../hooks/useSlices';
import { Slice } from '../../../../global/constants';
import { TSymptomMeta } from '../sliceSymptoms';
import { LookupMap } from '../../../../global/LookupMap';

const defaultSymptom: TSymptomMeta = {
  id: -1,
  name: 'Invalid',
  slug: 'invalid',
  imageUrl: null,
  description: 'This Symptom does not exist.',
  courses: [],
};

export const useSymptomMeta = (idOrSlug: number | string): TSymptomMeta => {
  const [{ symptoms }] = useSlices(Slice.SYMPTOMS);

  const SymptomMap = new LookupMap<TSymptomMeta>(
    (symptoms as TSymptomMeta[]).reduce((acc, symptom) => {
      return { ...acc, [symptom.id]: symptom, [symptom.slug]: symptom };
    }, {}),
    defaultSymptom
  );

  return SymptomMap.get(idOrSlug);
};
