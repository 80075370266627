import FormRequestMissingInfo from '../../components/FormRequestMissingInfo';
import api from '../../services/api';
import { TGenericActionCreator } from '../../store';
import { makePossessive } from '../../global/helpers';

const regexEmailPattern =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const isEmailValid = (email: string) => regexEmailPattern.test(email);

export interface IPageRequestEmailView {
  accountId: number;
  firstName: string;
  nextRoute: string;
  acMissingInfoUpdated: TGenericActionCreator;
}
const PageRequestEmailView: React.FC<IPageRequestEmailView> = ({
  accountId,
  firstName,
  nextRoute,
  acMissingInfoUpdated,
}) => {
  const infoDetails = {
    title: `What is your email address?`,
    headline: `Let's make sure ${makePossessive(
      firstName
    )} results are saved to an account.`,
    desc: ``,
    label: `What is your email address?`,
    type: 'email',
    optInText: `I want to receive emails from Cadey that may include assessment results, mini-courses, videos and articles associated with my child's profile.`,
    requiredText: 'You must enter an email address',
    affirmationText: `I'd like to receive weekly personalized recommendations for my child`,
    variant: 'plain',
    submitText: <>Next Step</>,
    hfProps: {
      validate: {
        isValid: (email: string) =>
          isEmailValid(email) || 'Please enter a valid email address',
        isEmailUnique: async (email: string) => {
          if (!isEmailValid(email)) return null;

          let retVal;
          try {
            const uniqueEmailResponse = await api.post(
              `account/${accountId}/validateemail`,
              { email }
            );
            retVal = uniqueEmailResponse.status === 200;
          } catch (e) {
            retVal = false;
          }

          return retVal || 'That email already exists';
        },
      },
    },
  };
  return (
    <FormRequestMissingInfo
      {...{
        acMissingInfoUpdated,
        infoDetails,
        nextRoute,
      }}
    />
  );
};

export default PageRequestEmailView;
