import { PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../../global/constants';
import {
  TAssessment,
  TAssessmentScoreItem,
  TAssessmentsState,
  TCourse,
} from './core/types';
import { assignAssessment } from './core/helpers';
import { buildSlice } from '../core/helpers';
import {
  createCadeAssessment,
  initCadeAssessments,
  getAssessmentResults,
  sendRetort,
} from './core/thunks';
import { TNullable } from '../../../global/types';
import { prepareAssessmentDomain } from '../sliceDomains/core/helpers';
import { getResults, sliceResults, TScore } from '../sliceResults';

interface IAssessmentsState extends TAssessmentsState {}

const initialState: IAssessmentsState = {
  isInit: false,
  isReady: false,
  currentDomainId: null,
  currentAssessmentId: null,
  assessments: [],
};
export const sliceAssessments = buildSlice({
  name: Slice.ASSESSMENTS,
  initialState,
  reducers: {
    assessmentSet: (
      state,
      { payload }: PayloadAction<{ assessment: TAssessment }>
    ) => {
      const { assessment } = payload;
      if (assessment.id !== state.currentAssessmentId) {
        assignAssessment(state, assessment);
      }
    },
    domainIdSet: (
      state,
      { payload }: PayloadAction<{ currentDomainId: number }>
    ) => {
      state.currentDomainId = payload.currentDomainId;
    },
    domainIdUnset: state => {
      state.currentDomainId = null;
    },
  },
  extraReducers: {
    /****************************
     * THUNK - GetAssessments
     */

    [initCadeAssessments.pending.type]: state => {
      state.isReady = false;
    },
    [initCadeAssessments.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        assessments: TAssessment[];
        currentAssessmentId: TNullable<number>;
      }>
    ) => {
      state.isInit = true;

      const { assessments } = payload;

      for (const assessment of assessments) {
        for (let domain of assessment.assessmentDomains) {
          domain = prepareAssessmentDomain(domain);
        }
      }

      state.assessments = assessments;
      state.currentAssessmentId = payload.currentAssessmentId;

      if (!!payload.currentAssessmentId) {
        state.isReady = true;
      }
    },

    /****************************
     * THUNK - CreateAssessment
     */

    [createCadeAssessment.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ assessment: TAssessment }>
    ) => {
      assignAssessment(state, payload.assessment);
    },

    /****************************
     * THUNK - getAssessmentResults
     */

    [sliceResults.actions.setResultsFetchTrigger.type]: state => {
      state.isReady = false;
    },
    [getAssessmentResults.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        assessmentId: number;
        scores: TAssessmentScoreItem[];
      }>
    ) => {
      // Find the Assessment for these results
      const assessmentIdx = state.assessments.findIndex(
        assessment => assessment.id === payload.assessmentId
      );

      // Store results on that Assessment
      state.assessments[assessmentIdx].scores = payload.scores;
    },

    /****************************
     * THUNK - Questionnaire - sendRetort
     */

    [sendRetort.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        domainId: number;
        itemDefId: number;
        responseId: number;
      }>
    ) => {
      const { domainId, itemDefId, responseId } = payload;

      // Retrieve Current Assessment
      const currentAssessment = state.assessments.find(
        ({ id }) => id === state.currentAssessmentId
      );

      // Pull Domains from `currentAssessment`
      const domains = currentAssessment?.assessmentDomains || [];

      // Scan each `domain`
      for (const domain of domains) {
        // Search for matching `assessmentItem` in this `domain`
        const assessmentItem = domain.assessmentItems.find(
          assessmentItem => assessmentItem.itemDefId === itemDefId
        );

        // If `assessmentItem` is found, update its `responseId` in State
        if (!!assessmentItem) {
          assessmentItem.responseId = responseId;

          // And if Domain's `domainDefId` differs from the incoming `domainId` from the API, flag `assessmentItem` as non-modifiable for that Domain
          if (domain.domainDefId !== domainId) {
            assessmentItem.isUserModifiable = false;
          }
        }
      }
    },

    [getResults.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        scores: TScore[];
      }>
    ) => {
      const { scores } = payload;
      const { assessments, currentAssessmentId } = state;
      const currentAssessmentIdx = assessments.findIndex(
        ({ id }) => id === currentAssessmentId
      );

      const assessmentDomains =
        state.assessments[currentAssessmentIdx].assessmentDomains;

      for (const {
        concernLevel,
        domainDefId,
        lastModified,
        score,
        maxScore,
        numPersonsCompletingDomain,
        pctPersonsLikeMe,
        pctPersonsLikeMeAsText,
      } of scores) {
        const assessmentDomainIdx = assessmentDomains.findIndex(
          domain => domain.domainDefId === domainDefId
        );
        const assessmentDomain = assessmentDomains[assessmentDomainIdx];
        assessmentDomain.concernLevel = concernLevel;
        assessmentDomain.lastModified = lastModified;
        assessmentDomain.score = score;
        assessmentDomain.normalizedMaxScore = maxScore;
        assessmentDomain.numPersonsCompletingDomain =
          numPersonsCompletingDomain;
        assessmentDomain.pctPersonsLikeMe = pctPersonsLikeMe;
        assessmentDomain.pctPersonsLikeMeAsText = pctPersonsLikeMeAsText;
      }
      state.isReady = true;
    },
  },
});
