export const retrieveAuthTokens = (
  AuthObj: any
): { accessToken: string; idToken: string } => {
  // Init Return Tokens to Null
  let accessToken = null;
  let idToken = null;

  // If tokens are available, overwrite Return values
  if (!!AuthObj.user) {
    const { accessToken: accessTokenRaw, idToken: idTokenRaw } =
      AuthObj.user.signInUserSession;
    accessToken = accessTokenRaw.jwtToken;
    idToken = idTokenRaw.jwtToken;
  }

  return { accessToken, idToken };
};
