import Courses from '../../components/Courses';
import Page from '../Page';
import PageBody from '../../components/PageBody';
import PageSection from '../../components/PageSection';
import { Copy, H4, H6 } from '../../components/Prose';
import { IPageView } from '../Page/PageView';
import { TCourse } from '../../store/slices';
import { createImageProps, headerSectionProps, pageBodyProps, pageHeaderProps } from './props';
import { ConcernTextMap } from '../../global/constants';
import { TNullable } from '../../global/types';
import Image from '../../components/Image';
import ButtonFreeCourse from '../../components/ButtonFreeCourse';
import ButtonBack from '../../components/ButtonBack';
import Content from '../../components/Content';
import { stringToJSX } from '../../global/helpers';
import ResultsSubFooter from '../../components/ResultsSubFooter';
import { Box } from '@chakra-ui/react';

export interface IPageSymptomDetailView extends IPageView {
  name: string;
  description: string;
  courses: TCourse[];
  concernLevel?: string;
  imageUrl: TNullable<string>;
}
const PageSymptomDetailView: React.FC<IPageSymptomDetailView> = ({
  courses,
  loadedConditions,
  name,
  description,
  concernLevel,
  imageUrl,
}) => {
  const pageProps = {
    loadedConditions,
    pageTitle: 'Results',
  };

  const concernText = !!concernLevel ? ConcernTextMap.get(concernLevel) : '';

  const coursesProps = {
    courses,
  };
  const ctaSectionProps = {
    display: 'flex',
    flexDir: 'column',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    textAlign: 'center' as 'center',
  };
  const pageHeaderContainerProps = {
    position: 'relative' as 'relative',
  };
  const backButtonProps = {
    position: 'absolute' as 'absolute',
    left: 0,
    top: '-0.1em',
  };

  const ctaHeadingProps = {
    color: 'green.800',
    mb: '20px',
  };
  const imageSectionProps = {
    maxW:'300px', 
    margin:'auto'
  };
  return (
    <Page {...pageProps}>
      <PageBody {...pageBodyProps}>
        <PageSection {...headerSectionProps}>
          <Box {...pageHeaderContainerProps}>
            <H4 {...pageHeaderProps}>{name}</H4>
            <ButtonBack {...backButtonProps} />
          </Box>
          <Content>{description}</Content>
          <Copy>{concernText}</Copy>
        </PageSection>
        {!!imageUrl && (
          <PageSection {...imageSectionProps}>
            <Image {...createImageProps({ src: imageUrl })} />
          </PageSection>
        )}
        <PageSection {...ctaSectionProps}>
          <H6 {...ctaHeadingProps}>
            Curious what you can do to start helping your child today?
          </H6>
          <ButtonFreeCourse />
        </PageSection>
        <PageSection>
          <Courses {...coursesProps} />
        </PageSection>
      </PageBody>
      <ResultsSubFooter />
    </Page>
  );
};

export default PageSymptomDetailView;
