import { TGenericFunction } from '../../global/types';
import { createGutterProps } from '../../theme/helpers';
import { pageGutter } from '../../pages/Page/props';

const [uiWrap, uiItem] = createGutterProps(pageGutter);

export const createListProps = (getUIStyles: TGenericFunction) =>
  getUIStyles({
    base: {
      ...uiWrap,
      display: 'flex',
      flexWrap: 'wrap',
    },
  });
export const createItemProps = (getUIStyles: TGenericFunction) =>
  getUIStyles({
    base: {
      ...uiItem,
      display: 'flex',
      alignItems: 'stretch',
      flex: '0 0 100%',
    },
    md: {
      flex: '0 0 50%',
    },
    lg: {
      flex: '0 0 33.3333333333%',
    },
  });
