import { TD3Func } from '../D3Chart';
import * as d3 from 'd3';
import { ConcernColorMap, getThemeColor } from '../../../theme/_';

const minScore = 0;
const maxScore = 10;

const offsetAngle = 0;
const minAngle = offsetAngle;
const maxAngle = 2 * Math.PI - offsetAngle;

// create the mapping between score value and chart value
const arcScale = d3
  .scaleLinear()
  .domain([minScore, maxScore])
  .range([minAngle, maxAngle]);

const getArcAngle = (score: number) => {
  const normalizedScore =
    score < minScore ? minScore : score > maxScore ? maxScore : score;
  return arcScale(normalizedScore);
};

export type TGaugeDatum = { score: number; concernLevel: number };
/**
 * Gauge chart D3 function
 * @description This function is used to draw the gauge chart
 * @param data
 * @param d3Canvas
 * @param w
 */
export const gaugeFunc: TD3Func<TGaugeDatum> = ({ data, d3Canvas, w }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const outerRadius = w / 2;
  const barWidth = outerRadius * 0.24;
  const innerRadius = outerRadius - barWidth;

  const getArc: any = (endAngle: number) =>
    d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(minAngle)
      .endAngle(endAngle);

  const canvas = d3
    .select(d3Canvas)
    .append('g')
    .attr('transform', 'rotate(180)');

  canvas
    .append('path')
    .attr('class', 'arc')
    .attr('d', getArc(maxAngle))
    .attr('fill', getThemeColor('sand.500'));

  canvas
    .selectAll('g')
    .data(data)
    .enter()
    .append('path')
    .attr('class', 'arc')
    .attr('d', d => getArc(getArcAngle(d.score))())
    .attr('fill', d => ConcernColorMap.get(d.concernLevel))
    .exit()
    .remove();
};
