import { getTypography } from '../../../theme/_';

const buttonHW = 32;
export const uiWrap = {
  position: 'relative',
};
export const createUIButton = (isPasswordVisible: boolean) => ({
  ...getTypography('copy'),
  children: isPasswordVisible ? 'Hide' : 'Show',
  height: `${buttonHW}px`,
  position: 'absolute',
  right: '10px',
  textAlign: 'center',
  top: '50%',
  transform: 'translateY(-50%)',
  variant: 'unstyled',
  width: `40px`,
  zIndex: 100,
});
export const createUIInput = (isPasswordVisible: boolean) => ({
  type: isPasswordVisible ? 'text' : 'password',
});
