import { createSlice, Draft, SliceCaseReducers } from '@reduxjs/toolkit';
import { CreateSliceOptions, Slice } from '@reduxjs/toolkit/src/createSlice';
import { sliceCore } from '../sliceCore';
import { mapPayloadToState } from '../../../global/helpers';

export function buildSlice<
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string
>(
  options: CreateSliceOptions<State, CaseReducers, Name>
): Slice<State, CaseReducers, Name> {
  const { initialState, extraReducers: rawExtraReducers = {} } = options;

  const extraReducers = {
    [sliceCore.actions.resetState.type]: (state: Draft<State>) => {
      mapPayloadToState(initialState, state);
    },
    ...rawExtraReducers,
  };

  return createSlice({ ...options, extraReducers });
}
