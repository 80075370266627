/**
 * @function createHtmlFormatting
 * @description Creates the HTML formatting for the content
 * @param elSeparation
 * @returns object
 */
export const createHtmlFormatting = elSeparation => ({
  h1: {
    fontSize: 'lg',
    fontWeight: 'bold',
  },
  'p, ul, ol': {
    marginTop: elSeparation,
  },
  'ul, ol': {
    'li + li': {
      marginTop: '0.25em',
    },
    marginLeft: '1.5em',
  },
  ul: {
    listStyle: 'disc',
  },
  '& > *:first-of-type': {
    marginTop: 0,
  },
  a: {
    textDecoration: 'underline',
  },
  '.bookLink': {
    color: 'blue.800',
  },
});
