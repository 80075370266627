import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import { allTrue } from '../../global/helpers';

const useEmbarkConditions_Person = () => {
  const [
    {
      currentPersonId,
      tempData: { firstName: tempFirstName, age: tempAge },
    },
  ] = useSlices(Slice.PERSONS);

  // If Person does not exist, we are clear to Embark
  const isEmbarkPossible = !currentPersonId;

  return {
    shouldEmbarkName: allTrue(isEmbarkPossible, !tempFirstName),
    shouldEmbarkAge: allTrue(isEmbarkPossible, !tempAge),
  };
};

export default useEmbarkConditions_Person;
