import React from 'react';
import { Box } from '@chakra-ui/react';
import GobStopper, { IGobstopper } from '../GobStopper/GobStopper';
import { useSlices } from '../../hooks/useSlices';
import { Slice } from '../../global/constants';
import { TDomain } from '../../store/slices';
import { IconDomain } from '../IconDomain';

// TODO: Convert to Stateful Component Pattern
interface IDomainAvatar extends IGobstopper {
  domainId: number;
  color?: string;
  w?: string;
}

const iconGutter = '2%';
const iconWrapProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: iconGutter,
  left: iconGutter,
  right: iconGutter,
  bottom: iconGutter,
};

/**
 * @constructor DomainAvatar
 * @description Renders a Domain Icon on a styled background.
 * @param domainId - id of the domain
 * @param bgColor - color of the background
 * @param bgValue - value of the background
 * @param w - width of the avatar
 * @param rest - additional props passed to the wrapper
 * @returns {JSX.Element}
 */
const DomainAvatar: React.FC<IDomainAvatar> = ({
  domainId,
  bgColor = 'blue',
  bgValue = 800,
  w = '70px',
  ...rest
}) => {
  const [{ domains = [] }] = useSlices(Slice.DOMAINS);
  const { displayName: domainName = '' } =
    (domains as TDomain[]).find(({ id }) => id === domainId) || {};

  const avatarProps = {
    w,
    title: domainName,
    ...rest,
  };
  const gobStopperProps = {
    bgColor,
    bgValue,
  };
  const iconProps = {
    domainId,
    ml: '-0.2em',
  };
  return (
    <Box pos={'relative'} {...avatarProps}>
      <GobStopper {...gobStopperProps} />
      <Box pos={'absolute'} {...iconWrapProps}>
        <IconDomain {...iconProps} />
      </Box>
    </Box>
  );
};
export default DomainAvatar;
