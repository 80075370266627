import React from 'react';
import CoursesView, { ICoursesView } from '../CoursesView';
import { useSlices } from '../../../hooks/useSlices';
import { Slice } from '../../../global/constants';
interface IContainer {}

/**
 * @constructor Courses
 * @description Renders a list of Cadey Courses.  Shell component. Generates props and passes them to CoursesView
 * @param rest
 * @returns {JSX.Element}
 */
export const Courses: React.FC<IContainer> = ({ ...rest }) => {
  const [{ courses }] = useSlices(Slice.RESULTS);

  const viewProps: ICoursesView = {
    courses,
    ...rest,
  };
  return <CoursesView {...viewProps} />;
};

export default Courses;
