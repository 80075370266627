import { ConcernLevel, Slice } from '../../../../global/constants';
import { TAssessmentItem } from './types';
import { TFilter } from '../../../../global/types';
import { useSlices } from '../../../../hooks/useSlices';

export type TAssessmentItemIterators = {
  fnFilterVisibleItems: TFilter<TAssessmentItem>;
  fnFilterHiddenItems: TFilter<TAssessmentItem>;
};
type Sig_useAssessmentItemIterators = () => TAssessmentItemIterators;
export const useAssessmentItemIterators: Sig_useAssessmentItemIterators =
  () => {
    const [{ isEditMode }] = useSlices(Slice.QUESTIONNAIRE);

    return {
      // If `isEditMode` is TRUE, return ALL AssessmentItems, regardless of Modifiable status
      // If `isEditMode` is FALSE, return ONLY AssessmentItems that are Modifiable
      fnFilterVisibleItems: ({ isUserModifiable }) =>
        isEditMode ? true : isUserModifiable,

      // If `isEditMode` is TRUE, there are NO hidden items.  Return FALSE for ALL.
      // If `isEditMode` is FALSE, return ONLY AssessmentItems that are non-modifiable
      fnFilterHiddenItems: ({ isUserModifiable }) =>
        isEditMode ? false : !isUserModifiable,
    };
  };
// Return all items that are NOT unanswered

export const fnFilterCompletedAssessmentItems: TFilter<TAssessmentItem> = ({
  responseId,
}) => responseId !== ConcernLevel.NO_RESPONSE;
