import { createAsyncThunk } from '@reduxjs/toolkit';
import { nullChar, Slice } from '../../../../global/constants';
import api from '../../../../services/api/api';
import { maybeGetResponseData } from '../../../../services/api/core/maybeGetResponseData';
import { TPerson } from './types';
import { TNullable } from '../../../../global/types';

export const initCadePersons = createAsyncThunk<
  TNullable<{ persons: TPerson[]; currentPersonId: number }>,
  { accountId: number; personId: number }
>(`${Slice.PERSONS}/get`, async ({ accountId, personId }) => {
  let returnValue = null;

  if (!accountId) return returnValue;

  const personsResponse = await api.get(`account/${accountId}/persons`);
  const persons = maybeGetResponseData(personsResponse) || [];

  if (persons.length) {
    const currentPerson = (persons as TPerson[]).find(
      ({ id }) => id === personId
    );
    const [firstPerson = { id: null }] = persons;

    const currentPersonId = currentPerson ? currentPerson.id : firstPerson.id;
    returnValue = { persons, currentPersonId };
  }
  return returnValue;
});

export const createCadePerson = createAsyncThunk<
  TNullable<TPerson>,
  { accountId: number; firstName: string; age: number }
>(`${Slice.PERSONS}/create`, async ({ accountId, firstName, age }) => {
  const personResponse = await api.post(`account/${accountId}/person`, {
    firstName,
    lastName: nullChar,
    age,
  });

  return maybeGetResponseData(personResponse) || null;
});
