import TestimonialsView, {
  ITestimonials,
  TTestimonialItem,
} from './TestimonialsView';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
const data: TTestimonialItem[] = [
  {
    src: img1,
    text: 'This is so helpful. Particularly for tricky kids that are hard to figure out. No one gets it. You guys do.',
  },
  {
    src: img2,
    text: 'I really like your questions. These are really helpful questions I never even thought to ask. I really loved that part.',
  },
  {
    src: img3,
    text: 'Tears run down my cheeks as I watch these videos on emotional regulation. For years we have struggled with this and no one understood. I finally feel heard.',
  },
];

const testimonialsProps: ITestimonials = {
  data,
};

/**
 * @constructor Testimonials
 * @description Displays a carousel of user testomonials.  Built with Shell component with the intent of retrieving data from the Store at some point.  For now, static data just gets passed to the View.
 * @returns {JSX.Element}
 */
const Testimonials = () => {
  return <TestimonialsView {...testimonialsProps} />;
};

export default Testimonials;
