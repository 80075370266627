import React, { createContext, useContext, useEffect, useState } from 'react';

type IMenuState = {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

/**
 * MenuMainContext
 * @description Context for MenuMain interactions.  Saves shared state for MenuMain and related components.
 */
export const MenuMainContext = createContext<IMenuState>({
  isMenuOpen: false,
  setIsMenuOpen: isMenuOpen => {
    console.log(isMenuOpen);
  },
});

interface IMenuMainProvider {
  children: React.ReactNode;
}

/**
 * MenuMainProvider
 * @description Provides MenuMainContext to children
 * @param children
 * @constructor
 */
const MenuMainProvider: React.FC<IMenuMainProvider> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const value: IMenuState = {
    isMenuOpen,
    setIsMenuOpen,
  };

  return (
    <MenuMainContext.Provider {...{ value }}>
      {children}
    </MenuMainContext.Provider>
  );
};

/**
 * @function useMenuState
 * @description Hook for accessing MenuMainContext
 */
export const useMenuState = () => {
  return useContext(MenuMainContext);
};

export default MenuMainProvider;
