export const uiEditButton = {
  colorSpace: 'green',
  position: 'fixed',
  bottom: '30px',
  left: `50%`,
  transform: 'translateX(-50%)',
  zIndex: 10000,
  borderWidth: '2px',
  borderColor: 'light.900',
  w: '300px',
};
