import { createGutterProps } from '../../theme/helpers';
import { TAssessmentDomain } from '../../store/slices';
import { TGenericObject } from '../../global/types';

const itemWidth = 165;
const gutterWidth = 7;

const getListWidth = (cols: number) =>
  `${cols * itemWidth + (cols - 1) * gutterWidth}px`;

const [gList, gListItem] = createGutterProps(gutterWidth);

/**
 * @function createListWrapProps
 * @description This function is used to create the list-wrap UI props
 * @param rest
 */
export const createListWrapProps = (rest: TGenericObject) => ({
  ...rest,
  w: {
    base: getListWidth(2),
    md: getListWidth(3),
  },
});
export const listProps = {
  ...gList,
  display: 'flex',
  flexWrap: 'wrap' as 'wrap',
};
/**
 * @function createListItemProps
 * @description This function is used to create the list-item UI props
 * @param domain
 */
export const createListItemProps = (domain: TAssessmentDomain) => ({
  ...gListItem,
  boxSizing: 'content-box' as 'content-box',
  key: domain.id,
  w: `${itemWidth}px`,
});
