import Button from '../Button';
import Content from '../Content';
import Essay from '../Essay';
import Icon from '../Icon';
import { Box } from '@chakra-ui/react';
import { H4 } from '../Prose';
import {
  createUIButtonStyles,
  createUIEssayStyles,
  createUIHeadingStyles,
} from './core/ui';
import React, { useState } from 'react';
import { IconType } from '../Icon/core/enum';
// TODO: Convert to Stateful Component Pattern
interface IButtonRetort {
  title: string;
  helpText: React.ReactNode;
  isActive?: boolean;
}

/**
 * @constructor ButtonRetort
 * @description Button that sends a Questionnaire response to the API. Used in the Questionnaire component.  `Retort` is a stand-in for `Response`, which is a reserved word for what the API returns
 * @param title
 * @param helpText
 * @param isActive
 * @param rest
 * @constructor
 */
export const ButtonRetort: React.FC<IButtonRetort> = ({
  title,
  helpText,
  isActive = false,
  ...rest
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isDepressed, setIsDepressed] = useState(false);

  const buttonProps = {
    ...rest,
    ...createUIButtonStyles(isHover, isDepressed),
    onMouseOver: () => setIsHover(true),
    onMouseOut: () => setIsHover(false),
    onMouseDown: () => setIsDepressed(true),
    onMouseUp: () => setIsDepressed(false),
  };

  const headingProps = {
    ...createUIHeadingStyles(isDepressed),
    mb: '9px',
  };

  const iconGutter = '16px';
  const iconWrap = {
    color: isDepressed ? 'light.900' : 'green.800',
  };
  const iconProps = {
    type: IconType.CHECK_CIRCLE,
    size: 16,
    position: 'absolute',
    top: iconGutter,
    right: iconGutter,
    zIndex: 100,
  };

  return (
    <Essay {...createUIEssayStyles(isActive)}>
      {isActive && (
        <Box {...iconWrap}>
          <Icon {...iconProps} />
        </Box>
      )}
      <Button {...buttonProps}>
        <H4 {...headingProps}>{title}</H4>
        <Content>{helpText}</Content>
      </Button>
    </Essay>
  );
};

export default ButtonRetort;
