import Button from '../Button';
import { Link } from '@chakra-ui/react';

/**
 * @constructor ButtonFreeCourse
 * @description Button to go to free course.  Centralized into a component because it is used in multiple places
 * @param rest
 * @returns {JSX.Element}
 */
export const ButtonFreeCourse = ({ ...rest }) => {
  const ctaProps = {
    as: Link,
    children: 'Try A Free Course',
    href: 'https://courses.cadey.co/courses/recent-advances-in-adhd',
    m: 'auto',
    maxW: '300px',
    target: '_blank',
    variant: 'primary',
    ...rest,
  };
  return <Button {...ctaProps} />;
};

export default ButtonFreeCourse;
