import PageLandingView, { IPageLandingView } from './PageLandingView';
import { Slice } from '../../global/constants';
import { acPerformTemporaryLogin } from '../../store';
import { getDomainsPath } from '../../global/helpers';
import { useDispatch } from 'react-redux';
import { useRedirect } from '../../hooks/useRedirect';
import { useSignOut } from '../../hooks/useSignOut';
import { useSlices } from '../../hooks/useSlices';
import { useCallback } from 'react';

/**
 * @constructor PageLanding
 * @description Landing page.  Marketing, app introduction. Shell Component. Generates Trial button handler clalback and passes it to PageLandingView.
 * @returns {JSX.Element}
 */
const PageLanding = () => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const signOut = useSignOut();
  const [{ isTempLogging }] = useSlices(Slice.SESSION);

  const handleTrialClick = useCallback(() => {
    (async () => {
      await signOut();
      await dispatch(acPerformTemporaryLogin());
      redirect(getDomainsPath());
    })();
  }, []);

  const loadedConditions = [!isTempLogging];
  const viewProps: IPageLandingView = {
    handleTrialClick,
    loadedConditions,
  };
  return <PageLandingView {...viewProps} />;
};
export default PageLanding;
