import { getTypography } from '../../theme/_';
import { TGenericObject } from '../../global/types';
import { createComponentProps } from '../../global/helpers';

const itemGutter = 20;
const itemPadding = `${itemGutter / 2}px`;
const breakpoint = 'smm';
/**
 * @function createFooterProps
 * @description Create the UI props for the Footer component
 * @param rest - Any other props to be passed to the component
 * @param dataTestId - The data-testid attribute value for Jest
 */
export const createFooterProps = (rest: TGenericObject, dataTestId: string) =>
  createComponentProps(dataTestId, {
    ...getTypography('caption'),
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      base: 'column',
      [breakpoint]: 'row',
    },
    bgColor: 'blue.900',
    color: 'white',
    p: {
      base: itemPadding,
      [breakpoint]: 0,
    },

    ...rest,
  });

export const cellProps = {
  p: itemPadding,
  whiteSpace: 'nowrap' as 'nowrap',
  flex: 0,
};
export const copyrightCellProps = {
  flex: {
    base: 0,
    [breakpoint]: 1,
  },
  order: {
    base: 10,
    [breakpoint]: -1,
  },
};
export const linkProps = {
  textDecoration: 'underline',
  target: '_blank',
};
