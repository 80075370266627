import { getCadeySlug } from '../../../../global/helpers';
import { ConcernLevel } from '../../../../global/constants';

export const preparePlainDomain = (domain) => {
  const { internalName = '' } = domain;

  return {
    ...domain,
    domainDefId: domain.id, // Normalize Domain Ids.  Setting DomainDefId as standard
    numberOfItemsCompleted: 0,
    slug: getCadeySlug(internalName),
  };
};

const getNumberOfItemsCompleted = (items) =>
  items.filter(({ responseId }) => responseId !== ConcernLevel.NO_RESPONSE)
    .length;
export const prepareAssessmentDomain = (domain) => {
  const { assessmentItems, domainDefId, internalName } = domain;

  return {
    ...domain,
    id: domainDefId,
    numberOfItemsCompleted: getNumberOfItemsCompleted(assessmentItems),
    slug: getCadeySlug(internalName),
  };
};