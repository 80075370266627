import React from 'react';
import CardDomain from '../CardDomain';
import { List, ListItem } from '@chakra-ui/react';
import { TAssessmentDomain } from '../../store/slices';
import { TGenericObject } from '../../global/types';

interface IDomainListView {
  domains: TAssessmentDomain[];
  listProps: TGenericObject;
  itemProps: TGenericObject;
}

const DomainListView: React.FC<IDomainListView> = ({
  domains,
  listProps,
  itemProps,
}) => {
  const createListItemProps = (domain: TAssessmentDomain) => ({
    key: domain.id,
    ...itemProps,
  });
  return (
    <List {...listProps}>
      {domains.map(domain => {
        return (
          <ListItem {...createListItemProps(domain)}>
            <CardDomain {...{ domain, flex: 1 }} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default DomainListView;
