// If we have reached this code, then we have successfully logged in.  Now we authenticate
import { Slice } from '../../../../../global/constants';
import { acAuthenticateCadeUser } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSlices } from '../../../../../hooks/useSlices';

type HookSig = (isUserAuthenticated: boolean) => boolean;
/**
 * useAuthenticateUser
 * @description This hook is responsible for authenticating the user with Cade API.
 * @param isUserAuthenticated
 * @returns isAccountInit
 */
export const useAuthenticateUser: HookSig = isUserAuthenticated => {
  const dispatch = useDispatch();

  const [{ isInit: isAccountInit }] = useSlices(Slice.ACCOUNT);

  useEffect(() => {
    if (!isUserAuthenticated) {
      dispatch(acAuthenticateCadeUser());
    }
  }, [isUserAuthenticated]);

  return isAccountInit;
};
