type TLookupMapKey = string | number;
type TMap<T> = { [key: TLookupMapKey]: T };
type TEntry<T> = { key: string; value: T };

/**
 * a Class to handle lookup maps
 */
export class LookupMap<Val = any> {
  private readonly map: TMap<Val>;
  private readonly defaultValue: Val;

  /**
   * Creates a new LookupMap
   * @param map - the map to use
   * @param defaultValue - the default value to return if the key does not exist
   */
  constructor(map: TMap<Val>, defaultValue: Val) {
    this.map = map;
    this.defaultValue = defaultValue;
  }

  /**
   * Gets the value for the given key or the default value if the key does not exist
   * @param key - the key to get the value for
   */
  public get(key: TLookupMapKey) {
    return this.map[key] || this.defaultValue;
  }

  /**
   * Checks if the given key exists in the map
   * @param key - the key to check
   */
  public doesKeyExist(key: TLookupMapKey): boolean {
    return this.map.hasOwnProperty(key);
  }

  /**
   * Returns all the keys in the map
   */
  public keys(): string[] {
    return Object.keys(this.map);
  }

  /**
   * Returns all the values in the map
   */
  public values(): Val[] {
    return Object.values(this.map);
  }

  /**
   * Returns all the entries in the map as an array of key-value pairs
   */
  public entries(): TEntry<Val>[] {
    return Object.entries(this.map).map(([key, value]) => ({ key, value }));
  }
}
